import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  editOrganizationDialogOpen: false,
  addOrganizationMemberDialogOpen: false,
  editOrganizationData: '',
  editOrganizationMembersData: [],
  mailPreparationDialogOpen: false,
  mailPreparationCheckingDialogOpen: false,
  accessPosition: '',
  matchesMailJobs1: [],
  isMatchesMailJobs: false,
  itemsValue: 0,
  snackBarMessage: '',
};

const AccessExecutionSlice = createSlice({
  name: 'AccessExecutionState',
  initialState,

  reducers: {
    setSnackBarMessage: (state, action) => {
      state.snackBarMessage = action.payload;
    },
    setItemsValue: (state, action) => {
      state.itemsValue = action.payload;
    },
    setEditOrganizationDialogOpen: (state, action) => {
      state.editOrganizationDialogOpen = action.payload;
    },

    setEditOrganizationData: (state, action) => {
      state.editOrganizationData = action.payload;
    },

    setEditOrganizationMembersData: (state, action) => {
      //ищем, есть ли в массиве данных редактируемых сотрудников компании сотрудник с определенным ID
      if (state.editOrganizationMembersData.find((item) => item.id === action.payload.id)) {
        //если есть, то находим этот элемент массива и заменяем новыми данными
        var oldObject = state.editOrganizationMembersData.find((item) => item.id === action.payload.id);
        Object.assign(oldObject, action.payload);
      } else {
        //если нету, добавляем новую запись с этим сотрудником в конец массива.
        state.editOrganizationMembersData.push(action.payload);
      }
    },

    clearEditOrganizationMembersData: (state, action) => {
      state.editOrganizationMembersData.splice(0, state.editOrganizationMembersData.length);
    },

    setAddOrganizationMemberDialogOpen: (state, action) => {
      state.addOrganizationMemberDialogOpen = action.payload;
    },

    setMailPreparationDialogOpen: (state, action) => {
      state.mailPreparationDialogOpen = action.payload;
    },

    setMailPreparationCheckingDialogOpen: (state, action) => {
      state.mailPreparationCheckingDialogOpen = action.payload;
    },
    MailPreparationCheck: (state, action) => {
      let workMatchesMailJobs = [];
      let existedMailJobs = action.payload.existedMailJobs;
      let newMailJobs = action.payload.newMailJobs;

      for (const item of newMailJobs) {
        for (let i = 0; i < existedMailJobs.length; i++) {
          item.ERP === existedMailJobs[i].Position.split('_')[0] && workMatchesMailJobs.push(existedMailJobs[i]);
        }
      }
      state.matchesMailJobs1 = workMatchesMailJobs;
    },

    setAccessPosition: (state, action) => {
      state.accessPosition = action.payload;
    },
  },
});

export const {
  setSnackBarMessage,
  setItemsValue,
  setEditOrganizationDialogOpen,
  setEditOrganizationData,
  setEditOrganizationMembersData,
  clearEditOrganizationMembersData,
  setAddOrganizationMemberDialogOpen,
  setMailPreparationDialogOpen,
  setAccessPosition,
  setMailPreparationCheckingDialogOpen,
  MailPreparationCheck,
} = AccessExecutionSlice.actions;

export default AccessExecutionSlice.reducer;
