import { createSlice, current } from '@reduxjs/toolkit';

const currentDate = () => {
  let d = new Date();
  let date = [d.getFullYear(), ('0' + (d.getMonth() + 1)).slice(-2), ('0' + d.getDate()).slice(-2)].join('-');
  return date;
};

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

const currentYear = new Date().getFullYear();

//заполняем массив выходных дней
let workHolidaysArr = [];
for (let month = 1; month <= 12; month++) {
  let DInM = daysInMonth(month, currentYear);
  for (let day = 1; day <= DInM; day++) {
    if (new Date(currentYear, month - 1, day).getDay() === 0 || new Date(currentYear, month - 1, day).getDay() === 6) {
      workHolidaysArr.push([month, day]);
    }
  }
}

const initialState = {
  daysInYearState: '',
  vacationArray: [],
  holidays: workHolidaysArr,
  snackBarMessage: '',
  planningYear: currentYear,
};

const VacationPlanningExecutionSlice = createSlice({
  name: 'VacationPlanningExecutionState',
  initialState,

  reducers: {
    setDaysInYearState: (state, action) => {
      state.daysInYearState = action.payload;
    },

    setVacationArray: (state, action) => {
      state.vacationArray = action.payload;
    },

    setSnackBarMessage: (state, action) => {
      state.snackBarMessage = action.payload;
    },

    setPlanningYear: (state, action) => {
      state.planningYear = action.payload;
    },

    handleVacationDaysCheck: (state, action) => {
      let index = action.payload.index;
      let workObj = state.vacationArray[index];
      let workVacationDaysArr = state.vacationArray[index].vacationDays;

      if (workVacationDaysArr.some((elem) => elem[0] === action.payload.monthNo && elem[1] === action.payload.day)) {
        workVacationDaysArr = workVacationDaysArr.filter((elem) => elem[0] !== action.payload.monthNo || elem[1] !== action.payload.day);
      } else {
        if (state.vacationArray[index].totalVacationDays - state.vacationArray[index].vacationDays.length > 0) {
          workVacationDaysArr.push([action.payload.monthNo, action.payload.day]);
        } else {
          state.snackBarMessage = '!Нет свободных дней отпуска для распределения';
        }
      }

      workObj = { ...workObj, vacationDays: workVacationDaysArr };
      state.vacationArray[index] = workObj;
    },

    handleLockCheckBoxChange: (state, action) => {
      let index = action.payload;
      let workObj = state.vacationArray[index];
      workObj.isLocked === 1 ? (workObj.isLocked = 0) : (workObj.isLocked = 1);
      state.vacationArray[index] = workObj;
    },
  },
});

export const { setDaysInYearState, setVacationArray, setSnackBarMessage, setPlanningYear, handleVacationDaysCheck, handleLockCheckBoxChange } =
  VacationPlanningExecutionSlice.actions;

export default VacationPlanningExecutionSlice.reducer;
