import React from 'react';
import { TextField, useMediaQuery, useTheme } from '@mui/material';

function TTInput({ TT, setTT, posValue }) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <TextField
      label='Номер TT'
      type='text'
      InputLabelProps={{
        shrink: true,
      }}
      value={TT}
      onChange={(e) => {
        setTT(e.target.value);
      }}
      size='small'
      sx={{ minWidth: mobileView ? '185px' : '135px', width: mobileView ? '185px' : '210px' }}
      inputProps={{ style: { fontSize: '12px' } }}
      disabled={posValue ? false : true}
    />
  );
}

export default TTInput;
