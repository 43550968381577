import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ButtonGroup, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar, ruRU, GridActionsCellItem } from '@mui/x-data-grid';

import ClearIcon from '@mui/icons-material/Clear';

import AlertDialog from '../../Access/UI/AlertDialog';

import { useDispatch, useSelector } from 'react-redux';

import { setSelectedRows, setZIPNewSelectedCartDialogOpen, deleteSelectedZipFromCart, changeZIPMountQty, clearZIPMountParams } from '../../../Redux/Slices/ZIPExecutionSlice';

import { useSaveMountedZIPMutation } from '../../../Redux/API/ZIPExecutionAPI';

import ZIPNewSelectedCartDialogFooter from './ZIPNewSelectedCartDialogFooter';
import MySnackbar from '../../Access/UI/MySnackbar';

function ZIPNewSelectedCartDialog() {
  const { user } = useSelector((state) => state.Auth);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { selectedRows, ZIPNewSelectedCartDialogOpen, ZIPMountParams } = useSelector((state) => state.ZIPExecution);
  const [SaveMountedZIP] = useSaveMountedZIPMutation();

  const dispatch = useDispatch();
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const columns = [
    {
      field: 'id',
      headerName: '',
      width: 50,
      filterable: false,
      sortable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    { field: 'NFS', headerName: 'Код НФС', width: 90, sortable: false },
    { field: 'Description', headerName: 'Описание', flex: 1, minWidth: 400, sortable: false },
    { field: 'SN', headerName: 'Серийный номер/партия', width: 150, sortable: false },
    {
      field: 'Qty_res',
      headerName: 'Кол-во',
      width: 80,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={`Максимальное кол-во: ${params.row.Qty_res}`}>
          <input
            label='кол-во'
            type='number'
            value={params.row.ZIPMountQty}
            onChange={(e) => {
              console.log(params);
              dispatch(changeZIPMountQty({ id: params.id, value: e.target.value }));
            }}
            size='small'
            style={{ fontSize: '15px', width: '50px' }}
          />
        </Tooltip>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 50,
      sortable: false,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ClearIcon sx={{ color: 'red', fontSize: '15px' }} />}
          label='Удалить'
          onClick={() => {
            dispatch(deleteSelectedZipFromCart(params.id));
          }}
        />,
      ],
    },
  ];

  function handleZIPNewSelectedCartDialogClose(event, reason) {
    if (reason && reason === 'backdropClick') return;
    dispatch(setZIPNewSelectedCartDialogOpen(false));
    dispatch(clearZIPMountParams());
  }

  async function saveZIPNewSelectedCartHandler() {
    const res = await SaveMountedZIP({ user, MountedZipArr: [ZIPMountParams, ...selectedRows] }).unwrap();
    dispatch(setZIPNewSelectedCartDialogOpen(false));
    dispatch(setSelectedRows('clear'));
    dispatch(clearZIPMountParams());
    setSnackBarMessage(res.res);
  }

  return (
    <>
      <Dialog open={ZIPNewSelectedCartDialogOpen} onClose={handleZIPNewSelectedCartDialogClose} fullWidth maxWidth='lg'>
        <DialogTitle sx={{ fontSize: mobileView ? '13px' : '20px' }}>Оформление монтажа на выбранных строках </DialogTitle>

        <DialogContent>
          <DataGrid
            //  density='compact'
            disableColumnMenu
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            hideFooter
            rowHeight={22}
            columnHeaderHeight={22}
            rows={selectedRows}
            columns={columns}
            sx={{
              fontSize: '10px',
              minWidth: mobileView ? '300px' : '980px',
              width: mobileView ? '300px' : 'auto',
              maxHeight: '50vh',

              '& .MuiDataGrid-columnHeaderTitle': {
                whiteSpace: 'normal',
                lineHeight: 'normal',
              },
              '& .MuiDataGrid-columnHeader': {
                // Forced to use important since overriding inline styles
                height: 'unset !important',
              },
              '& .MuiDataGrid-columnHeaders': {
                // Forced to use important since overriding inline styles
                maxHeight: '168px ',
              },
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                outline: 'none',
              },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                outline: 'none',
              },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: false,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
              },
            }}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          />
          <ZIPNewSelectedCartDialogFooter />
        </DialogContent>
        <DialogActions>
          <ButtonGroup size='small'>
            <Button
              sx={{ fontSize: '9px' }}
              onClick={() => {
                //dispatch(clearEditOrganizationMembersData());
                handleZIPNewSelectedCartDialogClose();
              }}
            >
              Отмена
            </Button>
            <AlertDialog title='Вы уверены ?' response={saveZIPNewSelectedCartHandler}>
              {(showAlertDialog) => (
                <Button sx={{ fontSize: '9px' }} onClick={showAlertDialog} autoFocus disabled={Object.keys(ZIPMountParams).length === 0}>
                  Сохранить
                </Button>
              )}
            </AlertDialog>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
      <MySnackbar message={snackBarMessage} setMessage={setSnackBarMessage} />
    </>
  );
}

export default ZIPNewSelectedCartDialog;
