import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  checkedArr: [],
  globalCheck: false,
};

const UnmountExecutionSlice = createSlice({
  name: 'UnmountExecutionState',
  initialState,

  reducers: {
    setUnmountState: (state, action) => {
      const unmountStateRow = state.checkedArr.find((elem) => elem.ID === action.payload.res.ID);
      if (unmountStateRow) unmountStateRow.unmounted_state = action.payload.value;
      if (action.payload.value === 'Исправно') unmountStateRow.unmounted_state_description = '';
    },
    setUnmountStateDescription: (state, action) => {
      const unmountStateDescRow = state.checkedArr.find((elem) => elem.ID === action.payload.res.ID);
      if (unmountStateDescRow) unmountStateDescRow.unmounted_state_description = action.payload.value;
    },
    setUnmountCheckbox: (state, action) => {
      if (!action.payload.checked) {
        for (let i = 0; i < state.checkedArr.length; i++) {
          if (state.checkedArr[i].ID === action.payload.res.ID) state.checkedArr.splice(i, 1);
        }
      } else {
        state.checkedArr.push(action.payload.res);
      }
    },
    resetCheckedArr: (state) => {
      state.checkedArr = [];
    },
    toggleCheckGlobal: (state, action) => {
      state.checkedArr = [];

      state.globalCheck ? (state.checkedArr = []) : action.payload.map((elem) => state.checkedArr.push({ ...elem, checked: state.globalCheck ? false : true }));

      state.globalCheck = !state.globalCheck;
    },
  },
});

export const { resetCheckedArr, toggleCheckGlobal, setUnmountCheckbox, setUnmountState, setUnmountStateDescription } = UnmountExecutionSlice.actions;

export default UnmountExecutionSlice.reducer;
