import React from 'react';

import { TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setZIPMountDate } from '../../../Redux/Slices/ZIPExecutionSlice';

function ZIPNewMountDateInput() {
  const { ZIPMountPosition, ZIPMountParams, ZIPMountDate } = useSelector((state) => state.ZIPExecution);
  const dispatch = useDispatch();

  return (
    <TextField
      id='date'
      label='Дата монтажа'
      type='date'
      size='small'
      value={ZIPMountParams?.mount_date ?? ZIPMountDate}
      onChange={(e) => {
        dispatch(setZIPMountDate(e.target.value));
      }}
      sx={{ minWidth: 130 }}
      inputProps={{ style: { fontSize: '12px' } }}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={ZIPMountPosition ? false : true}
    />
  );
}

export default ZIPNewMountDateInput;
