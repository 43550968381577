import React, { useState } from 'react';
import { Button, Dialog, DialogContent, TextField, DialogActions, DialogTitle } from '@mui/material';
import { useAccessInfoAndMemoDialogSaveMutation } from '../../../Redux/API/AccessExecutionAPI';
import { useSelector } from 'react-redux';
import MySnackbar from './MySnackbar';

function AccessInfoAndMemoDialog({ accessInfoAndMemoDialogOpen, setAccessInfoAndMemoDialogOpen, data }) {
  const { user } = useSelector((state) => state.Auth);
  const [AccessInfoAndMemoDialogSave] = useAccessInfoAndMemoDialogSaveMutation();
  const [snackBarMessage, setSnackBarMessage] = useState('');

  function handleClose(event, reason) {
    // if (reason && reason === 'backdropClick') return;
    setAccessInfoAndMemoDialogOpen(false);
  }

  async function hanleSubmit(e) {
    e.preventDefault();
    const res = await AccessInfoAndMemoDialogSave({ id: data.id, user, type: data.type, data: e.target[0].value }).unwrap();
    setSnackBarMessage(res.res);
    handleClose();
  }
  return (
    <>
      <Dialog open={accessInfoAndMemoDialogOpen} onClose={handleClose} component='form' onSubmit={hanleSubmit} fullWidth maxWidth='md'>
        <DialogTitle sx={{ padding: '3px 20px' }}>{data.title}</DialogTitle>
        <DialogContent dividers sx={{ padding: 0 }}>
          <TextField
            multiline
            defaultValue={data.data}
            fullWidth
            inputProps={{ style: { lineHeight: '12px', fontSize: '12px' }, readOnly: data.type === 'PositionAccessInfo' && user.role !== 'Admin' ? true : false }}
            sx={{ '& fieldset': { border: 'none' } }}
          />
        </DialogContent>
        <DialogActions>
          <Button size='small' sx={{ fontSize: '9px' }} onClick={handleClose}>
            Отмена
          </Button>
          {data.type === 'PositionAccessInfo' && user.role !== 'Admin' ? (
            ''
          ) : (
            <Button size='small' type='submit' sx={{ fontSize: '9px' }} autoFocus>
              Сохранить
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <MySnackbar message={snackBarMessage} setMessage={setSnackBarMessage} />
    </>
  );
}

export default AccessInfoAndMemoDialog;
