import React from 'react';
import { Button, Dialog, DialogActions, AlertTitle, Alert, Box, ButtonGroup } from '@mui/material';

import { setMailPreparationCheckingDialogOpen } from '../../../Redux/Slices/AccessExecutionSlice';

import { useDispatch, useSelector } from 'react-redux';

function MailPreparationCheckingDialog({ callback, matchesMailJobs, AccessMailJobsDuplicateDelete }) {
  const dispatch = useDispatch();
  const { mailPreparationCheckingDialogOpen } = useSelector((state) => state.AccessExecution);

  function handleMailPreparationCheckingDialogClose(event, reason) {
    if (reason && reason === 'backdropClick') return;
    dispatch(setMailPreparationCheckingDialogOpen(false));
  }

  return (
    <Dialog open={mailPreparationCheckingDialogOpen} onClose={handleMailPreparationCheckingDialogClose} maxWidth='lg'>
      {/* <DialogContent> */}
      <Alert severity='warning'>
        <AlertTitle>Внимание!</AlertTitle>
        На следующие позиции уже оформлены задачи на допуск:
        <Box sx={{ marginTop: '20px' }}>
          {matchesMailJobs.map((item, i) => (
            <p key={i} style={{ fontSize: '10px' }}>
              <strong>
                {item.Position} на {item.Organizations} с {item.startAccessDate} по {item.endAccessDate}. Оформил {item.Job_Date_Create} {item.Job_Author}
              </strong>
            </p>
          ))}
        </Box>
      </Alert>
      {/* </DialogContent> */}
      <DialogActions>
        <ButtonGroup size='small'>
          <Button sx={{ fontSize: '9px' }} onClick={handleMailPreparationCheckingDialogClose}>
            Отредактировать задачу
          </Button>
          <Button sx={{ fontSize: '9px' }} onClick={AccessMailJobsDuplicateDelete}>
            Удалить дубликаты из задачи
          </Button>
          <Button
            sx={{ fontSize: '9px' }}
            autoFocus
            onClick={() => {
              dispatch(setMailPreparationCheckingDialogOpen(false));
              callback();
            }}
          >
            Оформить письма
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}

export default MailPreparationCheckingDialog;
