import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useDropzone } from 'react-dropzone';
import MySnackbar from '../../Access/UI/MySnackbar';

function MyDropzone({ handleEstimateFileUpload }) {
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const onDropAccepted = useCallback((acceptedFiles) => {
    handleEstimateFileUpload(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, fileRejections } = useDropzone({
    onDropAccepted,
    maxFiles: 1,
    maxSize: 100000,
    accept: { 'application/vnd.ms-excel': ['.xls'], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] },
  });

  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    height: '60vh',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: 'gray',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const focusedStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  useEffect(() => {
    if (fileRejections[0]?.errors[0].code) {
      switch (fileRejections[0].errors[0].code) {
        case 'file-too-large': {
          setSnackBarMessage('!Ошибка: Загружаемый файл больше 100кб');
          break;
        }
        case 'file-invalid-type': {
          setSnackBarMessage('!Ошибка: Тип файла может быть .xls или .xlsx');
          break;
        }
        case 'too-many-files': {
          setSnackBarMessage('!Ошибка: Загрузить можно только один файл сметы');
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [fileRejections]);
  return (
    <>
      <Box
        align='center'
        sx={{
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #dedede',
          borderRadius: '5px',
        }}
      >
        <Box {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <CloudUploadOutlinedIcon sx={{ fontSize: '100px' }} />
          <Typography variant='h5'>Перетащите файл сметы. Или кликните на поле для выбора файла</Typography>
          <Typography mt='20px' variant='subtitle1' sx={{ color: 'lightGray' }}>
            Формат файла: .xls или .xlsx. Максимальный размер 100кб
          </Typography>
        </Box>
      </Box>
      <MySnackbar message={snackBarMessage} setMessage={setSnackBarMessage} />
    </>
  );
}

export default MyDropzone;
