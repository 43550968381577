import React from 'react';

import { Box, Paper, Chip } from '@mui/material';
import UploadZIPfile from './UI/UploadZIPfile';
import UploadOSfile from './UI/UploadOSfile';

function ZIPSettings() {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Chip label='Настройки' sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} />

          {/* <Divider /> */}
          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', height: '80vh', padding: '10px' }}>
              <UploadZIPfile />
              <UploadOSfile />
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default ZIPSettings;
