import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';

import { useSaveMoveRegisterEntryMutation } from '../../../Redux/API/MoveExecutionAPI';
import MovePositionInput from './MovePositionInput';
import ERPInput from '../../ZIP/ZIPSelectedCard/UI/ERPInput';
import GFKCodeInput from './GFKCodeInput';

import { useNavigate } from 'react-router-dom';
import MoveExecutionBtn from './MoveExecutionBtn';
import MoveAwaitingAlert from './MoveAwaitingAlert';

function MoveActionFooter() {
  const { movePositionTo, checkedArr, useGFKCode } = useSelector((state) => state.MoveExecution);
  const [SaveMoveRegisterEntry] = useSaveMoveRegisterEntryMutation();

  const navigate = useNavigate();

  const [awaitingAlertOpen, setAwaitingAlertOpen] = useState(false);

  function handleAwaitingAlertClose(event, reason) {
    if (reason && reason === 'backdropClick') return;
    setAwaitingAlertOpen(false);
  }

  function MoveExecutionBtnHandler() {
    setAwaitingAlertOpen(true);
    SaveMoveRegisterEntry({
      ERP_From: checkedArr[0].ERP,
      GFK_From: checkedArr[0].GFK,
      Address_From: checkedArr[0].Address,
      ERP_To: movePositionTo.position_ERP,
      GFK_To: useGFKCode,
      Address_To: movePositionTo.position_addr,
      Actives: checkedArr,
    }).then((fulfilled) => {
      // setSaving(false);
      if (fulfilled.data.res === 'Saved successfully!') {
        setAwaitingAlertOpen(false);
        navigate('../MoveRegister');
      }
    });
  }

  return (
    <>
      <Stack direction='row' spacing='5px' mt='15px'>
        <MovePositionInput />
        <ERPInput posERP={movePositionTo?.position_ERP} posValue={''} />
        <GFKCodeInput />
        <MoveExecutionBtn handler={MoveExecutionBtnHandler} movePositionTo={movePositionTo} />
      </Stack>
      <MoveAwaitingAlert awaitingAlertOpen={awaitingAlertOpen} handleAwaitingAlertClose={handleAwaitingAlertClose} title='Запись в реестр перемещения оборудования. Ожидайте..' />
    </>
  );
}

export default MoveActionFooter;
