import React, { useState, useEffect } from 'react';
import { TextField, Box, Paper, LinearProgress, Chip, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useMoveSearchQuery } from '../../Redux/API/MoveExecutionAPI';
import { resetCheckedArr } from '../../Redux/Slices/MoveExecutionSlice';

import MoveSearchResult from './UI/MoveSearchResult';

function MoveExecution() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const [MoveDesc, setMoveDesc] = useState('');
  const [skip, setSkip] = useState(true);

  const dispatch = useDispatch();
  const { data: MoveData, isFetching } = useMoveSearchQuery(MoveDesc, { skip });

  useEffect(() => {
    dispatch(resetCheckedArr());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let delay;
    setSkip(true);

    if (MoveDesc.trim() !== '' && MoveDesc.length > 2) {
      delay = setTimeout(() => {
        setSkip(false);
      }, 700);
    } else {
      setSkip(true);
    }
    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [MoveDesc]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Chip label={mobileView ? 'Перемещение оборудования' : 'Оформление перемещения оборудования'} sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} />

          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', minHeight: '80vh', padding: '10px' }}>
              <TextField
                fullWidth
                label='Поиск оборудования для перемещения'
                variant='outlined'
                helperText='Введите наименование оборудования для перемещения, код НФС, серийный или инвентарный номер, код ЕРП позиции для поиска'
                size='small'
                value={MoveDesc}
                onChange={(e) => {
                  setMoveDesc(e.target.value);
                }}
              />
              {isFetching && <LinearProgress />}
              <MoveSearchResult data={MoveData} />
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default MoveExecution;
