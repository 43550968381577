import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

function PositionsSearchResult({ data }) {
  return (
    <>
      {data && data !== '' && (
        <TableContainer component={Paper} sx={{ maxHeight: '70vh', marginTop: '10px' }}>
          <Table aria-label='simple table' stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  <b>ЕРП</b>
                </TableCell>
                <TableCell align='center'>
                  <b>Номер позиции</b>
                </TableCell>
                <TableCell align='center'>
                  <b>Наименование</b>
                </TableCell>
                <TableCell align='center'>
                  <b>MRB</b>
                </TableCell>
                <TableCell align='center'>
                  <b>TNN</b>
                </TableCell>
                <TableCell align='center'>
                  <b>Номер МТС</b>
                </TableCell>
                <TableCell align='center'>
                  <b>Адрес</b>
                </TableCell>
                <TableCell align='center'>
                  <b>Координаты</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((res, i) => (
                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                  <TableCell sx={{ fontSize: '12px' }} align='center'>
                    {res.position_ERP}
                  </TableCell>
                  <TableCell sx={{ fontSize: '12px' }} align='center'>
                    {res.position_num}
                  </TableCell>
                  <TableCell sx={{ fontSize: '12px' }} align='center'>
                    {res.position_name}
                  </TableCell>
                  <TableCell sx={{ fontSize: '12px' }} align='center'>
                    {res.position_MRB}
                  </TableCell>
                  <TableCell sx={{ fontSize: '12px' }} align='center'>
                    {res.position_TNN}
                  </TableCell>
                  <TableCell sx={{ fontSize: '12px' }} align='center'>
                    {res.position_MTS_num}
                  </TableCell>
                  <TableCell sx={{ fontSize: '10px' }} align='center'>
                    {res.position_addr}
                  </TableCell>
                  <TableCell sx={{ fontSize: '10px' }} align='center'>
                    <a href={'https://www.google.com/maps/place/' + res.position_coordinates.replace(/,/gi, '.')} target='_blank' rel='noreferrer'>
                      {res.position_coordinates}
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default PositionsSearchResult;
