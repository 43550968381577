import React from 'react';
import { TextField, Button, ButtonGroup, Dialog, Divider, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';

import { useAccesSettingsActionMutation } from '../../../../Redux/API/AccessExecutionAPI';

function AddOrganizationEntryDialog({ table, addOrganizationEntryDialogObject, setAddOrganizationEntryDialogObject }) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, open, title } = addOrganizationEntryDialogObject;

  const [AccessSettingsAction] = useAccesSettingsActionMutation();

  function handleDialogClose(event, reason) {
    if (reason && reason === 'backdropClick') return;
    setAddOrganizationEntryDialogObject({ ...addOrganizationEntryDialogObject, open: false });
  }

  function handleSubmit(e) {
    e.preventDefault();
    let workData = {};
    for (let elem of data) {
      workData = { ...workData, [elem.name]: e.target.elements[elem.name].value };
    }

    AccessSettingsAction({ action: 'add', table: table, data: workData });
    handleDialogClose();
  }
  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle sx={{ fontSize: mobileView ? '10px' : '20px' }}>{title}</DialogTitle>

        <DialogContent dividers>
          {data.map((elem, i) => (
            <TextField
              key={i}
              defaultValue={elem.defaultValue}
              name={elem.name}
              margin='dense'
              fullWidth
              label={elem.label}
              variant='outlined'
              size='small'
              required
              disabled={elem.disabled}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <ButtonGroup size='small'>
            <Button sx={{ fontSize: mobileView ? '7px' : '9px' }} onClick={handleDialogClose}>
              Отмена
            </Button>
            <Button sx={{ fontSize: mobileView ? '7px' : '9px' }} type='submit' autoFocus>
              Сохранить
            </Button>
          </ButtonGroup>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddOrganizationEntryDialog;
