import React, { useState, useEffect } from 'react';
import { Box, Button, ButtonGroup, TextField, Popover, Divider } from '@mui/material';

import { useUnmountRegisterUserActionMutation } from '../../../../Redux/API/UnmountExecutionAPI';

import AlertDialog from '../../../Access/UI/AlertDialog';

function StateDescriptionEditPopover({ open, anchorEl, handleClose, stateDescriptionRowValue, user, setSnackBarMessage }) {
  const [stateEditValue, setStateEditValue] = useState('');
  const [UnmountRegisterUserAction] = useUnmountRegisterUserActionMutation();

  useEffect(() => {
    setStateEditValue(stateDescriptionRowValue.unmounted_state_description);
  }, [open]);

  async function handlePopoverClose() {
    const res = await UnmountRegisterUserAction({
      action: 'save_state',
      user,
      checkedArr: [{ ...stateDescriptionRowValue, unmounted_state_description: stateEditValue }],
    }).unwrap();
    handleClose();
    setSnackBarMessage(res.res);
  }

  return (
    <AlertDialog
      title='Вы уверены ?'
      response={() => {
        handlePopoverClose();
      }}
    >
      {(showAlertDialog) => (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            stateEditValue !== stateDescriptionRowValue.unmounted_state_description ? showAlertDialog() : handleClose();
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box sx={{ width: '400px' }}>
            <TextField
              multiline
              value={stateEditValue}
              fullWidth
              onChange={(e) => {
                setStateEditValue(e.target.value);
              }}
              sx={{ '& fieldset': { border: 'none' } }}
            />
            <Divider />
            <Box align='right'>
              <ButtonGroup size='small' sx={{ margin: '5px 5px 5px 0px' }}>
                <Button
                  sx={{ fontSize: '7px' }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Отмена
                </Button>
                <Button
                  disabled={stateEditValue === stateDescriptionRowValue.unmounted_state_description}
                  sx={{ fontSize: '7px' }}
                  onClick={() => {
                    showAlertDialog();
                  }}
                >
                  Сохранить
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Popover>
      )}
    </AlertDialog>
  );
}

export default StateDescriptionEditPopover;
