import React from 'react';
import { Checkbox } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setMoveCheckbox } from '../../../Redux/Slices/MoveExecutionSlice';

function MyCheckbox({ res }) {
  const dispatch = useDispatch();
  const checkedArr = useSelector((state) => state.MoveExecution.checkedArr);

  return (
    <Checkbox
      checked={checkedArr.includes(checkedArr.find((elem) => elem.ID === res.ID))}
      onChange={(e) => {
        dispatch(setMoveCheckbox({ res, checked: e.target.checked }));
      }}
      size='small'
    />
  );
}

export default MyCheckbox;
