import React, { useState, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, useMediaQuery, useTheme } from '@mui/material';

import { DataGrid, ruRU } from '@mui/x-data-grid';

import { useAccesSettingsActionMutation } from '../../../../Redux/API/AccessExecutionAPI';

function AccessSettingsItem({ columns, table, data, setSnackBarMessage }) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const [AccessSettingsAction] = useAccesSettingsActionMutation();

  const [promiseArguments, setPromiseArguments] = useState(null);

  const processRowUpdate = useCallback(
    (updatedRow, originalRow) =>
      new Promise((resolve, reject) => {
        if (JSON.stringify(updatedRow) !== JSON.stringify(originalRow)) {
          setPromiseArguments({ resolve, reject, updatedRow, originalRow });
        } else {
          resolve(originalRow);
        }
      }),
    []
  );

  const handleNo = () => {
    const { originalRow, resolve } = promiseArguments;
    resolve(originalRow);
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { updatedRow, originalRow, reject, resolve } = promiseArguments;
    try {
      await AccessSettingsAction({ action: 'edit', table: table, data: updatedRow });
      resolve(updatedRow);
      setPromiseArguments(null);
      setSnackBarMessage('Изменения успешно сохранены');
    } catch (error) {
      reject(originalRow);
      setPromiseArguments(null);
      setSnackBarMessage('!Изменения не сохранены');
    }
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }
    return (
      <Dialog open={!!promiseArguments} maxWidth='xs'>
        <DialogTitle>Сохранить изменения ?</DialogTitle>
        <DialogActions>
          <Button variant='contained' size='small' onClick={handleNo}>
            Нет
          </Button>
          <Button variant='contained' size='small' onClick={handleYes}>
            Да
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    data && (
      <>
        {renderConfirmDialog()}
        <DataGrid
          // density='compact'
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
          rowHeight={25}
          columnHeaderHeight={30}
          editMode='row'
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={() => {}}
          autoHeight
          rows={data}
          columns={columns}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            fontSize: '10px',
            mt: '10px',
            '& .MuiDataGrid-columnHeaderTitle': {
              whiteSpace: 'normal',
              lineHeight: 'normal',
            },
            '& .MuiDataGrid-columnHeader': {
              // Forced to use important since overriding inline styles
              height: 'unset !important',
            },
            '& .MuiDataGrid-columnHeaders': {
              // Forced to use important since overriding inline styles
              maxHeight: '168px !important',
            },
          }}
        />
      </>
    )
  );
}

export default AccessSettingsItem;
