import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Typography, Box, Divider, Paper, LinearProgress, Button, ButtonGroup, Chip, useMediaQuery, useTheme, Menu, MenuItem } from '@mui/material';

import { DataGrid, gridClasses, GridToolbar, ruRU } from '@mui/x-data-grid';

import { useGetMoveRegisterQuery } from '../../Redux/API/MoveExecutionAPI';
import { useMoveRegisterUserActionMutation } from '../../Redux/API/MoveExecutionAPI';
import { setRowSelectionModel, setSelectedRows, rowSelectionModelChangeHandler } from '../../Redux/Slices/MoveExecutionSlice';

import MySnackbar from '../Access/UI/MySnackbar';
import AlertDialog from '../Access/UI/AlertDialog';
import MoveAwaitingAlert from './UI/MoveAwaitingAlert';

function MoveRegister() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useSelector((state) => state.Auth);
  const { rowSelectionModel, selectedRows } = useSelector((state) => state.MoveExecution);
  const dispatch = useDispatch();

  const { data: MoveRegisterData, isLoading: isMoveRegisterLoading } = useGetMoveRegisterQuery({}, { refetchOnMountOrArgChange: true });
  const [MoveRegisterUserAction] = useMoveRegisterUserActionMutation();

  const [actPreparationAwaitingAlertOpen, setActPreparationAwaitingAlertOpen] = useState(false);

  const [stateSelectMenuAnchorEl, setStateSelectMenuAnchorEl] = useState(null);

  const stateSelectMenuOpen = Boolean(stateSelectMenuAnchorEl);
  const handleStateMenuButton = (event) => {
    setStateSelectMenuAnchorEl(event.currentTarget);
  };
  const handleStateMenuClose = () => {
    setStateSelectMenuAnchorEl(null);
  };

  const [snackBarMessage, setSnackBarMessage] = useState('');

  const columns = [
    { field: 'Filial', headerName: 'Филиал', width: 100 },
    { field: 'Move_Date', headerName: 'Дата оформления перемещения', width: 100 },
    { field: 'ERP_From', headerName: 'Код ЕРП откуда перемещается оборудование', width: 100 },
    { field: 'GFK_From', headerName: 'Код ГФК откуда перемещается оборудование', width: 150 },
    { field: 'Address_From', headerName: 'Адрес откуда перемещается оборудование', width: 300 },
    { field: 'ERP_To', headerName: 'Код ЕРП куда перемещается оборудование', width: 150 },
    { field: 'GFK_To', headerName: 'Код ГФК куда перемещается оборудование', width: 150 },
    { field: 'Address_To', headerName: 'Адрес куда перемещается оборудование', width: 300 },
    { field: 'move_active', headerName: 'Актив перемещаемого оборудования', width: 150 },
    { field: 'Move_NFS', headerName: 'Код НФС перемещаемого оборудования', width: 150 },
    { field: 'move_description', headerName: 'Описание перемещаемого оборудования', width: 300 },
    { field: 'move_SN', headerName: 'Серийный номер перемещаемого оборудования', width: 150 },
    { field: 'move_inventoryNum', headerName: 'Инвентарный номер перемещаемого оборудования', width: 150 },
    { field: 'move_PartNum', headerName: 'Парт-номер перемещаемого оборудования', width: 150 },
    { field: 'move_Qty', headerName: 'Кол-во перемещаемого оборудования', width: 100 },
  ];

  function handleActPreparationAwaitingAlertClose(event, reason) {
    if (reason && reason === 'backdropClick') return;
    setActPreparationAwaitingAlertOpen(false);
  }

  function actionHandler(msg) {
    dispatch(setRowSelectionModel([]));
    dispatch(setSelectedRows([]));
    if (msg !== '') setSnackBarMessage(msg);
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Chip label={mobileView ? 'Реестр перемещения' : 'Реестр перемещения оборудования'} sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} />

          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', minHeight: '80vh', padding: '10px' }}>
              {isMoveRegisterLoading ? (
                <LinearProgress />
              ) : MoveRegisterData?.length === 0 ? (
                <Box align='center'>Записи в реестре перемещения оборудования отсутствуют</Box>
              ) : (
                <>
                  <DataGrid
                    //  density='compact'
                    disableColumnMenu
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    checkboxSelection
                    hideFooter
                    rowHeight={27}
                    // autoHeight={true}
                    rows={MoveRegisterData}
                    columns={columns}
                    sx={{
                      height: '75vh',
                      fontSize: '10px',
                      '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                        display: 'none',
                      },
                      '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                      },
                      '& .MuiDataGrid-columnHeader': {
                        // Forced to use important since overriding inline styles
                        height: 'unset !important',
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        // Forced to use important since overriding inline styles
                        maxHeight: '168px !important',
                      },
                      [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                        outline: 'none',
                      },
                      [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                        outline: 'none',
                      },
                      '.isActedTrue': {
                        fontWeight: 100,
                        color: 'lightgrey',
                      },
                      '.isActedFalse': {
                        fontWeight: 700,
                      },
                    }}
                    getRowClassName={(params) => {
                      switch (params.row.isActed) {
                        case 'true': {
                          return 'isActedTrue';
                        }
                        case 'false': {
                          return 'isActedFalse';
                        }
                        default: {
                          break;
                        }
                      }
                    }}
                    onRowSelectionModelChange={(ids) => {
                      dispatch(rowSelectionModelChangeHandler({ ids, MoveRegisterData }));
                    }}
                    rowSelectionModel={rowSelectionModel}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: {
                          delimiter: ';',
                          utf8WithBom: true,
                          disableToolbarButton: mobileView,
                        },
                      },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                  />
                </>
              )}
              {MoveRegisterData?.length !== 0 && (
                <Box align='right' mt='10px'>
                  <ButtonGroup size='small' disabled={selectedRows.length === 0}>
                    {user.role === 'Admin' && (
                      <AlertDialog
                        title='Вы уверены ?'
                        response={async () => {
                          const res = await MoveRegisterUserAction({ action: 'delete', user, data: selectedRows }).unwrap();
                          actionHandler(res.res);
                        }}
                      >
                        {(showAlertDialog) => (
                          <Button sx={{ fontSize: mobileView ? '7px' : '9px' }} color='error' onClick={showAlertDialog}>
                            {mobileView ? 'Удалить' : 'Удалить выбранные записи'}
                          </Button>
                        )}
                      </AlertDialog>
                    )}
                    <Button
                      sx={{ fontSize: mobileView ? '7px' : '9px' }}
                      onClick={async () => {
                        setActPreparationAwaitingAlertOpen(true);
                        const res = await MoveRegisterUserAction({ action: 'download_act', user, data: selectedRows }).unwrap();
                        actionHandler('');
                        res === 'Ready' && handleActPreparationAwaitingAlertClose();
                      }}
                    >
                      Скачать акт перемещения
                    </Button>
                    <Button sx={{ fontSize: mobileView ? '7px' : '9px' }} onClick={handleStateMenuButton}>
                      {mobileView ? 'Изменить состояние' : 'Изменить состояние выбранных записей'}
                    </Button>
                  </ButtonGroup>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>

      <Menu anchorEl={stateSelectMenuAnchorEl} open={stateSelectMenuOpen} onClose={handleStateMenuClose}>
        <MenuItem
          onClick={async () => {
            const res = await MoveRegisterUserAction({ action: 'change_state', state: 'true', user, data: selectedRows }).unwrap();
            handleStateMenuClose();
            actionHandler(res.res);
          }}
        >
          Отметить как обработанный
        </MenuItem>
        <MenuItem
          onClick={async () => {
            const res = await MoveRegisterUserAction({ action: 'change_state', state: 'false', user, data: selectedRows }).unwrap();
            handleStateMenuClose();
            actionHandler(res.res);
          }}
        >
          Отметить как не обработанный
        </MenuItem>
      </Menu>

      <MySnackbar message={snackBarMessage} setMessage={setSnackBarMessage} />
      <MoveAwaitingAlert
        awaitingAlertOpen={actPreparationAwaitingAlertOpen}
        handleAwaitingAlertClose={handleActPreparationAwaitingAlertClose}
        title='Подготовка акта перемещения оборудования'
      />
    </>
  );
}

export default MoveRegister;
