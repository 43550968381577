import { createSlice, current } from '@reduxjs/toolkit';

const currentDate = () => {
  let d = new Date();
  let date = [d.getFullYear(), ('0' + (d.getMonth() + 1)).slice(-2), ('0' + d.getDate()).slice(-2)].join('-');
  return date;
};

const initialState = {
  parsedXLSxRows: [],
  orderDate: currentDate(),
  snackBarMessage: '',
};

const PaymentExecutionSlice = createSlice({
  name: 'PaymentExecutionState',
  initialState,

  reducers: {
    setParsedXLSxRows: (state, action) => {
      state.parsedXLSxRows = action.payload;
    },

    changeRow: (state, action) => {
      const index = state.parsedXLSxRows.findIndex((elem) => elem.id === action.payload.id);

      if (index !== -1) {
        state.parsedXLSxRows[index] = action.payload;
      }
    },

    setOrderDate: (state, action) => {
      state.orderDate = action.payload;
    },

    setSnackBarMessage: (state, action) => {
      state.snackBarMessage = action.payload;
    },

    handleChangeOrderDate: (state, action) => {
      let workDate = new Date(
        state.parsedXLSxRows[0].WorkDate.split('.')[2] + '-' + state.parsedXLSxRows[0].WorkDate.split('.')[1] + '-' + state.parsedXLSxRows[0].WorkDate.split('.')[0]
      );
      let newOrderDate = new Date(action.payload.target.value);
      newOrderDate - workDate <= 0 ? (state.orderDate = action.payload.target.value) : (state.snackBarMessage = '!Дата заказа не может быть больше даты выполнения работ');
    },
  },
});

export const { setParsedXLSxRows, changeRow, setOrderDate, setSnackBarMessage, handleChangeOrderDate } = PaymentExecutionSlice.actions;

export default PaymentExecutionSlice.reducer;
