import React from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setMoveUseGFKCode } from '../../../Redux/Slices/MoveExecutionSlice';

function GFKCodeInput() {
  const dispatch = useDispatch();
  const { movePositionTo, useGFKCode } = useSelector((state) => state.MoveExecution);

  return (
    <Autocomplete
      id='GFK'
      filterOptions={(x) => x}
      options={movePositionTo ? [movePositionTo.position_MRB, movePositionTo.position_TNN] : []}
      sx={{ minWidth: '220px' }}
      size='small'
      onChange={(event, newValue) => {
        dispatch(setMoveUseGFKCode(newValue));
      }}
      value={useGFKCode}
      renderInput={(params) => (
        <TextField {...params} label='Код ГФК позиции' inputProps={{ ...params.inputProps, style: { fontSize: '12px' } }} InputLabelProps={{ style: { fontSize: '14px' } }} />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <span style={{ fontSize: '10px' }}>{option}</span>
          </li>
        );
      }}
      disabled={movePositionTo ? false : true}
    />
  );
}

export default GFKCodeInput;
