import React, { useCallback, useMemo } from 'react';
import { Box, TableCell } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { handleVacationDaysCheck, setSnackBarMessage } from '../../../Redux/Slices/VacationPlanningExecutionSlice';

function EmployeeYearVacationPlan({ i }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Auth);
  const { holidays, planningYear, vacationArray } = useSelector((state) => state.VacationPlanning);

  const toggleDay = useCallback(
    (props) => (e) => {
      user.role === 'Admin'
        ? dispatch(handleVacationDaysCheck(props))
        : vacationArray[i].employee === user.full_name &&
          (vacationArray[i].isLocked === 1 ? dispatch(setSnackBarMessage('!Изменения заблокированы.')) : dispatch(handleVacationDaysCheck(props)));
    },
    [dispatch]
  );

  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  let employeesHTMLarr = [];
  for (let monthNo = 1; monthNo <= 12; monthNo++) {
    for (let day = 1; day <= daysInMonth(monthNo, planningYear); day++) {
      employeesHTMLarr.push(
        <TableCell
          key={`${planningYear}_${monthNo}_${day}`}
          sx={{
            fontSize: '12px',
            padding: '4px',
            margin: '0px',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
            minWidth: '20px',
            width: '20px',
            minHeight: '20px',
            height: '20px',
            backgroundColor: holidays.some((elem) => elem[0] === monthNo && elem[1] === day)
              ? vacationArray[i].employee === user.full_name
                ? '#E6B0AA'
                : '#FDEDEC'
              : vacationArray[i].employee === user.full_name
              ? 'lightGray'
              : 'white',
          }}
          onClick={toggleDay({ monthNo, day, index: i })}
        >
          {vacationArray[i].vacationDays.some((elem) => elem[0] === monthNo && elem[1] === day) && (
            <Box sx={{ width: '10px', height: '10px', border: '1px solid black', backgroundColor: 'red', padding: '0px', marginLeft: '4px' }} />
          )}
        </TableCell>
      );
    }
  }
  return useMemo(
    () => employeesHTMLarr,

    [vacationArray[i]]
  );
}

export default EmployeeYearVacationPlan;
