import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useAccessPositionSearchQuery, AccessExecutionAPI } from '../../../Redux/API/AccessExecutionAPI';
import { setAccessPosition } from '../../../Redux/Slices/AccessExecutionSlice';

function AccessPositionInput() {
  const [positionDesc, setPositionDesc] = useState('');
  const [skip, setSkip] = useState(true);

  const dispatch = useDispatch();
  const { accessPosition } = useSelector((state) => state.AccessExecution);
  const { data: positionData, isFetching } = useAccessPositionSearchQuery(positionDesc, { skip });

  useEffect(() => {
    let delay;
    setSkip(true);
    dispatch(AccessExecutionAPI.util.invalidateTags(['Positions']));

    if (positionDesc.trim() !== '' && positionDesc.length > 2) {
      delay = setTimeout(() => {
        setSkip(false);
      }, 700);
    } else {
      setSkip(true);
      dispatch(AccessExecutionAPI.util.invalidateTags(['Positions']));
    }
    return () => clearTimeout(delay);

    // eslint-disable-next-line
  }, [positionDesc]);

  return (
    <>
      <Autocomplete
        id='position'
        freeSolo
        filterOptions={(x) => x}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.position_num + ' ' + option.position_name)}
        options={positionData ? positionData : []}
        sx={{ width: '400px' }}
        size='small'
        onChange={(event, newValue) => {
          dispatch(setAccessPosition(newValue));
        }}
        value={accessPosition ?? ''}
        inputValue={positionDesc}
        onInputChange={(event, newInputPosValue) => {
          setPositionDesc(newInputPosValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Позиция'
            helperText='Выберете позицию для оформления допуска'
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetching ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={props.id}>
              <span style={{ fontSize: '10px' }}>{option.position_num + ' ' + option.position_name}</span>
            </li>
          );
        }}
      />
    </>
  );
}

export default AccessPositionInput;
