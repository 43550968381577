import React from 'react';
import { Stack, Box, useMediaQuery, useTheme } from '@mui/material';
import ZIPNewPositionInput from './ZIPNewPositionInput';

import { useSelector, useDispatch } from 'react-redux';
import ERPInput from '../ZIPSelectedCard/UI/ERPInput';
import ZIPNewGFKCodeInput from './ZIPNewGFKCodeInput';
import ProjectInput from '../ZIPSelectedCard/UI/ProjectInput';

import { setZIPMountTT } from '../../../Redux/Slices/ZIPExecutionSlice';
import TTInput from '../ZIPSelectedCard/UI/TTInput';
import ZIPNewMountDateInput from './ZIPNewMountDateInput';

function ZIPNewSelectedCartDialogFooter() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { ZIPMountPosition, ZIPMountParams } = useSelector((state) => state.ZIPExecution);
  const dispatch = useDispatch();

  return mobileView ? (
    <>
      <Box mt='20px'>
        <ZIPNewPositionInput />
      </Box>
      <Stack direction='row' mt='10px' gap='5px'>
        <ZIPNewMountDateInput />
        <ERPInput posERP={ZIPMountPosition?.position_ERP} posValue={ZIPMountPosition} />
      </Stack>
      <Box mt='10px'>
        <ZIPNewGFKCodeInput />
      </Box>
      <Stack direction='row' mt='10px' gap='5px'>
        <ProjectInput project={ZIPMountParams?.project ?? ''} posValue={ZIPMountPosition} />
        <TTInput
          TT={ZIPMountParams?.TT ?? ''}
          setTT={(e) => {
            dispatch(setZIPMountTT(e));
          }}
          posValue={ZIPMountPosition}
        />
      </Stack>
    </>
  ) : (
    <Stack direction='row' mt='20px' gap='5px'>
      <ZIPNewPositionInput />
      <ZIPNewMountDateInput />
      <ERPInput posERP={ZIPMountPosition?.position_ERP} posValue={ZIPMountPosition} />
      <ZIPNewGFKCodeInput />
      <ProjectInput project={ZIPMountParams?.project ?? ''} posValue={ZIPMountPosition} />
      <TTInput
        TT={ZIPMountParams?.TT ?? ''}
        setTT={(e) => {
          dispatch(setZIPMountTT(e));
        }}
        posValue={ZIPMountPosition}
      />
    </Stack>
  );
}

export default ZIPNewSelectedCartDialogFooter;
