import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { AppBar, Toolbar, IconButton, Typography, Box, Drawer, List, ListItemButton, ListItemText, ListSubheader, Divider, Collapse, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import UserCard from './UserCard';

import NFS from './NFS/NFS';

import ZIPSettings from './ZIP/ZIPSettings/ZIPSettings';

import UnmountRegister from './Unmount/UnmountRegister';
import UnmountExecution from './Unmount/UnmountExecution/UnmountExecution';
import ZIPFulness from './ZIP/ZIPFulness/ZIPFulness';
import MoveRegister from './Move/MoveRegister';
import MoveExecution from './Move/MoveExecution';
import AccessInfo from './Access/AccessInfo';
import AccessMails from './Access/AccessMails';
import ZIPNew from './ZIP/ZIPNew';
import OrderPreparation from './Payments/OrderPreparation';
import PositionsNew from './Positions/PositionsNew';
import VacationPlanning from './VacationPlanning/VacationPlanning';
import UsedZIPRegisterNew from './ZIP/UsedZIPRegister/UsedZIPRegisterNew';
import AccessMailSettings from './Access/AccessMailSettings';
import UnmountRegisterNew from './Unmount/UnmountRegisterNew';

function MainHandbook() {
  const { user } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawerstate, setDrawerState] = useState(false);
  const [openZIP, setOpenZIP] = useState(false);
  const [openUnmount, setOpenUnmount] = useState(false);
  const [openMove, setOpenMove] = useState(false);
  const [openAccess, setOpenAccess] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {/* <Box sx={{ flexGrow: 1, backgroundColor: 'red', color: 'red' }}> */}
      <AppBar position='fixed'>
        <Toolbar variant='dense'>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2 }}
            onClick={() => {
              setDrawerState(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' sx={{ flexGrow: 1 }}>
            beeHandBook v.0.48
          </Typography>
          <UserCard />
        </Toolbar>
      </AppBar>
      {/* </Box> */}
      <Box sx={{ flexGrow: 1, marginTop: '48px', height: 'calc(100vh - 48px)' }}>
        <Routes>
          {/* <Route path='ZIP' element={<ZIP />} /> */}
          <Route path='ZIPNew' element={<ZIPNew />} />
          {/* <Route path='UsedZIPRegister' element={<UsedZIPRegister />} /> */}
          <Route path='UsedZIPRegisterNew' element={<UsedZIPRegisterNew />} />
          <Route path='ZIPFulness' element={<ZIPFulness />} />
          <Route path='UnmountRegister' element={<UnmountRegister />} />
          <Route path='UnmountRegisterNew' element={<UnmountRegisterNew />} />
          <Route path='UnmountExecution' element={<UnmountExecution />} />

          <Route path='MoveRegister' element={<MoveRegister />} />
          <Route path='MoveExecution' element={<MoveExecution />} />

          <Route path='AccessMailSettings' element={<AccessMailSettings />} />
          <Route path='AccessInfo' element={<AccessInfo />} />
          <Route path='AccessMails' element={<AccessMails />} />
          <Route path='OrderPreparation' element={<OrderPreparation />} />
          <Route path='NFS' element={<NFS />} />
          <Route path='Positions' element={<PositionsNew />} />
          <Route path='Vacation_planning' element={<VacationPlanning />} />
          <Route path='ZIPSettings' element={<ZIPSettings />} />
        </Routes>
      </Box>

      <Drawer
        anchor='left'
        open={drawerstate}
        onClose={() => {
          setDrawerState(false);
        }}
      >
        <List sx={{ width: '300px', maxWidth: 360, bgcolor: 'background.paper' }} component='nav'>
          <ListItemButton
            onClick={() => {
              setOpenZIP(!openZIP);
            }}
          >
            <ListItemText primary='ЗИП' />
            {openZIP ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openZIP} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('ZIPNew');
                  setDrawerState(false);
                }}
              >
                <ListItemText primary='Поиск и оформление ЗИП' primaryTypographyProps={{ fontSize: '14px' }} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('UsedZIPRegisterNew');
                  setDrawerState(false);
                }}
              >
                <ListItemText primary='Реестр использованных ЗИП' primaryTypographyProps={{ fontSize: '14px' }} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('ZIPFulness');
                  setDrawerState(false);
                }}
              >
                <ListItemText primary='Анализ достаточности ЗИП-ЛО' primaryTypographyProps={{ fontSize: '14px' }} />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            onClick={() => {
              setOpenUnmount(!openUnmount);
            }}
          >
            <ListItemText primary='Демонтаж' />
            {openUnmount ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openUnmount} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('UnmountExecution');
                  setDrawerState(false);
                }}
              >
                <ListItemText primary='Оформление демонтированного оборудования' primaryTypographyProps={{ fontSize: mobileView ? '11px' : '14px' }} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('UnmountRegister');
                  setDrawerState(false);
                }}
              >
                <ListItemText primary='Реестр демонтированного оборудования' primaryTypographyProps={{ fontSize: mobileView ? '11px' : '14px' }} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('UnmountRegisterNew');
                  setDrawerState(false);
                }}
              >
                <ListItemText primary='Реестр демонтированного оборудования_New (Beta)' primaryTypographyProps={{ fontSize: mobileView ? '11px' : '14px' }} />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            onClick={() => {
              setOpenMove(!openMove);
            }}
          >
            <ListItemText primary='Перемещение' />
            {openMove ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openMove} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('MoveExecution');
                  setDrawerState(false);
                }}
              >
                <ListItemText primary='Оформление перемещения оборудования' primaryTypographyProps={{ fontSize: mobileView ? '11px' : '14px' }} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('MoveRegister');
                  setDrawerState(false);
                }}
              >
                <ListItemText primary='Реестр перемещения оборудования' primaryTypographyProps={{ fontSize: mobileView ? '11px' : '14px' }} />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            onClick={() => {
              setOpenAccess(!openAccess);
            }}
          >
            <ListItemText primary='Допуски' />
            {openAccess ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openAccess} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('AccessMailSettings');
                  setDrawerState(false);
                }}
              >
                <ListItemText primary='Настройки' primaryTypographyProps={{ fontSize: mobileView ? '11px' : '14px' }} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('AccessInfo');
                  setDrawerState(false);
                }}
              >
                <ListItemText primary='Информация по допуску на позиции' primaryTypographyProps={{ fontSize: mobileView ? '11px' : '14px' }} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('AccessMails');
                  setDrawerState(false);
                }}
              >
                <ListItemText primary='Подготовка писем на допуск' primaryTypographyProps={{ fontSize: mobileView ? '11px' : '14px' }} />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            onClick={() => {
              setOpenPayment(!openPayment);
            }}
          >
            <ListItemText primary='Оплаты' />
            {openPayment ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openPayment} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('OrderPreparation');
                  setDrawerState(false);
                }}
              >
                <ListItemText primary='Формирование заказа из сметы' primaryTypographyProps={{ fontSize: mobileView ? '11px' : '14px' }} />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            onClick={() => {
              navigate('NFS');
              setDrawerState(false);
            }}
          >
            <ListItemText primary='Коды НФС' />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              navigate('Positions');
              setDrawerState(false);
            }}
          >
            <ListItemText primary='Справочник позиций' />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              navigate('Vacation_planning');
              setDrawerState(false);
            }}
          >
            <ListItemText primary='Планирование отпусков' />
          </ListItemButton>

          {user.role === 'Admin' && (
            <ListItemButton
              onClick={() => {
                navigate('ZIPSettings');
                setDrawerState(false);
              }}
            >
              <ListItemText primary='Настройки' />
            </ListItemButton>
          )}
        </List>
      </Drawer>
    </>
  );
}

export default MainHandbook;
