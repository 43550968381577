import React, { useState } from 'react';
import { Box, Divider, Paper, Tabs, Tab, Chip, Button, Stack, LinearProgress, useMediaQuery, useTheme } from '@mui/material';

import PeopleIcon from '@mui/icons-material/People';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HandymanIcon from '@mui/icons-material/Handyman';

import { useGetAccesUsersRegisterQuery } from '../../Redux/API/AccessExecutionAPI';
import ListItems from './Settings/ListItems';
import MySnackbar from './UI/MySnackbar';
import AddOrganizationEntryButton from './Settings/UI/AddOrganizationEntryButton';

function AccessMailSettings() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: OrganizationsData, isLoading } = useGetAccesUsersRegisterQuery();
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role='tabpanel' hidden={value !== index} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Chip
              label={mobileView ? 'Настройки допусков' : 'Настройки модуля оформления писем на допуск'}
              sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
            />
            <Stack direction='row' alignItems='center' justifyContent='end' mb='8px'>
              <AddOrganizationEntryButton value={value} Organizations={OrganizationsData?.Organization_name} />
              <Button variant='contained' size='small' sx={{ fontSize: '9px', marginLeft: '5px' }} onClick={() => {}}>
                Лог
              </Button>
            </Stack>
          </Stack>

          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', height: '80vh', maxHeight: '80vh', padding: '10px' }}>
              <Divider />
              <Tabs value={value} onChange={handleChange} sx={{ minHeight: '40px', height: '40px' }}>
                <Tab icon={<PeopleIcon />} label='Сотрудники организаций' iconPosition='start' sx={{ fontSize: '10px', minHeight: '30px' }} />
                <Tab icon={<DirectionsCarIcon />} label='Автомобили организаций' iconPosition='start' sx={{ fontSize: '10px', minHeight: '30px' }} />
                <Tab icon={<HandymanIcon />} label='Приборы и инструмент' iconPosition='start' sx={{ fontSize: '10px', minHeight: '30px' }} />
              </Tabs>
              <Divider />
              {isLoading && <LinearProgress sx={{ marginTop: '5px' }} />}
              <TabPanel value={value} index={0}>
                <ListItems OrganizationsData={OrganizationsData} table={'users'} setSnackBarMessage={setSnackBarMessage} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ListItems OrganizationsData={OrganizationsData} table={'vehicles'} setSnackBarMessage={setSnackBarMessage} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ListItems OrganizationsData={OrganizationsData} table={'tools'} setSnackBarMessage={setSnackBarMessage} />
              </TabPanel>
            </Box>
          </Paper>
        </Box>
      </Box>

      <MySnackbar message={snackBarMessage} setMessage={setSnackBarMessage} />
    </>
  );
}

export default AccessMailSettings;
