import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../Redux/Slices/AuthSlice';

import { IconButton, Avatar, Popover, Box, Button, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';

function UserCard() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Auth);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} edge='end' color='inherit' aria-label='avatar' onClick={handleClick}>
        <Avatar sx={{ width: 25, height: 25, color: blue[700], bgcolor: 'white' }} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            width: '300px',
            height: '300px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ width: 100, height: 100, marginBottom: '10px' }} />
          <Typography variant='subtitle1'>{user.full_name}</Typography>
          <Typography variant='subtitle2'>{user.position}</Typography>
          <Typography variant='subtitle2'>{user.filial}</Typography>
          <Button
            variant='contained'
            onClick={() => {
              dispatch(logout());
            }}
            sx={{ marginTop: '30px' }}
          >
            Выход
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default UserCard;
