import { createSlice, current } from '@reduxjs/toolkit';

const currentDate = () => {
  let d = new Date();
  let date = [d.getFullYear(), ('0' + (d.getMonth() + 1)).slice(-2), ('0' + d.getDate()).slice(-2)].join('-');
  return date;
};

const initialState = {
 
};

const PositionsExecutionSlice = createSlice({
  name: 'PositionsExecutionState',
  initialState,

  reducers: {
    setParsedXLSxRows: (state, action) => {
      state.parsedXLSxRows = action.payload;
    },

  },
});

export const { setParsedXLSxRows} = PositionsExecutionSlice.actions;

export default PositionsExecutionSlice.reducer;
