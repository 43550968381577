import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  checkedArr: [],
  globalCheck: false,
  movePositionTo: '',
  useGFKCode: '',
  rowSelectionModel: [],
  selectedRows: [],
};

const MoveExecutionSlice = createSlice({
  name: 'MoveExecutionState',
  initialState,

  reducers: {
    setMoveCheckbox: (state, action) => {
      if (!action.payload.checked) {
        for (let i = 0; i < state.checkedArr.length; i++) {
          if (state.checkedArr[i].ID === action.payload.res.ID) state.checkedArr.splice(i, 1);
        }
      } else {
        // если в массиве отмеченных строк нет записей или отмечаются строки с совпадающим ЕРП и ГФК, тогда заполняем массив отмеченных строк

        if (!state.checkedArr[0] || (state.checkedArr[0].GFK === action.payload.res.GFK && state.checkedArr[0].ERP === action.payload.res.ERP))
          state.checkedArr.push(action.payload.res);
      }
    },
    resetCheckedArr: (state) => {
      state.checkedArr = [];
    },
    toggleCheckGlobal: (state, action) => {
      state.checkedArr = [];

      state.globalCheck ? (state.checkedArr = []) : action.payload.map((elem) => state.checkedArr.push({ ...elem, checked: state.globalCheck ? false : true }));

      state.globalCheck = !state.globalCheck;
    },

    setMovePositionTo: (state, action) => {
      state.movePositionTo = action.payload;
    },
    setMoveUseGFKCode: (state, action) => {
      state.useGFKCode = action.payload;
    },
    moveBtnHandler: (state, action) => {
      console.log({
        'ЕРП откуда': state.checkedArr[0].ERP,
        'ГФК откуда': state.checkedArr[0].GFK,
        'Адрес откуда': state.checkedArr[0].Address,
        'ЕРП куда': state.movePositionTo.position_ERP,
        'ГФК куда': state.useGFKCode,
        'Адрес куда': state.movePositionTo.position_addr,
        Активы: current(state.checkedArr),
      });
    },

    setRowSelectionModel: (state, action) => {
      state.rowSelectionModel = action.payload
    },

    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload
    },

    rowSelectionModelChangeHandler: (state, action) => {
      //вычисляем новый отмеченный ID
      let newId = action.payload.ids.filter((id) => !state.rowSelectionModel.includes(id))[0];
      // проверяем если массив отмеченных строк пустой или новых добавляемых ID нет (сняли ранее отмеченную галочку) то заполняем первую строчку массива
      if (state.selectedRows.length === 0 || newId === undefined) {
        state.selectedRows = action.payload.ids.map((id) => action.payload.MoveRegisterData.find((row) => row.id === id));
        state.rowSelectionModel = action.payload.ids;
      } else {
        //все последующие строки проверяем на соотверствие ЕРП. Если ЕРП исходной позиции и ЕРП позиции куда перемещаем совпадают - заполняем массивы, если нет - ничего не делаем.
        let ERP_From = state.selectedRows[0].ERP_From;
        let ERP_to = state.selectedRows[0].ERP_To;
        let new_ERP_From = action.payload.MoveRegisterData.find((row) => row.id === newId).ERP_From;
        let new_ERP_To = action.payload.MoveRegisterData.find((row) => row.id === newId).ERP_To;
        if (ERP_From === new_ERP_From && ERP_to === new_ERP_To) {
          state.selectedRows = action.payload.ids.map((id) => action.payload.MoveRegisterData.find((row) => row.id === id));
          state.rowSelectionModel = action.payload.ids;
        }
      }
    },
  },
});

export const { resetCheckedArr, toggleCheckGlobal, setMoveCheckbox, setMovePositionTo, setMoveUseGFKCode, moveBtnHandler, setRowSelectionModel,setSelectedRows, rowSelectionModelChangeHandler } =
  MoveExecutionSlice.actions;

export default MoveExecutionSlice.reducer;
