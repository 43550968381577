import { beeHandBookAPI } from './beeHandBookAPI';

async function downloadFile(response, filename) {
  let hiddenElement = document.createElement('a');
  let url = window.URL || window.webkitURL;
  let file = url.createObjectURL(await response.blob());
  hiddenElement.href = file;
  hiddenElement.target = '_blank';
  hiddenElement.download = filename;
  hiddenElement.click();
}

export const ZIPExecutionAPI = beeHandBookAPI.enhanceEndpoints({ addTagTypes: ['ZIPRegister', 'Positions', 'Logs'] }).injectEndpoints({
  endpoints: (builder) => ({
    ZIPSearch: builder.query({
      query: (SearchString) => `ZIPSearch_ms?SearchString=${SearchString}`,
      providesTags: ['ZIPRegister'],
    }),

    ZIPMountPositionSearch: builder.query({
      query: (positionDesc) => `PositionSearch_ms?SearchString=${positionDesc}`,
      providesTags: ['Positions'],
    }),

    SaveMountedZIP: builder.mutation({
      query: ({ user, MountedZipArr }) => ({
        url: `saveMountedZIP_ms`,
        method: 'POST',
        body: { user, MountedZipArr },
      }),
      invalidatesTags: ['ZIPRegister', 'Logs'],
    }),

    GetUsedZIPRegister: builder.query({
      query: () => `GetUsedZIPRegister_ms`,
      providesTags: ['ZIPRegister'],
    }),

    ZIPRegisterUserAction: builder.mutation({
      query: ({ action, selectedRows, user }) => ({
        url: `ZIPRegisterUserAction_ms`,
        method: 'POST',
        body: { action, selectedRows, user },
        responseHandler: async (response) => {
          switch (action) {
            case 'download_act': {
              downloadFile(response, 'AM.zip');
              return 'Ready';
            }
            case 'download_register': {
              downloadFile(response, 'Реестр использованных ЗИП.xlsx');
              return 'Ready';
            }
            default: {
              return JSON.parse(await response.text());
            }
          }
        },
      }),
      invalidatesTags: ['ZIPRegister', 'Logs'],
    }),
  }),
});

export const { useZIPSearchQuery, useZIPMountPositionSearchQuery, useSaveMountedZIPMutation, useGetUsedZIPRegisterQuery, useZIPRegisterUserActionMutation } = ZIPExecutionAPI;
