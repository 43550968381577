import React, { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';

function MySnackbar({ message, setMessage }) {
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [messageForShow, setMessageForShow] = useState('');

  function snackbarHandleClose() {
    setSnackBarOpen(false);
    setTimeout(() => {
      setMessage('');
      // setMessageForShow('');
    }, 300);
  }

  useEffect(() => {
    if (message !== '') {
      if (message[0] === '!') {
        setSeverity('error');
        setMessageForShow(message.slice(1));
      } else {
        setSeverity('success');
        setMessageForShow(message);
      }
      setSnackBarOpen(true);
    } else {
      snackbarHandleClose();
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <Snackbar open={snackBarOpen} autoHideDuration={2000} onClose={snackbarHandleClose}>
      <Alert variant='filled' elevation={6} onClose={snackbarHandleClose} severity={severity} sx={{ width: '100%' }}>
        {messageForShow}
      </Alert>
    </Snackbar>
  );
}

export default MySnackbar;
