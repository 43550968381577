import React, { useState } from 'react';
import { Typography, Box, Paper, LinearProgress, Stack, Button, ButtonGroup, Menu, MenuItem, Switch, Chip, Tooltip, useMediaQuery, useTheme } from '@mui/material';

import { useGetUsedZIPRegisterQuery, useZIPRegisterUserActionMutation } from '../../../Redux/API/ZIPExecutionAPI';

import { DataGrid, gridClasses, GridToolbar, ruRU } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import AlertDialog from '../../Access/UI/AlertDialog';
import MySnackbar from '../../Access/UI/MySnackbar';
import AwaitingAlert from '../../UI/AwaitingAlert';

function UsedZIPRegisterNew() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useSelector((state) => state.Auth);
  const { data: UsedZIPRegisterData, isLoading: isUsedZIPRegisterLoading } = useGetUsedZIPRegisterQuery({}, { refetchOnMountOrArgChange: true });

  const [ZIPRegisterUserAction] = useZIPRegisterUserActionMutation();

  const [stateSelectMenuAnchorEl, setStateSelectMenuAnchorEl] = useState(null);
  const stateSelectMenuOpen = Boolean(stateSelectMenuAnchorEl);
  const handleStateMenuButton = (event) => {
    setStateSelectMenuAnchorEl(event.currentTarget);
  };
  const handleStateMenuClose = () => {
    setStateSelectMenuAnchorEl(null);
  };

  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [wrap, setWrap] = useState(false);
  const [awaitingAlertOpen, setAwaitingAlertOpen] = useState(false);
  const [awaitingAlertText, setAwaitingAlertText] = useState('');

  const columns = [
    { field: 'filial', headerName: 'Филиал', width: 100 },
    { field: 'mount_date', headerName: 'Дата монтажа', width: 100 },
    { field: 'mounted_NFS', headerName: 'Код НФС', width: 100 },
    { field: 'mounted_description', headerName: 'Описание', width: 400 },
    { field: 'mounted_Qty', headerName: 'Кол-во', width: 100 },
    { field: 'mounted_SN', headerName: 'Серийный номер/партия', width: 150 },
    { field: 'TT', headerName: 'TT', width: 100 },
    { field: 'position_ERP', headerName: 'Код ЕРП', width: 100 },
    { field: 'position_GFK', headerName: 'Код ГФК', width: 120 },
    {
      field: 'position_num',
      headerName: 'Позиция монтажа',
      width: 250,
      renderCell: (params) => params.row.position_num + ' ' + params.row.position_name,
    },
    { field: 'project', headerName: 'Проект', width: 100 },
    { field: 'invoice', headerName: 'Инвойс', width: 170 },
    {
      field: 'author',
      headerName: 'Ответственный',
      width: 170,
      renderCell: (params) => (
        <Tooltip title={`${params.row.author.split(',')[1]}`}>
          <span>{params.row.author.split(',')[0]}</span>
        </Tooltip>
      ),
    },
  ];

  function actionHandler(res) {
    handleAwaitingAlertClose();
    setRowSelectionModel([]);
    setSelectedRows([]);
    if (res !== '' && res !== 'Ready') {
      setSnackBarMessage(res.res);
    }
  }

  function wrapSwitchHandler(value) {
    value.target.checked ? setWrap(true) : setWrap(false);
  }

  function handleAwaitingAlertClose() {
    setAwaitingAlertOpen(false);
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Chip label={mobileView ? 'Реестр исп. ЗИП' : 'Реестр использованных ЗИП'} sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} />
            <Stack direction='row' alignItems='center' justifyContent='end' mb='8px'>
              <Typography variant='caption'> {mobileView ? 'Перенос строк' : 'Включить перенос строк в таблице'}</Typography>
              <Switch size='small' checked={wrap} onChange={wrapSwitchHandler} />
              <Button variant='contained' size='small' sx={{ fontSize: '9px', marginLeft: '5px' }} onClick={() => {}}>
                Лог
              </Button>
            </Stack>
          </Stack>

          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', minHeight: '80vh', padding: '10px' }}>
              {isUsedZIPRegisterLoading ? (
                <LinearProgress />
              ) : UsedZIPRegisterData?.res?.length === 0 ? (
                <Box align='center'>Нет записей в реестре использованных ЗИП</Box>
              ) : (
                <>
                  <DataGrid
                    disableColumnMenu
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    checkboxSelection
                    rowHeight={27}
                    getRowHeight={() => (wrap ? 'auto' : null)}
                    getEstimatedRowHeight={() => (wrap ? 100 : null)}
                    columnHeaderHeight={30}
                    rows={UsedZIPRegisterData.res}
                    columns={columns}
                    sx={{
                      height: '75vh',
                      fontSize: '10px',
                      '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                      },
                      '& .MuiDataGrid-columnHeader': {
                        // Forced to use important since overriding inline styles
                        height: 'unset !important',
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        // Forced to use important since overriding inline styles
                        maxHeight: '150 !important',
                      },
                      [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                        outline: 'none',
                      },
                      [`& .${gridClasses.cell}`]: wrap && {
                        py: 1,
                      },
                      '.isActedTrue': {
                        fontWeight: 100,
                        color: 'lightgrey',
                      },
                      '.isActedFalse': {
                        fontWeight: 700,
                      },
                    }}
                    onRowSelectionModelChange={(ids) => {
                      setSelectedRows(ids.map((id) => UsedZIPRegisterData.res.find((row) => row.id === id)));
                      setRowSelectionModel(ids);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    getRowClassName={(params) => {
                      switch (params.row.isActed) {
                        case 'true': {
                          return 'isActedTrue';
                        }
                        case 'false': {
                          return 'isActedFalse';
                        }
                        default: {
                          break;
                        }
                      }
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                      },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                  />
                </>
              )}
              {UsedZIPRegisterData?.res?.length !== 0 && (
                <Box align='right' mt='10px'>
                  <ButtonGroup size='small' disabled={selectedRows.length === 0}>
                    {user.role === 'Admin' && (
                      <AlertDialog
                        title='Вы уверены ?'
                        response={async () => {
                          const res = await ZIPRegisterUserAction({ action: 'delete', user, selectedRows }).unwrap();
                          actionHandler(res);
                        }}
                      >
                        {(showAlertDialog) => (
                          <Button sx={{ fontSize: mobileView ? '7px' : '9px' }} color='error' onClick={showAlertDialog}>
                            {mobileView ? 'Удалить' : 'Удалить выбранные записи'}
                          </Button>
                        )}
                      </AlertDialog>
                    )}
                    <Button
                      sx={{ fontSize: mobileView ? '7px' : '9px' }}
                      onClick={async () => {
                        setAwaitingAlertText('Подготовка актов монтажа');
                        setAwaitingAlertOpen(true);
                        const res = await ZIPRegisterUserAction({ action: 'download_act', user, selectedRows }).unwrap();
                        actionHandler(res);
                      }}
                    >
                      Выгрузить акт монтажа
                    </Button>
                    <Button
                      sx={{ fontSize: mobileView ? '7px' : '9px' }}
                      onClick={async () => {
                        setAwaitingAlertText('Подготовка реестра использованных ЗИП');
                        setAwaitingAlertOpen(true);
                        const res = await ZIPRegisterUserAction({ action: 'download_register', user, selectedRows }).unwrap();
                        actionHandler(res);
                      }}
                    >
                      Выгрузить реестр
                    </Button>

                    <Button sx={{ fontSize: mobileView ? '7px' : '9px' }} onClick={handleStateMenuButton}>
                      {mobileView ? 'Изменить состояние' : 'Изменить состояние выбранных записей'}
                    </Button>
                  </ButtonGroup>
                  <Menu anchorEl={stateSelectMenuAnchorEl} open={stateSelectMenuOpen} onClose={handleStateMenuClose}>
                    <MenuItem
                      onClick={async () => {
                        const res = await ZIPRegisterUserAction({ action: 'switch_to_acted', user, selectedRows }).unwrap();
                        handleStateMenuClose();
                        actionHandler(res);
                      }}
                    >
                      Отметить как отработанный
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        const res = await ZIPRegisterUserAction({ action: 'switch_to_no_acted', user, selectedRows }).unwrap();
                        handleStateMenuClose();
                        actionHandler(res);
                      }}
                    >
                      Отметить как не отработанный
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>

      <MySnackbar message={snackBarMessage} setMessage={setSnackBarMessage} />
      <AwaitingAlert awaitingAlertOpen={awaitingAlertOpen} handleAwaitingAlertClose={handleAwaitingAlertClose} title={awaitingAlertText} />
    </>
  );
}

export default UsedZIPRegisterNew;
