import React from 'react';
import { Checkbox } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setUnmountCheckbox } from '../../../../Redux/Slices/UnmountExecutionSlice';

function MyCheckbox({ res }) {
  //const [checkedArr, setCheckedArr] = useState(arr);
  const dispatch = useDispatch();
  const checkedArr = useSelector((state) => state.UnmountExecution.checkedArr);

  return (
    <Checkbox
      checked={checkedArr.includes(checkedArr.find((elem) => elem.ID === res.ID))}
      onChange={(e) => {
        dispatch(setUnmountCheckbox({ res, checked: e.target.checked }));
        // let arr = checkedArr;
        // if (!e.target.checked) {
        //   for (let i = 0; i < arr.length; i++) {
        //     if (arr[i] === res.ID) arr.splice(i, 1);
        //   }
        // } else {
        //   arr.push(res.ID);
        // }
        // setCheckedArr([...arr]);
        // let arr = checkedArr;
        // if (!e.target.checked) {
        //   for (let i = 0; i < arr.length; i++) {
        //     if (arr[i].ID === res.ID) arr.splice(i, 1);
        //   }
        // } else {
        //   arr.push(res);
        // }
        // setCheckedArr([...arr]);
      }}
      size='small'
    />
  );
}

export default MyCheckbox;
