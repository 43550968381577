import React from 'react';
import { Dialog, DialogTitle, DialogContent, LinearProgress } from '@mui/material';

function MoveAwaitingAlert({ awaitingAlertOpen, handleAwaitingAlertClose, title }) {
  return (
    <Dialog open={awaitingAlertOpen} onClose={handleAwaitingAlertClose} maxWidth='lg'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <LinearProgress />
      </DialogContent>
    </Dialog>
  );
}

export default MoveAwaitingAlert;
