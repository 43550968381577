import { beeHandBookAPI } from './beeHandBookAPI';

async function downloadFile(response, filename) {
  let hiddenElement = document.createElement('a');
  let url = window.URL || window.webkitURL;
  let file = url.createObjectURL(await response.blob());
  hiddenElement.href = file;
  hiddenElement.target = '_blank';
  hiddenElement.download = filename;
  hiddenElement.click();
}

export const UnmountExecutionAPI = beeHandBookAPI.enhanceEndpoints({ addTagTypes: ['UnmountRegister'] }).injectEndpoints({
  endpoints: (builder) => ({
    getUnmountRegister: builder.query({
      query: ({ page, limit }) => `GetUnmountRegister?page=${page}&limit=${limit}`,
      providesTags: ['UnmountRegister'],
    }),

    GetUnmountRegister_ms: builder.query({
      query: () => `GetUnmountRegister_ms`,
      providesTags: ['UnmountRegister'],
    }),

    SaveUnmountRegisterEntry: builder.mutation({
      query: (checkedArr) => ({
        url: `SaveUnmountRegisterEntry`,
        method: 'POST',
        body: checkedArr,
      }),
      invalidatesTags: ['UnmountRegister'],
    }),

    UnmountRegisterUserAction: builder.mutation({
      query: ({ action, user, checkedArr }) => ({
        url: `UnmountRegisterUserAction`,
        method: 'POST',
        body: { action, user, checkedArr },
      }),

      invalidatesTags: ['UnmountRegister'],
    }),

    UnmountRegisterUserAction_ms: builder.mutation({
      query: ({ action, user, checkedArr }) => ({
        url: `UnmountRegisterUserAction_ms`,
        method: 'POST',
        body: { action, user, checkedArr },
      }),

      invalidatesTags: ['UnmountRegister'],
    }),

    UnmountRegisterUserActionStatus: builder.query({
      query: (unmountRegisterUserActionStatusProcessId) => `UnmountRegisterUserActionStatus?processID=${unmountRegisterUserActionStatusProcessId}`,
      providesTags: ['Status'],
    }),

    UnmountActDownload: builder.mutation({
      query: ({ processID, fileName }) => ({
        url: `UnmountActDownload`,
        method: 'POST',
        body: { processID },
        responseHandler: async (response) => {
          downloadFile(response, fileName);
          return 'Ready';
        },
        cache: 'no-cache',
      }),
      invalidatesTags: ['UnmountRegister', 'Logs'],
    }),
  }),
});

export const {
  useGetUnmountRegisterQuery,
  useGetUnmountRegister_msQuery,
  useSaveUnmountRegisterEntryMutation,
  useUnmountRegisterUserActionMutation,
  useUnmountRegisterUserAction_msMutation,
  useUnmountRegisterUserActionStatusQuery,
  useUnmountActDownloadMutation,
} = UnmountExecutionAPI;
