import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, LinearProgress, Stack, Button, Popover, ButtonGroup, Menu, MenuItem, Switch, Chip, Tooltip, useMediaQuery, useTheme } from '@mui/material';

import {
  useGetUnmountRegister_msQuery,
  useUnmountRegisterUserAction_msMutation,
  useUnmountRegisterUserActionStatusQuery,
  useUnmountActDownloadMutation,
} from '../../Redux/API/UnmountExecutionAPI';

import { DataGrid, gridClasses, GridToolbar, hoveredRow, ruRU } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useSelector } from 'react-redux';

import AlertDialog from '../Access/UI/AlertDialog';
import MySnackbar from '../Access/UI/MySnackbar';
import AccessMailsAwaitingAlert from '../Access/UI/AccessMailsAwaitingAlert';
import MySelect from './UnmountExecution/UI/MySelect';
import UnmountStateSelector from './UnmountExecution/UI/UnmountStateSelector';
import StateDescriptionEditPopover from './UnmountExecution/UI/StateDescriptionEditPopover';
import StateEditPopover from './UnmountExecution/UI/StateEditPopover';

function UnmountRegisterNew() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useSelector((state) => state.Auth);

  const { data: UnmountRegisterData, isLoading: isUnmountRegisterDataLoading } = useGetUnmountRegister_msQuery({}, { refetchOnMountOrArgChange: true });

  const [skipPoll, setSkipPoll] = useState(true);
  const [UnmountRegisterUserAction] = useUnmountRegisterUserAction_msMutation();
  const [UnmountActDownload] = useUnmountActDownloadMutation();
  const [unmountRegisterUserActionStatusProcessId, setUnmountRegisterUserActionStatusProcessId] = useState();
  const [unmountRegisterUserActionStatusDataState, setUnmountRegisterUserActionStatusDataState] = useState({ progress: 0, status: '' });

  const { data: unmountRegisterUserActionStatusData } = useUnmountRegisterUserActionStatusQuery(unmountRegisterUserActionStatusProcessId, {
    pollingInterval: 500,
    skip: skipPoll,
    refetchOnMountOrArgChange: true,
  });

  const [stateSelectMenuAnchorEl, setStateSelectMenuAnchorEl] = useState(null);
  const stateSelectMenuOpen = Boolean(stateSelectMenuAnchorEl);
  const handleStateMenuButton = (event) => {
    setStateSelectMenuAnchorEl(event.currentTarget);
  };
  const handleStateMenuClose = () => {
    setStateSelectMenuAnchorEl(null);
  };

  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [wrap, setWrap] = useState(false);
  const [awaitingAlertOpen, setAwaitingAlertOpen] = useState(false);
  const [awaitingAlertText, setAwaitingAlertText] = useState('');

  //блок состояний для всплывающего окна редактирования состояния оборудования
  const [stateRowValue, setStateRowValue] = useState([]);
  const [stateEditAnchorEl, setStateEditAnchorEl] = useState(null);
  const stateEditPopoverOpen = Boolean(stateEditAnchorEl);

  //блок состояний для всплывающего окна редактирования описания состояния оборудования
  const [stateDescriptionRowValue, setStateDescriptionRowValue] = useState({});
  const [stateEditDescriptionAnchorEl, setStateEditDescriptionAnchorEl] = useState(null);
  const stateEditDescriptionPopoverOpen = Boolean(stateEditDescriptionAnchorEl);

  const columns = [
    { field: 'filial', headerName: 'Филиал', width: 100 },
    { field: 'unmount_date', headerName: 'Дата демонтажа', width: 100 },
    { field: 'unmounted_description', headerName: 'Описание', width: 400 },
    { field: 'unmounted_Qty', headerName: 'Кол-во', width: 80 },
    { field: 'unmounted_Qty_type', headerName: 'ед. изм.', width: 80 },
    { field: 'unmounted_SN', headerName: 'Серийный номер', width: 200 },
    { field: 'unmounted_part', headerName: 'партия', width: 100 },
    { field: 'unmounted_NFS', headerName: 'Код НФС', width: 120 },
    { field: 'unmounted_New_NFS', headerName: 'Новый код НФС', width: 120 },
    { field: 'unmounted_Inventory_num', headerName: 'Инвентарный номер', width: 120 },
    { field: 'unmounted_active', headerName: 'Актив', width: 120 },
    { field: 'unmounted_ERP', headerName: 'ЕРП поз. демонтажа', width: 120 },
    { field: 'unmounted_GFK', headerName: 'ГФК поз. демонтажа', width: 150 },
    {
      field: 'unmounted_state',
      type: 'singleSelect',
      headerName: 'Состояние',
      width: 180,
    },

    {
      field: 'unmounted_state_description',
      headerName: 'Описание неисправности',
      width: 200,
      valueGetter: (params) => (params.row.unmounted_state === 'Исправно' ? '' : params.value),
    },

    {
      field: 'author',
      headerName: 'Ответственный',
      width: 170,
      renderCell: (params) => (
        <Tooltip title={`${params.row.author.split(',')[1]}`}>
          <span>{params.row.author.split(',')[0]}</span>
        </Tooltip>
      ),
    },
  ];

  function actionHandler(res) {
    setRowSelectionModel([]);
    setSelectedRows([]);
    if (res !== '' && res !== 'Ready') {
      setSnackBarMessage(res.res);
    }
  }

  //обработчик двойного клика на полях статуса (редактирование описания неисправности)
  function customEditCell(params, event) {
    if (params.row.unmounted_state !== 'Исправно' && (params.field === 'unmounted_state_description' || params.field === 'unmounted_state')) {
      setStateEditDescriptionAnchorEl(event.currentTarget);
      setStateDescriptionRowValue(params.row);
    }
  }

  //обработчик клика правой клавишей мышки на полях статуса (смена статуса)
  function handleStateCellContextMenu(event) {
    event.preventDefault();
    let id = Number(event.currentTarget.parentElement.dataset.id);
    let cellName = event.currentTarget.dataset.field;

    if (cellName === 'unmounted_state' || cellName === 'unmounted_state_description') {
      if (selectedRows.some((elem) => elem.id === id)) {
        setStateRowValue([...selectedRows]);
      } else {
        setStateRowValue([UnmountRegisterData.find((row) => row.id === id)]);
      }

      setStateEditAnchorEl(event.currentTarget);
    }
  }

  function wrapSwitchHandler(value) {
    value.target.checked ? setWrap(true) : setWrap(false);
  }

  function handleAwaitingAlertClose() {
    setAwaitingAlertOpen(false);
  }

  useEffect(() => {
    async function UnmountRegisterUserActionStatusCheck() {
      setUnmountRegisterUserActionStatusDataState({ progress: unmountRegisterUserActionStatusData?.res.progress, status: unmountRegisterUserActionStatusData?.res.status });
      if (unmountRegisterUserActionStatusData?.res.progress === 100) {
        let fileName = unmountRegisterUserActionStatusData.res.filePath.split('/')[2];
        setSkipPoll(true);
        const res = await UnmountActDownload({ processID: unmountRegisterUserActionStatusProcessId, fileName }).unwrap();
        if (res === 'Ready') handleAwaitingAlertClose();
      }
    }
    UnmountRegisterUserActionStatusCheck();
  }, [unmountRegisterUserActionStatusData]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Chip label={mobileView ? 'Реестр демонтажа' : 'Реестр демонтированного оборудования'} sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} />
            <Stack direction='row' alignItems='center' justifyContent='end' mb='8px'>
              <Typography variant='caption'> {mobileView ? 'Перенос строк' : 'Включить перенос строк в таблице'}</Typography>
              <Switch size='small' checked={wrap} onChange={wrapSwitchHandler} />
              <Button variant='contained' size='small' sx={{ fontSize: '9px', marginLeft: '5px' }} onClick={() => {}}>
                Лог
              </Button>
            </Stack>
          </Stack>

          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', minHeight: '80vh', padding: '10px' }}>
              {isUnmountRegisterDataLoading ? (
                <LinearProgress />
              ) : UnmountRegisterData?.length === 0 ? (
                <Box align='center'>Нет записей в реестре демонтированного оборудования</Box>
              ) : (
                <>
                  <DataGrid
                    disableColumnMenu
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    checkboxSelection
                    rowHeight={27}
                    getRowHeight={() => (wrap ? 'auto' : null)}
                    getEstimatedRowHeight={() => (wrap ? 100 : null)}
                    columnHeaderHeight={30}
                    rows={UnmountRegisterData}
                    columns={columns}
                    sx={{
                      height: '75vh',
                      fontSize: '10px',
                      '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                      },
                      '& .MuiDataGrid-columnHeader': {
                        // Forced to use important since overriding inline styles
                        height: 'unset !important',
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        // Forced to use important since overriding inline styles
                        maxHeight: '150 !important',
                      },
                      [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                        outline: 'none',
                      },
                      [`& .${gridClasses.cell}`]: wrap && {
                        py: 1,
                      },
                      '.isActedTrue': {
                        fontWeight: 100,
                        color: 'lightgrey',
                      },
                      '.isActedFalse': {
                        fontWeight: 700,
                      },
                      [`.${gridClasses.cell}.toUtilization`]: {
                        backgroundColor: '#ffcdd2',
                      },
                      [`.${gridClasses.cell}.toReparing`]: {
                        backgroundColor: '#fff9c4',
                      },
                      [`.${gridClasses.cell}.Normal`]: {
                        backgroundColor: '#c8e6c9',
                      },
                    }}
                    onRowSelectionModelChange={(ids) => {
                      setSelectedRows(ids.map((id) => UnmountRegisterData.find((row) => row.id === id)));
                      setRowSelectionModel(ids);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    getRowClassName={(params) => {
                      switch (params.row.isActed) {
                        case 'true': {
                          return 'isActedTrue';
                        }
                        case 'false': {
                          return 'isActedFalse';
                        }
                        default: {
                          break;
                        }
                      }
                    }}
                    getCellClassName={(params) => {
                      if (params.field !== 'unmounted_state') return '';
                      switch (params.row.unmounted_state) {
                        case 'Неисправно, на утилизацию': {
                          return 'toUtilization';
                        }
                        case 'Неисправно, в ремонт': {
                          return 'toReparing';
                        }
                        case 'Исправно': {
                          return 'Normal';
                        }
                        default: {
                          break;
                        }
                      }
                    }}
                    onCellDoubleClick={customEditCell}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                      },
                      cell: {
                        onContextMenu: handleStateCellContextMenu,
                        // style: { cursor: 'context-menu' },
                      },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                  />
                </>
              )}
              {UnmountRegisterData?.length !== 0 && (
                <Box align='right' mt='10px'>
                  <ButtonGroup size='small' disabled={selectedRows.length === 0}>
                    {user.role === 'Admin' && (
                      <AlertDialog
                        title='Вы уверены ?'
                        response={async () => {
                          const res = await UnmountRegisterUserAction({ action: 'delete', user, checkedArr: selectedRows }).unwrap();
                          actionHandler(res);
                        }}
                      >
                        {(showAlertDialog) => (
                          <Button sx={{ fontSize: mobileView ? '7px' : '9px' }} color='error' onClick={showAlertDialog}>
                            {mobileView ? 'Удалить' : 'Удалить выбранные записи'}
                          </Button>
                        )}
                      </AlertDialog>
                    )}
                    <Button
                      sx={{ fontSize: mobileView ? '7px' : '9px' }}
                      onClick={async () => {
                        setUnmountRegisterUserActionStatusDataState({ progress: 0, status: '' });
                        setAwaitingAlertText('Подготовка акта возврата');
                        setAwaitingAlertOpen(true);

                        await UnmountRegisterUserAction({ action: 'download_act', user, checkedArr: selectedRows })
                          .unwrap()
                          .then((res) => {
                            setSkipPoll(false);
                            setUnmountRegisterUserActionStatusProcessId(res.processID);
                            actionHandler('');
                          });
                      }}
                    >
                      Выгрузить комплект документов
                    </Button>

                    <Button sx={{ fontSize: mobileView ? '7px' : '9px' }} onClick={handleStateMenuButton}>
                      {mobileView ? 'Изменить статус' : 'Изменить статус выбранных записей'}
                    </Button>

                    {/* <Button
                      sx={{ fontSize: mobileView ? '7px' : '9px' }}
                      onClick={async () => {
                        // const res = await UnmountRegisterUserAction({ action: 'save_state', user, checkedArr: selectedRows }).unwrap();
                        console.log(selectedRows);
                        handleStateMenuClose();
                        //  actionHandler(res);
                      }}
                    >
                      Сохранить состояние
                    </Button> */}
                  </ButtonGroup>
                  <Menu anchorEl={stateSelectMenuAnchorEl} open={stateSelectMenuOpen} onClose={handleStateMenuClose}>
                    <MenuItem
                      onClick={async () => {
                        const res = await UnmountRegisterUserAction({ action: 'switch_to_acted', user, checkedArr: selectedRows }).unwrap();
                        handleStateMenuClose();
                        actionHandler(res);
                      }}
                    >
                      Отметить как отработанный
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        const res = await UnmountRegisterUserAction({ action: 'switch_to_no_acted', user, checkedArr: selectedRows }).unwrap();
                        handleStateMenuClose();
                        actionHandler(res);
                      }}
                    >
                      Отметить как не отработанный
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>

      <MySnackbar message={snackBarMessage} setMessage={setSnackBarMessage} />
      <AccessMailsAwaitingAlert
        accessMailsAwaitingAlertOpen={awaitingAlertOpen}
        handleAccessMailsAwaitingAlertClose={handleAwaitingAlertClose}
        title={awaitingAlertText}
        progress={unmountRegisterUserActionStatusDataState.progress}
        status={unmountRegisterUserActionStatusDataState.status}
      />
      <StateDescriptionEditPopover
        open={stateEditDescriptionPopoverOpen}
        anchorEl={stateEditDescriptionAnchorEl}
        handleClose={() => {
          setStateEditDescriptionAnchorEl(null);
        }}
        stateDescriptionRowValue={stateDescriptionRowValue}
        user={user}
        setSnackBarMessage={setSnackBarMessage}
      />

      <StateEditPopover
        open={stateEditPopoverOpen}
        anchorEl={stateEditAnchorEl}
        handleClose={() => setStateEditAnchorEl(null)}
        stateRowValueArr={stateRowValue}
        user={user}
        callback={actionHandler}
      />
    </>
  );
}

export default UnmountRegisterNew;
