import React from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { setZIPMountposGFKValue } from '../../../Redux/Slices/ZIPExecutionSlice';

function ZIPNewGFKCodeInput() {
  const { ZIPMountPosition, ZIPMountParams } = useSelector((state) => state.ZIPExecution);
  const dispatch = useDispatch();

  return (
    <Autocomplete
      id='GFK'
      filterOptions={(x) => x}
      options={ZIPMountPosition ? [ZIPMountPosition.position_MRB, ZIPMountPosition.position_TNN] : []}
      sx={{ minWidth: '210px', width: '220px' }}
      size='small'
      onChange={(event, newValue) => {
        dispatch(setZIPMountposGFKValue(newValue));
      }}
      value={ZIPMountParams?.position_GFK ?? ''}
      renderInput={(params) => (
        <TextField {...params} label='Код ГФК позиции' inputProps={{ ...params.inputProps, style: { fontSize: '12px' } }} InputLabelProps={{ shrink: true }} />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <span style={{ fontSize: '10px' }}>{option}</span>
          </li>
        );
      }}
      disabled={ZIPMountPosition ? false : true}
    />
  );
}

export default ZIPNewGFKCodeInput;
