import React, { useState, useEffect } from 'react';

import { Box, Paper, LinearProgress, TextField, Chip, useMediaQuery, useTheme } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { usePositionSearchQuery, PositionsExecutionAPI } from '../../Redux/API/PositionsExecutionAPI';

import PositionsSearchResult from './PositionsSearchResult';

function PositionsNew() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useSelector((state) => state.Auth);

  const [positionDesc, setPositionDesc] = useState('');
  const [skip, setSkip] = useState(true);

  const dispatch = useDispatch();

  const { data: PositionData, isFetching: isPositionDataFetching } = usePositionSearchQuery(positionDesc, { skip });

  useEffect(() => {
    let delay;
    setSkip(true);

    if (positionDesc.trim() !== '' && positionDesc.length > 2) {
      delay = setTimeout(() => {
        setSkip(false);
      }, 700);
    } else {
      setSkip(true);
    }
    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [positionDesc]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Chip label='Справочник позиций' sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} />
          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', height: '80vh', padding: '10px' }}>
              <TextField
                fullWidth
                label='Поиск позиции'
                variant='outlined'
                helperText={mobileView ? 'Введите номер позиции, ЕРП, ГФК, адрес, номер МТС' : 'Введите номер позиции, либо другой идентификатор: ЕРП, ГФК, адрес, номер МТС'}
                size='small'
                value={positionDesc}
                onChange={(e) => {
                  dispatch(PositionsExecutionAPI.util.invalidateTags(['Position']));
                  setPositionDesc(e.target.value);
                }}
              />
              {isPositionDataFetching && <LinearProgress />}
              <PositionsSearchResult data={PositionData} />
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default PositionsNew;
