import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function UnmountExecutionBtn({ handler }) {
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Button
        variant='outlined'
        size='small'
        sx={{ fontSize: '9px' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Оформить демонтаж на отмеченных строках
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Оформление демонтажа выделенных строк'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>Вы уверены ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Нет</Button>
          <Button
            onClick={() => {
              handler();
              handleClose();
            }}
            autoFocus
          >
            Да
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UnmountExecutionBtn;
