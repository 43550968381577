import { beeHandBookAPI } from './beeHandBookAPI';

async function downloadFile(response, filename) {
  let hiddenElement = document.createElement('a');
  let url = window.URL || window.webkitURL;
  let file = url.createObjectURL(await response.blob());
  hiddenElement.href = file;
  hiddenElement.target = '_blank';
  hiddenElement.download = filename;
  hiddenElement.click();
}

export const AccessExecutionAPI = beeHandBookAPI
  .enhanceEndpoints({ addTagTypes: ['AccessUsersRegister', 'AccessMailJobsRegister', 'Logs', 'Positions', 'Status', 'AccessInfo'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      GetAccesUsersRegister: builder.query({
        query: () => `GetAccesUsersRegister`,
        providesTags: ['AccessUsersRegister'],
      }),

      GetAccessMailJobsRegister: builder.query({
        query: () => `GetAccessMailJobsRegister`,
        providesTags: ['AccessMailJobsRegister'],
      }),

      GetAccessLogs: builder.query({
        query: () => `GetAccessLogs`,
        providesTags: ['Logs'],
      }),

      GetAccessInfo: builder.query({
        query: () => `GetAccessInfo`,
        providesTags: ['AccessInfo'],
      }),

      AccessPositionSearch: builder.query({
        query: (positionDesc) => `PositionSearch?SearchString=${positionDesc}`,
        providesTags: ['Positions'],
      }),

      AccesSettingsAction: builder.mutation({
        query: ({ action, table, id, data }) => ({
          url: `AccesSettingsAction`,
          method: 'POST',
          body: { action, table, id, data },
        }),
        invalidatesTags: ['AccessUsersRegister', 'Logs'],
      }),

      AccessMailJobsAction: builder.mutation({
        query: ({ selectedOrganizationsList, selectedPositionsList, user, startAccessDate, endAccessDate, options }) => ({
          url: `AccessMailJobsAction`,
          method: 'POST',
          body: { selectedOrganizationsList, selectedPositionsList, user, startAccessDate, endAccessDate, options },
        }),

        invalidatesTags: ['AccessMailJobsRegister', 'Logs'],
      }),

      AccessMailJobsActionStatus: builder.query({
        query: (accessMailJobsPreparationProcessId) => `AccessMailJobsActionStatus?processID=${accessMailJobsPreparationProcessId}`,
        providesTags: ['Status'],
      }),

      AccessMailsDownload: builder.mutation({
        query: ({ processID, fileName }) => ({
          url: `AccessMailsDownload`,
          method: 'POST',
          body: { processID },
          responseHandler: async (response) => {
            downloadFile(response, fileName);
            return 'Ready';
          },
          cache: 'no-cache',
        }),
        invalidatesTags: ['AccessMailJobsRegister', 'Logs'],
      }),

      AccessListAction: builder.mutation({
        query: ({ action, status, user, data }) => ({
          url: `AccessListAction`,
          method: 'POST',
          body: { action, status, user, data },
          responseHandler: async (response) => {
            switch (action) {
              case 'download_mails': {
                downloadFile(response, `AccessMails.zip`);
                return 'Ready';
              }

              default: {
                return JSON.parse(await response.text());
              }
            }
          },
        }),
        invalidatesTags: ['AccessMailJobsRegister', 'Logs'],
      }),

      AccessMailsResponseUpload: builder.mutation({
        query: (data) => ({
          url: `AccessMailsResponseUpload`,
          method: 'POST',
          body: data,
          cache: 'no-cache',
        }),
        invalidatesTags: ['AccessMailJobsRegister', 'Logs'],
      }),

      AccessMailsResponseFileDelete: builder.mutation({
        query: ({ id, fileNameForDelete, user }) => ({
          url: `AccessMailsResponseFileDelete`,
          method: 'POST',
          body: { id, fileNameForDelete, user },
          cache: 'no-cache',
        }),
        invalidatesTags: ['AccessMailJobsRegister', 'Logs'],
      }),

      AccessMailsResponseFileDownload: builder.mutation({
        query: ({ el, id, user }) => ({
          url: `AccessMailsResponseFileDownload`,
          method: 'POST',
          body: { fileNameForDownload: el, id: id, user: user },
          responseHandler: async (response) => {
            downloadFile(response, el);
            return 'Ready';
          },
          cache: 'no-cache',
        }),
        invalidatesTags: ['AccessMailJobsRegister', 'Logs'],
      }),

      AccessInfoAndMemoDialogSave: builder.mutation({
        query: (data) => ({
          url: `AccessInfoAndMemoDialogSave`,
          method: 'POST',
          body: { ...data },
          cache: 'no-cache',
        }),
        invalidatesTags: ['AccessMailJobsRegister', 'Logs'],
      }),

      AccessInfoEntryActions: builder.mutation({
        query: ({ action, originalRowID, updatedRow }) => ({
          url: `AccessInfoEntryActions`,
          method: 'POST',
          body: { action, originalRowID, updatedRow },
          cache: 'no-cache',
        }),
        invalidatesTags: ['AccessInfo'],
      }),
    }),
  });

export const {
  useGetAccesUsersRegisterQuery,
  useGetAccessMailJobsRegisterQuery,
  useGetAccessLogsQuery,
  useGetAccessInfoQuery,
  useAccessPositionSearchQuery,
  useAccesSettingsActionMutation,
  useAccessMailJobsActionMutation,
  useAccessMailJobsActionStatusQuery,
  useAccessMailsDownloadMutation,
  useAccessListActionMutation,
  useAccessMailsResponseUploadMutation,
  useAccessMailsResponseFileDeleteMutation,
  useAccessMailsResponseFileDownloadMutation,
  useAccessInfoAndMemoDialogSaveMutation,
  useAccessInfoEntryActionsMutation,
} = AccessExecutionAPI;
