import { beeHandBookAPI } from './beeHandBookAPI';

export const PositionsExecutionAPI = beeHandBookAPI.enhanceEndpoints({ addTagTypes: ['Position'] }).injectEndpoints({
  endpoints: (builder) => ({
    PositionSearch: builder.query({
      query: (SearchString) => `PositionSearch_ms?SearchString=${SearchString}`,
      providesTags: ['Position'],
    }),
  }),
});

export const { usePositionSearchQuery } = PositionsExecutionAPI;
