import { beeHandBookAPI } from './beeHandBookAPI';

export const VacationPlanningAPI = beeHandBookAPI.enhanceEndpoints({ addTagTypes: ['VacationData'] }).injectEndpoints({
  endpoints: (builder) => ({
    GetVacationData: builder.query({
      query: () => `GetVacationData`,
      providesTags: ['VacationData'],
    }),

    SaveVacationData: builder.mutation({
      query: (vacationEmploeeObj) => ({
        url: `SaveVacationData`,
        method: 'POST',
        body: vacationEmploeeObj,
      }),
      invalidatesTags: ['VacationData'],
    }),
  }),
});

export const { useGetVacationDataQuery, useSaveVacationDataMutation } = VacationPlanningAPI;
