import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addZIPFullnessAddEntryDialogOpen: false
};

const SparePartsFullnessSlice = createSlice({
  name: 'SparePartsFullnessState',
  initialState,

  reducers: {
   setAddZIPFullnessAddEntryDialogOpen: (state, action) => {
      state.addZIPFullnessAddEntryDialogOpen = action.payload;
    },
  },
});


export const { setAddZIPFullnessAddEntryDialogOpen } = SparePartsFullnessSlice.actions;


export default SparePartsFullnessSlice.reducer;
