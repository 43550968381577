import React, { useRef, useState } from 'react';
import axios from 'axios';

import { Box, Button, LinearProgress, Typography, Divider, TextField, Stack, Alert, Snackbar, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

function UploadZIPfile() {
  const [file, setFile] = useState('');
  const [load, setLoad] = useState(false);
  const [loadState, setLoadState] = useState('');
  //const [snackBarOpts, setSnackBarOpts] = useState({ state: false, severity: 'success', message: '' });
  const [ZIPFileColumsSettings, setZIPFileColumsSettings] = useState({
    FilialCol: 4,
    NFSCol: 10,
    DescriptionCol: 11,
    SNCol: 12,
    QtyCol: 13,
    MOLCol: 27,
    InvoiceCol: 39,
    DataRow: 3,
  });
  const inputRef = useRef();

  function handleChange(e) {
    setLoadState('');
    const fileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

    if (e.target.files[0] && fileTypes.indexOf(e.target.files[0].type) === -1) {
      alert('Выбран не верный тип файла!');
      inputRef.current.value = '';
      return;
    } else if (e.target.files[0] === undefined) {
      return;
    } else {
      setFile(e.target.files[0]);
    }
  }

  async function uploadFile() {
    var uploadres = '';
    setLoad(true);
    setLoadState('Файл загружается на сервер...');

    const formData = new FormData();
    formData.append('file', file); // подключаем файл в элемент formData

    // загружаем файл на сервер
    const AccessToken = localStorage.getItem('AccessToken');
    uploadres = await axios.post('https://beehandbook.ru:4001/uploadZIPfile', formData, {
      headers: {
        Authorization: AccessToken,
      },
      withCredentials: true,
    });
    if (uploadres.data === 'Upload complete, processing...') {
      setLoadState('Файл загружен на сервер, идет обработка');
      //если все ок, запускаем процесс заполнения базы данных

      uploadres = await axios.post('https://beehandbook.ru:4001/processZIPfile', ZIPFileColumsSettings, {
        headers: {
          Authorization: AccessToken,
        },
        withCredentials: true,
      });
    } else {
      setLoadState('Ошибка при загрузке файла на сервер. Попробуйте еще раз');
      setLoad(false);
    }

    if (uploadres.data === 'Processing completed!') {
      setLoadState('Обработка завершена успешно');
      setLoad(false);
    } else {
      setLoadState('Ошибка при обработке. Проверьте файл на корректность данных');
      setLoad(false);
    }
  }

  return (
    <>
      <Stack direction='row' spacing='10px' alignItems='center'>
        <Typography variant='subtitle1'>Загрузка файла остатков ЗИП-ЛО</Typography>

        <Button variant='contained' component='label' size='small'>
          Выбрать файл
          <input type='file' ref={inputRef} onChange={handleChange} hidden />
        </Button>

        {file && (
          <Typography variant='body2' sx={{ marginLeft: '10px' }}>
            Выбран файл: {file.name}
          </Typography>
        )}
        <IconButton
          sx={{ display: file ? undefined : 'none' }}
          onClick={() => {
            inputRef.current.value = '';
            setFile('');
            setLoadState('');
          }}
        >
          <ClearIcon sx={{ color: 'red' }} />
        </IconButton>
      </Stack>

      {file && (
        <Box sx={{ marginTop: '30px' }} align='left'>
          <Typography variant='body2'>Укажите номера столбцов с типами данных в файле остатков ЗИП-ЛО:</Typography>
          <Stack sx={{ marginTop: '20px' }} direction='row' spacing='10px'>
            <TextField
              label='Код НФС'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={ZIPFileColumsSettings.NFSCol}
              onChange={(e) => {
                setZIPFileColumsSettings({ ...ZIPFileColumsSettings, NFSCol: e.target.value });
              }}
              size='small'
              sx={{ width: '80px' }}
            />
            <TextField
              label='Описание'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={ZIPFileColumsSettings.DescriptionCol}
              onChange={(e) => {
                setZIPFileColumsSettings({ ...ZIPFileColumsSettings, DescriptionCol: e.target.value });
              }}
              size='small'
              sx={{ width: '80px' }}
            />
            <TextField
              label='Серийный номер'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={ZIPFileColumsSettings.SNCol}
              onChange={(e) => {
                setZIPFileColumsSettings({ ...ZIPFileColumsSettings, SNCol: e.target.value });
              }}
              size='small'
              sx={{ width: '120px' }}
            />
            <TextField
              label='Количество'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={ZIPFileColumsSettings.QtyCol}
              onChange={(e) => {
                setZIPFileColumsSettings({ ...ZIPFileColumsSettings, QtyCol: e.target.value });
              }}
              size='small'
              sx={{ width: '90px' }}
            />
            <TextField
              label='МОЛ'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={ZIPFileColumsSettings.MOLCol}
              onChange={(e) => {
                setZIPFileColumsSettings({ ...ZIPFileColumsSettings, MOLCol: e.target.value });
              }}
              size='small'
              sx={{ width: '70px' }}
            />
            <TextField
              label='Инвойс'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={ZIPFileColumsSettings.InvoiceCol}
              onChange={(e) => {
                setZIPFileColumsSettings({ ...ZIPFileColumsSettings, InvoiceCol: e.target.value });
              }}
              size='small'
              sx={{ width: '70px' }}
            />
          </Stack>

          <Typography variant='body2' sx={{ marginTop: '40px' }}>
            Укажите номер строки с которой начинаются данные (без заголовков) в файле остатков ЗИП-ЛО:
          </Typography>
          <TextField
            label='Первая строка с данными'
            type='number'
            InputLabelProps={{
              shrink: true,
            }}
            value={ZIPFileColumsSettings.DataRow}
            onChange={(e) => {
              setZIPFileColumsSettings({ ...ZIPFileColumsSettings, DataRow: e.target.value });
            }}
            size='small'
            sx={{ width: '180px', marginTop: '10px' }}
          />
        </Box>
      )}
      <Box align='right'>
        <Button variant='contained' size='small' color='error' sx={{ display: file ? undefined : 'none' }} onClick={uploadFile}>
          Загрузить на сервер
          <input type='file' ref={inputRef} onChange={handleChange} hidden />
        </Button>
      </Box>
      {load && <LinearProgress sx={{ marginTop: '10px' }} />}
      <Box align='right' mb='10px' mt='10px'>
        <Typography variant='body2' sx={{ display: file ? undefined : 'none' }}>
          {loadState}
        </Typography>
      </Box>

      <Divider />

      {/* <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackBarOpts.state}
          onClose={() => {
            setSnackBarOpts({ ...snackBarOpts, state: false });
          }}
          autoHideDuration={3000}
        >
          <Alert
            elevation={6}
            variant='filled'
            onClose={() => {
              setSnackBarOpts({ ...snackBarOpts, state: false });
            }}
            severity={snackBarOpts.severity}
            sx={{ width: '100%' }}
          >
            {snackBarOpts.message}
          </Alert>
        </Snackbar> */}
    </>
  );
}

export default UploadZIPfile;
