import React, { useEffect } from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import MainHandbook from './Components/MainHandbook';
import Authorization from './Components/Authorization';
import './Components/CSS/index.css';
import { useSelector, useDispatch } from 'react-redux';
import { loggedInCheck } from './Redux/Slices/AuthSlice';

function App() {
  const { user, wait } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  useEffect(() => {
    async function asyncCall() {
      await dispatch(loggedInCheck());
    }
    asyncCall();
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      {wait ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Routes>
          {user && <Route path='/main/*' element={<MainHandbook />} />}
          {!user && <Route path='/login' element={<Authorization />} />}
          <Route path='*' element={<Navigate to={user ? '/main' : '/login'} />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
