import React from 'react';
import { Dialog, DialogTitle, DialogContent, LinearProgress, Typography } from '@mui/material';

function AccessMailsAwaitingAlert({ progress = 0, status = '', title, accessMailsAwaitingAlertOpen, handleAccessMailsAwaitingAlertClose }) {
  return (
    <Dialog open={accessMailsAwaitingAlertOpen} onClose={handleAccessMailsAwaitingAlertClose} fullWidth maxWidth='xs'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ height: '30px' }}>
        <LinearProgress variant='determinate' value={progress} />
        <Typography variant='caption' display='block' gutterBottom>
          {status}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default AccessMailsAwaitingAlert;
