import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useMovePositionSearchQuery } from '../../../Redux/API/MoveExecutionAPI';
import { setMovePositionTo } from '../../../Redux/Slices/MoveExecutionSlice';
import { setMoveUseGFKCode } from '../../../Redux/Slices/MoveExecutionSlice';

function MovePositionInput() {
  const [positionDesc, setPositionDesc] = useState('');
  const [skip, setSkip] = useState(true);

  const dispatch = useDispatch();
  const { movePositionTo } = useSelector((state) => state.MoveExecution);
  const { data: positionData, isFetching } = useMovePositionSearchQuery(positionDesc, { skip });

  useEffect(() => {
    let delay;
    setSkip(true);

    if (positionDesc.trim() !== '' && positionDesc.length > 2) {
      delay = setTimeout(() => {
        setSkip(false);
      }, 700);
    } else {
      setSkip(true);
    }
    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [positionDesc]);

  return (
    <>
      <Autocomplete
        id='position'
        filterOptions={(x) => x}
        freeSolo
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.position_num + ' ' + option.position_name)}
        options={positionData ? positionData : []}
        sx={{ minWidth: 250 }}
        size='small'
        onChange={(event, newValue) => {
          dispatch(setMovePositionTo(newValue));
          dispatch(setMoveUseGFKCode(newValue ? newValue.position_MRB : ''));
        }}
        value={movePositionTo ? movePositionTo : ''}
        onInputChange={(event, newInputPosValue) => {
          setPositionDesc(newInputPosValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Перемещаем на:'
            inputProps={{ ...params.inputProps, style: { fontSize: '12px' } }}
            InputLabelProps={{ style: { fontSize: '14px' } }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetching ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <span style={{ fontSize: '10px' }}>{option.position_num + ' ' + option.position_name}</span>
            </li>
          );
        }}
      />
    </>
  );
}

export default MovePositionInput;
