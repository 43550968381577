import React, { useState, useEffect } from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material';

import { useSelector } from 'react-redux';

import AddOrganizationEntryDialog from './AddOrganizationEntryDialog';

function AddOrganizationEntryButton({ value, Organizations }) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { itemsValue } = useSelector((state) => state.AccessExecution);
  const [table, setTable] = useState('users');
  const [mainButtonCaption, setMainButtonCaption] = useState(mobileView ? 'Добавить сотрудника' : 'Добавить сотрудника организации');
  const [addOrganizationEntryDialogObject, setAddOrganizationEntryDialogObject] = useState({ open: false, title: '', data: [] });

  useEffect(() => {
    switch (value) {
      case 0: {
        setTable('users');
        setMainButtonCaption(mobileView ? 'Добавить сотрудника' : 'Добавить сотрудника организации');
        break;
      }
      case 1: {
        setTable('vehicles');
        setMainButtonCaption(mobileView ? 'Добавить транспорт' : 'Добавить транспорт организации');
        break;
      }
      case 2: {
        setTable('tools');
        setMainButtonCaption(mobileView ? 'Добавить инструмент' : 'Добавить список инструментов организации');
        break;
      }
      default:
        break;
    }
  }, [value]);

  const addUserDialogData = [
    { name: 'Organization_name', label: 'Наименование организации', defaultValue: Organizations ? Organizations[itemsValue].Organization_name : '' },
    { name: 'name', label: 'Ф.И.О.' },
    { name: 'position', label: 'Должность' },
    { name: 'passport', label: 'Паспортные данные' },
    { name: 'birthDate', label: 'Дата рождения' },
    { name: 'registration', label: 'Прописка' },
    { name: 'electricalSafety', label: 'Группа по эл.безопасности' },
    { name: 'WorkAtHeight', label: 'Группа допуска по высоте' },
  ];

  const addUserVehicleData = [
    { name: 'Organization_name', label: 'Наименование организации', defaultValue: Organizations ? Organizations[itemsValue].Organization_name : '', disabled: true },
    { name: 'Vehicle_brand', label: 'Марка машины' },
    { name: 'Vehicle_registration_number', label: 'Госномер машины' },
  ];

  const addUserToolsData = [
    { name: 'Organization_name', label: 'Наименование организации', defaultValue: Organizations ? Organizations[itemsValue].Organization_name : '', disabled: true },
    { name: 'Tools_Description', label: 'Описание инструмента/прибора' },
    { name: 'Tools_Qty', label: 'Кол-во' },
  ];

  function handleSettingsAddEntryButton() {
    switch (value) {
      case 0: {
        setAddOrganizationEntryDialogObject({ title: 'Добавление сотрудника организации', data: addUserDialogData, open: true });
        break;
      }
      case 1: {
        setAddOrganizationEntryDialogObject({ title: 'Добавление машины организации', data: addUserVehicleData, open: true });
        break;
      }
      case 2: {
        setAddOrganizationEntryDialogObject({ title: 'Добавление инструментов/приборов организации', data: addUserToolsData, open: true });
        break;
      }
      default: {
        break;
      }
    }
  }

  return (
    <>
      <Button variant='contained' size='small' sx={{ fontSize: mobileView ? '7px' : '9px' }} onClick={handleSettingsAddEntryButton}>
        {mainButtonCaption}
      </Button>
      <AddOrganizationEntryDialog
        table={table}
        addOrganizationEntryDialogObject={addOrganizationEntryDialogObject}
        setAddOrganizationEntryDialogObject={setAddOrganizationEntryDialogObject}
      />
    </>
  );
}

export default AddOrganizationEntryButton;
