import React from 'react';
import { Box, Tabs, Tooltip, Tab, useMediaQuery, useTheme } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { useDispatch, useSelector } from 'react-redux';

import { setItemsValue } from '../../../Redux/Slices/AccessExecutionSlice';
import { useAccesSettingsActionMutation } from '../../../Redux/API/AccessExecutionAPI';

import DeleteIcon from '@mui/icons-material/Delete';
import AccessSettingsItem from './Items/AccessSettingsItem';
import AlertDialog from '../UI/AlertDialog';

function ListItems({ table, OrganizationsData, setSnackBarMessage }) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { itemsValue } = useSelector((state) => state.AccessExecution);

  const [AccesSettingsAction] = useAccesSettingsActionMutation();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role='tabpanel' hidden={value !== index} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const itemColumns = () => {
    switch (table) {
      case 'users': {
        return [
          { field: 'Organization_name', headerName: 'Организация', width: 230, editable: true },
          { field: 'name', headerName: 'Ф.И.О.', width: 230, editable: true },
          { field: 'position', headerName: 'Должность', width: 250, editable: true },
          { field: 'passport', headerName: 'Паспортные данные', width: 150, editable: true },
          { field: 'birthDate', headerName: 'Дата рождения', width: 150, editable: true },
          { field: 'registration', headerName: 'Регистрация', width: 150, editable: true },
          { field: 'electricalSafety', headerName: 'Группа по эл. безопасности', width: 100, editable: true },
          { field: 'WorkAtHeight', headerName: 'Группа допуска работ на высоте', width: 100, editable: true },
          {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
              <AlertDialog
                title='Вы уверены ?'
                response={() => {
                  AccesSettingsAction({ action: 'delete', table: 'users', id: params.id });
                }}
              >
                {(showAlertDialog) => (
                  <Tooltip title={`Удалить сотрудника ${params.row.name}`}>
                    <GridActionsCellItem
                      icon={<DeleteIcon sx={{ color: 'red' }} />}
                      label='Удалить'
                      onClick={() => {
                        showAlertDialog();
                      }}
                    />
                  </Tooltip>
                )}
              </AlertDialog>,
            ],
          },
        ];
      }
      case 'vehicles': {
        return [
          { field: 'Organization_name', headerName: 'Организация', width: 230, editable: false },
          { field: 'Vehicle_brand', headerName: 'Марка машины', width: 230, editable: true },
          { field: 'Vehicle_registration_number', headerName: 'Госномер машины', width: 250, editable: true },
          {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
              <AlertDialog
                title='Вы уверены ?'
                response={() => {
                  AccesSettingsAction({ action: 'delete', table: 'vehicles', id: params.id });
                }}
              >
                {(showAlertDialog) => (
                  <Tooltip title={`Удалить машину ${params.row.Vehicle_brand} ${params.row.Vehicle_registration_number}`}>
                    <GridActionsCellItem
                      icon={<DeleteIcon sx={{ color: 'red' }} />}
                      label='Удалить'
                      onClick={() => {
                        showAlertDialog();
                      }}
                    />
                  </Tooltip>
                )}
              </AlertDialog>,
            ],
          },
        ];
      }
      case 'tools': {
        return [
          { field: 'Organization_name', headerName: 'Организация', width: 230, editable: false },
          { field: 'Tools_Description', headerName: 'Описание инструмента/прибора', width: 230, editable: true },
          { field: 'Tools_Qty', headerName: 'Кол-во инструментов/приборов', width: 250, editable: true },
          {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
              <AlertDialog
                title='Вы уверены ?'
                response={() => {
                  AccesSettingsAction({ action: 'delete', table: 'tools', id: params.id });
                }}
              >
                {(showAlertDialog) => (
                  <Tooltip title={`Удалить инструмент/прибор: ${params.row.Tools_Description}`}>
                    <GridActionsCellItem
                      icon={<DeleteIcon sx={{ color: 'red' }} />}
                      label='Удалить'
                      onClick={() => {
                        showAlertDialog();
                      }}
                    />
                  </Tooltip>
                )}
              </AlertDialog>,
            ],
          },
        ];
      }
      default: {
        break;
      }
    }
  };

  const itemData = () => {
    switch (table) {
      case 'users': {
        return OrganizationsData.Organization_members?.filter(
          (organization) => organization.Organization_name === OrganizationsData.Organization_name[itemsValue].Organization_name
        );
      }
      case 'vehicles': {
        return OrganizationsData.Organization_vehicles?.filter(
          (organization) => organization.Organization_name === OrganizationsData.Organization_name[itemsValue].Organization_name
        );
      }
      case 'tools': {
        return OrganizationsData.Organization_tools?.filter((organization) => organization.Organization_name === OrganizationsData.Organization_name[itemsValue].Organization_name);
      }
      default: {
        break;
      }
    }
  };

  return (
    <>
      {OrganizationsData && OrganizationsData !== '' && (
        <>
          <Box sx={{ flexGrow: 1, display: 'flex', height: '73vh', marginTop: '10px' }}>
            <Tabs
              orientation='vertical'
              variant='scrollable'
              value={itemsValue}
              onChange={(e, newValue) => {
                dispatch(setItemsValue(newValue));
              }}
              sx={{ borderRight: 1, borderColor: 'divider', marginRight: '10px', minWidth: mobileView ? '80px' : '200px' }}
            >
              {OrganizationsData.Organization_name.map((res, i) => (
                <Tab
                  label={res.Organization_name}
                  index={res.Organization_name}
                  key={i}
                  sx={{ padding: '0px 10px 0px 0px', fontSize: mobileView ? '8px' : '12px', minHeight: '30px' }}
                />
              ))}
            </Tabs>
            {OrganizationsData.Organization_name.map((res, i) => (
              <TabPanel value={itemsValue} index={i} key={i} style={{ overflow: 'auto', width: '100%' }}>
                <AccessSettingsItem table={table} columns={itemColumns()} data={itemData()} setSnackBarMessage={setSnackBarMessage} />
              </TabPanel>
            ))}
          </Box>
        </>
      )}
    </>
  );
}

export default ListItems;
