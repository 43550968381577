import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar, ruRU } from '@mui/x-data-grid';
import { useGetAccessLogsQuery } from '../../../Redux/API/AccessExecutionAPI';

function AccessLogDialog({ accessLogDialogOpen, setAccessLogDialogOpen }) {
  const { data: AccessLogsData, isLoading: isAccessLogsLoading } = useGetAccessLogsQuery({}, { refetchOnMountOrArgChange: true });

  const columns = [
    { field: 'DateTime', headerName: 'Дата и время события', width: 110 },
    { field: 'User', headerName: 'Пользователь', width: 300 },
    { field: 'Action', headerName: 'Событие', flex: 1, minWidth: 400 },
  ];

  function handleAccessLogDialogClose() {
    setAccessLogDialogOpen(false);
  }
  return (
    <Dialog open={accessLogDialogOpen} onClose={handleAccessLogDialogClose} fullWidth maxWidth='lg'>
      <DialogTitle>Лог событий модуля оформления допусков</DialogTitle>
      <DialogContent>
        <DataGrid
          //  density='compact'
          disableColumnMenu
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          //hideFooter
          rowHeight={20}
          autoHeight
          rows={AccessLogsData}
          columns={columns}
          sx={{
            fontSize: '9px',
            '& .MuiDataGrid-columnHeaderTitle': {
              whiteSpace: 'normal',
              lineHeight: 'normal',
            },
            '& .MuiDataGrid-columnHeader': {
              // Forced to use important since overriding inline styles
              height: 'unset !important',
            },
            '& .MuiDataGrid-columnHeaders': {
              // Forced to use important since overriding inline styles
              maxHeight: '168px !important',
            },
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none',
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        />
      </DialogContent>
      <DialogActions>
        <Button size='small' variant='contained' sx={{ fontSize: '9px' }} onClick={handleAccessLogDialogClose}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AccessLogDialog;
