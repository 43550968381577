import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUnmountState, setUnmountStateDescription } from '../../../../Redux/Slices/UnmountExecutionSlice';
import { ToggleButtonGroup, ToggleButton, TextField } from '@mui/material';

function MySelect({ res }) {
  const unmountedState = useSelector((state) => state.UnmountExecution.checkedArr.find((elem) => elem.ID === res.ID));
  const dispatch = useDispatch();
  const commonUnmountedState = unmountedState.state ? unmountedState.state : unmountedState.unmounted_state;
  return (
    <>
      <ToggleButtonGroup
        size='small'
        value={commonUnmountedState}
        exclusive
        onChange={(e) => {
          dispatch(setUnmountState({ res, value: e.target.value }));
        }}
      >
        <ToggleButton color='success' sx={{ fontSize: '8px', width: '100px' }} value='Исправно'>
          Исправно
        </ToggleButton>
        <ToggleButton color='error' sx={{ fontSize: '8px', width: '100px' }} value='Неисправно'>
          Неисправно
        </ToggleButton>
      </ToggleButtonGroup>
      {commonUnmountedState === 'Неисправно' ? (
        <TextField
          sx={{ marginTop: '10px' }}
          label='Cимптомы неисправности'
          multiline
          maxRows={4}
          minRows={4}
          size='small'
          defaultValue={res.unmounted_state_description ? res.unmounted_state_description : ''}
          onBlur={(e) => {
            dispatch(setUnmountStateDescription({ res, value: e.target.value }));
          }}
          inputProps={{ style: { fontSize: '10px', lineHeight: '10px', margin: 0, width: '170px' } }}
          InputLabelProps={{ style: { fontSize: '10px', lineHeight: '10px' } }}
        />
      ) : null}
    </>
  );
}

export default MySelect;
