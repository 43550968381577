import { beeHandBookAPI } from './beeHandBookAPI';

export const SparePartFullnessAPI = beeHandBookAPI.enhanceEndpoints({ addTagTypes: ['ZIPFullnessData'] }).injectEndpoints({
  endpoints: (builder) => ({
    GetZIPFullnessData: builder.query({
      query: () => 'getZIPFullnessData',
      providesTags: ['ZIPFullnessData'],
    }),

    ZIPFullnessAction: builder.mutation({
      query: ({ action, id, data }) => ({
        url: `ZIPFulnessAction`,
        method: 'POST',
        body: { action, id, data },
      }),
      invalidatesTags: ['ZIPFullnessData'],
    }),
  }),
});

export const { useGetZIPFullnessDataQuery, useZIPFullnessActionMutation } = SparePartFullnessAPI;
