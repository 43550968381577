import React, { useState } from 'react';
import { TextField, Button, Divider, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { setAddZIPFullnessAddEntryDialogOpen } from '../../../../Redux/Slices/SparePartsFullnessSlice';
import { useZIPFullnessActionMutation } from '../../../../Redux/API/SparePartsFullnessAPI';
import MySnackbar from '../../../Access/UI/MySnackbar';

function ZIPFullnessAddEntryDialog() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const [ZIPFullnessAction] = useZIPFullnessActionMutation();
  const { addZIPFullnessAddEntryDialogOpen } = useSelector((state) => state.SparePartFullnessExecution);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const textFieldData = [
    { name: 'ZIP_Group', label: 'Группа модулей ЗИП', helperText: 'Введите наименование группы модулей ЗИП' },
    { name: 'ZIP_name', label: 'Наименование модуля', helperText: 'Введите наименование модуля' },
    { name: 'ZIP_NFSs', label: 'Возможные коды НФС', helperText: 'Введите возможные коды НФС модуля' },
    { name: 'ZIP_normal_qty', label: 'Необходимое кол-во в ЗИП-ЛО', helperText: 'Введите необходимое кол-во в ЗИП-ЛО' },
  ];

  function handleZIPFullnessAddEntryDialogClose(event, reason) {
    if (reason && reason === 'backdropClick') return;
    dispatch(setAddZIPFullnessAddEntryDialogOpen(false));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    let data = {
      ZIP_Group: e.target.elements.ZIP_Group.value,
      ZIP_name: e.target.elements.ZIP_name.value,
      ZIP_NFSs: e.target.elements.ZIP_NFSs.value,
      ZIP_normal_qty: e.target.elements.ZIP_normal_qty.value,
    };

    const res = await ZIPFullnessAction({ action: 'add', data }).unwrap();
    setSnackBarMessage(res.res);
    handleZIPFullnessAddEntryDialogClose();
  }

  return (
    <>
      <Dialog open={addZIPFullnessAddEntryDialogOpen} onClose={handleZIPFullnessAddEntryDialogClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle sx={{ padding: '10px 20px' }}>{mobileView ? 'Новая запись в паспорт ЗИП-ЛО' : 'Добавление записи в паспорт ЗИП-ЛО'}</DialogTitle>
          <Divider />
          <DialogContent>
            {textFieldData.map((elem, i) => (
              <TextField key={i} name={elem.name} margin='dense' fullWidth label={elem.label} variant='outlined' size='small' required />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleZIPFullnessAddEntryDialogClose}>Отмена</Button>
            <Button type='submit' autoFocus>
              Сохранить
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <MySnackbar message={snackBarMessage} setMessage={setSnackBarMessage} />
    </>
  );
}

export default ZIPFullnessAddEntryDialog;
