import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  Box,
  Stack,
  Typography,
  Switch,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
  MenuItem,
} from '@mui/material';

import { setMailPreparationDialogOpen, setAccessPosition, MailPreparationCheck, setMailPreparationCheckingDialogOpen } from '../../../Redux/Slices/AccessExecutionSlice';
import { useAccessMailJobsActionMutation, useAccessMailJobsActionStatusQuery, useAccessMailsDownloadMutation } from '../../../Redux/API/AccessExecutionAPI';

import { useDispatch, useSelector } from 'react-redux';
import AccessPositionInput from './AccessPositionInput';
import AccessMailsAwaitingAlert from './AccessMailsAwaitingAlert';
import MailPreparationCheckingDialog from './MailPreparationCheckingDialog';

function MailPreparationDialog({ data }) {
  const dispatch = useDispatch();
  const [skipPoll, setSkipPoll] = useState(true);
  const [AccessMailJobsAction] = useAccessMailJobsActionMutation();
  const [AccessMailsDownload] = useAccessMailsDownloadMutation();
  const [accessMailJobsPreparationProcessId, setAccessMailJobsPreparationProcessId] = useState();
  const [AccessMailJobsStatusDataState, setAccessMailJobsStatusDataState] = useState({ progress: 0, status: '' });
  const { data: AccessMailJobsStatusData } = useAccessMailJobsActionStatusQuery(accessMailJobsPreparationProcessId, {
    pollingInterval: 500,
    skip: skipPoll,
    refetchOnMountOrArgChange: true,
  });
  const { user } = useSelector((state) => state.Auth);
  const { mailPreparationDialogOpen, accessPosition, matchesMailJobs1 } = useSelector((state) => state.AccessExecution);
  const [selectedOrganization, setSelectedOrganization] = useState(data.Organization_name[0].Organization_name);
  const [selectedOrganizationsList, setSelectedOrganizationsList] = useState([]);
  const [selectedPositionsList, setSelectedPositionsList] = useState([]);
  const [mailPreparationAwaitingAlertOpen, setMailPreparationAwaitingAlertOpen] = useState(false);
  const [startAccessDate, setStartAccessDate] = useState(() => {
    let d = new Date();
    let date = [d.getFullYear(), ('0' + (d.getMonth() + 1)).slice(-2), ('0' + d.getDate()).slice(-2)].join('-');
    return date;
  });
  const [endAccessDate, setEndAccessDate] = useState(() => {
    let d = new Date();
    let date = [d.getFullYear(), 12, 31].join('-');
    return date;
  });

  const [matchesMailJobs, setMatchesMailJobs] = useState([]);
  const [mailOptions, setMailOptions] = useState({ addVehicles: true, addTools: false });

  function handleMailPreparationAwaitingAlertClose(event, reason) {
    if (reason && reason === 'backdropClick') return;
    setMailPreparationAwaitingAlertOpen(false);
  }

  function handleMailPreparationDialogClose(event, reason) {
    if (reason && reason === 'backdropClick') return;
    dispatch(setMailPreparationDialogOpen(false));

    setSelectedOrganizationsList([]);
    setSelectedPositionsList([]);
    setSelectedOrganization(data.Organization_name[0].Organization_name);
  }

  async function saveMailJobs() {
    handleMailPreparationDialogClose();
    setAccessMailJobsStatusDataState({ progress: 0, status: '' });
    setMailPreparationAwaitingAlertOpen(true);
    AccessMailJobsAction({ selectedOrganizationsList, selectedPositionsList, user, startAccessDate, endAccessDate, options: { ...mailOptions } })
      .unwrap()
      .then((res) => {
        setAccessMailJobsPreparationProcessId(res.processID);
        setSkipPoll(false);
      });
  }

  useEffect(() => {
    async function AccessMailStausCheck() {
      setAccessMailJobsStatusDataState({ progress: AccessMailJobsStatusData?.res.progress, status: AccessMailJobsStatusData?.res.status });
      if (AccessMailJobsStatusData?.res.progress === 100) {
        let fileName = AccessMailJobsStatusData.res.filePath.split('/')[2];
        setSkipPoll(true);
        const res = await AccessMailsDownload({ processID: accessMailJobsPreparationProcessId, fileName }).unwrap();
        if (res === 'Ready') handleMailPreparationAwaitingAlertClose();
      }
    }
    AccessMailStausCheck();
  }, [AccessMailJobsStatusData]);

  function AccessJobsSaveButtonHandler() {
    // dispatch(MailPreparationCheck({ existedMailJobs: data.MailJobs, newMailJobs: selectedPositionsList }));
    // console.log(matchesMailJobs1);

    let existedMailJobs = data.MailJobs;
    let newMailJobs = selectedPositionsList;
    let workMatchesMailJobs = [];

    for (const item of newMailJobs) {
      for (let i = 0; i < existedMailJobs.length; i++) {
        item.ERP === existedMailJobs[i].Position.split('_')[0] && workMatchesMailJobs.push(existedMailJobs[i]);
      }
    }

    if (workMatchesMailJobs.length !== 0) {
      setMatchesMailJobs(workMatchesMailJobs);
      dispatch(setMailPreparationCheckingDialogOpen(true));
    }

    if (workMatchesMailJobs.length === 0) {
      saveMailJobs();
    }
  }

  function AccessMailJobsDuplicateDelete() {
    setSelectedPositionsList(selectedPositionsList.filter((item) => !matchesMailJobs.some((pos) => pos.Position.split('_')[0] === item.ERP)));

    dispatch(setMailPreparationCheckingDialogOpen(false));
  }

  return (
    data.Organization_name && (
      <>
        <Dialog open={mailPreparationDialogOpen} onClose={handleMailPreparationDialogClose} maxWidth='lg'>
          <DialogTitle>Добавление задачи на подготовку писем</DialogTitle>
          <DialogContent style={{ height: '60vh' }} dividers>
            <DialogContentText>Выберете организации и позиции для оформления писем на допуск</DialogContentText>
            <Stack direction='row' spacing='20px' alignItems='center' justifyContent='center' sx={{ marginTop: '20px' }}>
              <TextField
                label='Дата начала допуска'
                type='date'
                size='small'
                value={startAccessDate}
                onChange={(e) => {
                  setStartAccessDate(e.target.value);
                }}
                sx={{ minWidth: 200 }}
                inputProps={{ style: { fontSize: '12px' } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label='Дата окончания допуска'
                type='date'
                size='small'
                value={endAccessDate}
                onChange={(e) => {
                  setEndAccessDate(e.target.value);
                }}
                sx={{ minWidth: 200 }}
                inputProps={{ style: { fontSize: '12px' } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Stack>
            <Stack direction='row' spacing='20px' sx={{ marginTop: '20px' }}>
              <Box>
                <TextField size='small' select label='Организация' helperText='Выберете организацию для оформления допуска' sx={{ width: '400px' }} value={selectedOrganization}>
                  {data.Organization_name.map((option, i) => (
                    <MenuItem
                      key={i}
                      value={option.Organization_name}
                      onClick={(e) => {
                        setSelectedOrganization(e.target.dataset.value);
                      }}
                    >
                      {option.Organization_name}
                    </MenuItem>
                  ))}
                </TextField>

                <Box align='right'>
                  <Button
                    variant='contained'
                    size='small'
                    sx={{ fontSize: '9px', marginTop: '5px', marginBottom: '20px' }}
                    onClick={() => {
                      !selectedOrganizationsList.includes(selectedOrganization) && setSelectedOrganizationsList([...selectedOrganizationsList, selectedOrganization]);
                    }}
                  >
                    Добавить организацию
                  </Button>
                </Box>
                <Card elevation={5} sx={{ width: '400px', height: '160px' }}>
                  <CardHeader subheader='Организации' sx={{ paddingTop: '5px', paddingBottom: '5px' }} />
                  <Divider />
                  <CardContent sx={{ overflow: 'auto', height: '100px' }}>
                    {selectedOrganizationsList.map((elem, i) => (
                      <Chip
                        key={i}
                        color='success'
                        size='small'
                        sx={{ fontSize: '10px', margin: '2px' }}
                        label={elem}
                        onDelete={() => {
                          setSelectedOrganizationsList(selectedOrganizationsList.filter((items) => items !== elem));
                        }}
                      />
                    ))}
                  </CardContent>
                </Card>
                <Stack mt='10px' direction='row' alignItems='center' justifyContent='end'>
                  <Typography variant='caption'>Включить в письмо список автомобилей</Typography>
                  <Switch
                    size='small'
                    checked={mailOptions.addVehicles}
                    onChange={(e) => {
                      setMailOptions({ ...mailOptions, addVehicles: e.target.checked });
                    }}
                  />
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent='end'>
                  <Typography variant='caption'>Включить в письмо список инструментов/приборов</Typography>
                  <Switch
                    size='small'
                    checked={mailOptions.addTools}
                    onChange={(e) => {
                      setMailOptions({ ...mailOptions, addTools: e.target.checked });
                    }}
                  />
                </Stack>
              </Box>
              <Box>
                <AccessPositionInput />
                <Box align='right'>
                  <Button
                    variant='contained'
                    disabled={accessPosition === ''}
                    size='small'
                    sx={{ fontSize: '9px', marginTop: '5px', marginBottom: '20px' }}
                    onClick={() => {
                      accessPosition !== null &&
                        !selectedPositionsList.some((item) => item.ERP === accessPosition.position_ERP) &&
                        setSelectedPositionsList([
                          ...selectedPositionsList,
                          { ERP: accessPosition.position_ERP, positionName: accessPosition.position_num + ' ' + accessPosition.position_name },
                        ]);
                      dispatch(setAccessPosition(''));
                    }}
                  >
                    Добавить позицию
                  </Button>
                </Box>
                <Card elevation={5} sx={{ width: '400px', height: '220px' }}>
                  <CardHeader subheader='Позиции' sx={{ paddingTop: '5px', paddingBottom: '5px' }} />
                  <Divider />
                  <CardContent sx={{ overflow: 'auto', height: '150px' }}>
                    {selectedPositionsList.map((elem) => (
                      <Chip
                        key={elem.ERP}
                        color='success'
                        size='small'
                        sx={{ fontSize: '10px', margin: '2px' }}
                        label={elem.ERP + ' (' + elem.positionName + ')'}
                        onDelete={() => {
                          setSelectedPositionsList(selectedPositionsList.filter((items) => items !== elem));
                        }}
                      />
                    ))}
                  </CardContent>
                </Card>
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <ButtonGroup size='small'>
              <Button sx={{ fontSize: '9px' }} onClick={handleMailPreparationDialogClose}>
                Отмена
              </Button>
              <Button
                sx={{ fontSize: '9px' }}
                onClick={AccessJobsSaveButtonHandler}
                autoFocus
                disabled={selectedPositionsList.length === 0 || selectedOrganizationsList.length === 0}
              >
                Оформить письма
              </Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
        <AccessMailsAwaitingAlert
          title={'Подготовка писем на допуск'}
          progress={AccessMailJobsStatusDataState.progress}
          status={AccessMailJobsStatusDataState.status}
          accessMailsAwaitingAlertOpen={mailPreparationAwaitingAlertOpen}
          handleAccessMailsAwaitingAlertClose={handleMailPreparationAwaitingAlertClose}
        />
        <MailPreparationCheckingDialog callback={saveMailJobs} matchesMailJobs={matchesMailJobs} AccessMailJobsDuplicateDelete={AccessMailJobsDuplicateDelete} />
      </>
    )
  );
}

export default MailPreparationDialog;
