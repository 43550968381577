import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { TextField, Chip, Box, Paper, LinearProgress } from '@mui/material';
import NFSSearchResult from './NFSSearchResult';

function NFS() {
  const { accessToken } = useSelector((state) => state.Auth);
  const [NFSValue, setNFSValue] = useState('');
  const [searchResult, setSearchResult] = useState();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    let delay;
    if (NFSValue.trim() !== '' && NFSValue.length > 2) {
      delay = setTimeout(() => {
        setLoad(true);
        setSearchResult('');

        axios
          .get('https://beehandbook.ru:4001/NFSSearch?SearchString=' + NFSValue, {
            headers: {
              Authorization: accessToken,
            },
            withCredentials: true,
          })
          .then((data) => {
            setLoad(false);
            setSearchResult(data.data);
            // console.log(data);
          });
      }, 700);
    } else {
      setLoad(false);
    }
    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [NFSValue]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Chip label='Справочник кодов НФС' sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} />
          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', height: '80vh', padding: '10px' }}>
              <TextField
                fullWidth
                label='Поиск по кодам НФС'
                variant='outlined'
                helperText='Введите код НФС или описание актива'
                size='small'
                value={NFSValue}
                onChange={(e) => {
                  setSearchResult('');
                  setNFSValue(e.target.value);
                }}
              />
              {load && <LinearProgress />}
              <NFSSearchResult data={searchResult} />
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default NFS;
