import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

function NFSSearchResult({ data }) {
  return (
    <>
      {data && data !== '' && (
        <TableContainer component={Paper} sx={{ maxHeight: '60vh', marginTop: '10px' }}>
          <Table aria-label='simple table' stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Код НФС</b>
                </TableCell>
                <TableCell align='center'>
                  <b>Описание</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontSize: '8px' }}>
              {data.map((res, i) => (
                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                  <TableCell sx={{ fontSize: '12px', borderRight: '1px' }}>{res.NFS}</TableCell>
                  <TableCell sx={{ fontSize: '12px' }} align='right'>
                    {res.Description}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default NFSSearchResult;
