import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress,
  CircularProgress,
  IconButton,
  Tooltip,
  Grid,
  Checkbox,
  Chip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { useDispatch, useSelector } from 'react-redux';

import { useGetVacationDataQuery, useSaveVacationDataMutation } from '../../Redux/API/VacationPlanningAPI';
import { setVacationArray, setSnackBarMessage, handleLockCheckBoxChange } from '../../Redux/Slices/VacationPlanningExecutionSlice';
import MySnackbar from '../Access/UI/MySnackbar';
import EmployeeYearVacationPlan from './UI/EmployeeYearVacationPlan';
import TableHeaderMonthsAndDays from './UI/TableHeaderMonthsAndDays';

function VacationPlanning() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useSelector((state) => state.Auth);
  const { vacationArray, snackBarMessage, planningYear } = useSelector((state) => state.VacationPlanning);

  const dispatch = useDispatch();
  const [skip, setSkip] = useState(true);
  const { data: VacationData, isFetching, refetch: refetchVacationData } = useGetVacationDataQuery(null, { skip });
  const [SaveVacationData] = useSaveVacationDataMutation();

  useEffect(() => {
    dispatch(setVacationArray([]));
    setSkip(false);
    // eslint-disable-next-line
  }, []);

  function setupVacationArray() {
    let workArr = [];

    //костыль для решения странной проблемы с парсингом вложенного массива массивов дней отпуска работника.
    VacationData.res.forEach((element) => {
      workArr.push({
        id: element.id,
        employee: element.employee,
        totalVacationDays: element.totalVacationDays,
        vacationDays: JSON.parse(element.vacationDays),
        isLocked: element.isLocked,
      });
    });
    // dispatch(setVacationArray([...VacationData.res]));
    dispatch(setVacationArray([...workArr]));
  }

  useEffect(() => {
    if (VacationData) {
      setupVacationArray();
      setSkip(false);
    }
    // eslint-disable-next-line
  }, [VacationData]);

  async function saveEmployeeVacationDataHandler() {
    let workArr = [];
    vacationArray.forEach((element) => {
      workArr.push({
        id: element.id,
        employee: element.employee,
        totalVacationDays: element.totalVacationDays,
        vacationDays: JSON.stringify(element.vacationDays),
        isLocked: element.isLocked,
      });
    });
    const index = workArr.findIndex((elem) => elem.employee === user.full_name);

    const res = await SaveVacationData(user.role === 'Admin' ? workArr : [workArr[index]]).unwrap();
    dispatch(setSnackBarMessage(res.res));
  }

  function refreshButtonClickHandle() {
    refetchVacationData();
    setupVacationArray();
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Chip
            label={mobileView ? 'Планирование отпусков' : 'Планирование отпусков Иркутского отдела эксплуатации'}
            sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
          />

          <Paper elevation={24}>
            <Box sx={{ width: 'auto', minHeight: '80vh', padding: '10px' }}>
              <Box sx={{ height: '75vh' }}>
                {vacationArray.length === 0 ? (
                  <LinearProgress />
                ) : (
                  vacationArray.length !== 0 && (
                    <Box style={{ position: 'relative' }}>
                      <Box style={{ overflowX: 'scroll', overflowY: 'visible', marginLeft: '231px' }}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                style={{
                                  position: 'absolute',
                                  left: 0,
                                  margin: 0,
                                  padding: 0,
                                  width: '205px',
                                  height: '61px',
                                  border: '1px solid black',
                                  borderRight: 'none',
                                  fontSize: '30px',
                                }}
                                align='center'
                              >
                                <Grid container sx={{ marginTop: '7px' }}>
                                  <Grid item xs={4}>
                                    {isFetching && <CircularProgress disableShrink sx={{ marginTop: '2px' }} />}
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Box sx={{ marginTop: '2px' }}>
                                      <b>{planningYear}</b>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Tooltip title='Обновить' placement='top' arrow>
                                      <IconButton onClick={refreshButtonClickHandle} color='primary' disabled={isFetching}>
                                        <RefreshIcon sx={{ fontSize: '30px' }} />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </TableCell>

                              <TableCell
                                sx={{
                                  position: 'absolute',
                                  left: '205px',
                                  border: '1px solid black',
                                  writingMode: 'vertical-rl',
                                  fontSize: '8px',
                                  margin: 0,
                                  padding: 0,
                                  paddingRight: '5px',
                                  width: '20px',
                                  height: '61px',
                                }}
                                align='center'
                              >
                                Дней отпуска
                              </TableCell>
                            </TableRow>

                            <TableHeaderMonthsAndDays />

                            {vacationArray.map((employee, i) => (
                              <React.Fragment key={i}>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      position: 'absolute',
                                      left: 0,
                                      borderBottom: '1px solid black',
                                      borderLeft: '1px solid black',
                                      width: '205px',
                                      padding: '0',
                                      height: '28px',
                                      fontSize: '10px',
                                    }}
                                    align='right'
                                  >
                                    <Grid container>
                                      <Grid item xs={1}>
                                        {user.role === 'Admin' && (
                                          <Checkbox
                                            sx={{
                                              padding: '4px',
                                              '&.Mui-checked': {
                                                color: 'red',
                                              },
                                            }}
                                            checked={employee.isLocked === 1 ? true : false}
                                            onChange={() => {
                                              dispatch(handleLockCheckBoxChange(i));
                                            }}
                                            size='small'
                                            icon={<LockOpenOutlinedIcon />}
                                            checkedIcon={<LockOutlinedIcon />}
                                          />
                                        )}
                                      </Grid>
                                      <Grid item xs={11}>
                                        <Box sx={{ marginRight: '5px', marginTop: '7px' }}>{employee.employee}</Box>
                                      </Grid>
                                    </Grid>
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      position: 'absolute',
                                      left: '205px',
                                      borderBottom: '1px solid black',
                                      borderLeft: '1px solid black',
                                      borderRight: '1px solid black',
                                      width: '25px',
                                      height: '28px',
                                      fontSize: '10px',
                                      padding: 0,
                                      margin: 0,
                                    }}
                                    align='center'
                                  >
                                    <Box sx={{ marginTop: '7px' }}> {employee.totalVacationDays - employee.vacationDays.length}</Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <EmployeeYearVacationPlan i={i} />
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Box>
                  )
                )}
              </Box>
              <Box align='right' mt='10px'>
                {vacationArray.length !== 0 && (
                  <Button variant='contained' size='small' sx={{ fontSize: '9px', mr: '5px' }} onClick={saveEmployeeVacationDataHandler}>
                    Сохранить
                  </Button>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
      <MySnackbar
        message={snackBarMessage}
        setMessage={(e) => {
          dispatch(setSnackBarMessage(e));
        }}
      />
    </>
  );
}

export default VacationPlanning;
