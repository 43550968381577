import React from 'react';

import { Paper, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, LinearProgress } from '@mui/material';
import MyCheckbox from './MyCheckbox';

import UnmountExecutionBtn from './UnmountExecutionBtn';
import MySelect from './MySelect';

import { useSelector, useDispatch } from 'react-redux';
import { toggleCheckGlobal } from '../../../../Redux/Slices/UnmountExecutionSlice';

function UnmountSearchResult({ data, handler, saving }) {
  const { globalCheck, checkedArr } = useSelector((state) => state.UnmountExecution);
  const dispatch = useDispatch();

  return (
    <>
      {data && data !== '' && (
        <TableContainer component={Paper} sx={{ maxHeight: '68vh', marginTop: '10px' }}>
          <Table aria-label='simple table' stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={globalCheck}
                    onChange={() => {
                      dispatch(toggleCheckGlobal(data));
                    }}
                    size='small'
                  />
                </TableCell>
                <TableCell sx={{ fontSize: '10px' }}>
                  <b>Актив</b>
                </TableCell>
                <TableCell sx={{ fontSize: '10px' }}>
                  <b>Код НФС</b>
                </TableCell>
                <TableCell align='center' sx={{ fontSize: '10px', minWidth: '200px' }}>
                  <b>Описание</b>
                </TableCell>
                <TableCell align='center' sx={{ fontSize: '10px' }}>
                  <b>Серийный номер</b>
                </TableCell>
                <TableCell align='center' sx={{ fontSize: '10px' }}>
                  <b>Партия</b>
                </TableCell>
                <TableCell align='center' sx={{ fontSize: '10px' }}>
                  <b>Инвентарный номер</b>
                </TableCell>
                <TableCell align='center' sx={{ fontSize: '10px' }}>
                  <b>Кол-во</b>
                </TableCell>
                <TableCell align='center' sx={{ fontSize: '10px' }}>
                  <b>ГФК</b>
                </TableCell>
                <TableCell align='center' sx={{ fontSize: '10px' }}>
                  <b>ЕРП</b>
                </TableCell>
                <TableCell align='center' sx={{ fontSize: '10px' }}>
                  <b>Адрес</b>
                </TableCell>
                <TableCell align='center' sx={{ fontSize: '10px' }}>
                  <b>Состояние</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontSize: '8px' }}>
              {/* ВНИМАНИЕ ВЫВОД ОГРАНИЧЕН НА 100 ЗАПИСЕЙ !!!!! */}
              {data.slice(0, 100).map((res, i) => (
                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                  <TableCell>
                    <MyCheckbox res={res} />
                  </TableCell>
                  <TableCell sx={{ fontSize: '10px', borderRight: '1px' }}>{res.Active}</TableCell>
                  <TableCell sx={{ fontSize: '10px' }} align='center'>
                    {res.NFS}
                  </TableCell>
                  <TableCell sx={{ fontSize: '10px' }} align='left'>
                    {res.Description}
                  </TableCell>
                  <TableCell sx={{ fontSize: '10px' }} align='center'>
                    {res.SN}
                  </TableCell>
                  <TableCell sx={{ fontSize: '10px' }} align='center'>
                    {res.Part}
                  </TableCell>
                  <TableCell sx={{ fontSize: '10px' }} align='center'>
                    {res.Inventory_num}
                  </TableCell>
                  <TableCell sx={{ fontSize: '10px' }} align='center'>
                    {res.Qty}
                  </TableCell>
                  <TableCell sx={{ fontSize: '10px' }} align='center'>
                    {res.GFK}
                  </TableCell>
                  <TableCell sx={{ fontSize: '10px' }} align='center'>
                    {res.ERP}
                  </TableCell>
                  <TableCell sx={{ fontSize: '10px', minWidth: '200px' }} align='center'>
                    {res.Address}
                  </TableCell>

                  <TableCell sx={{ fontSize: '10px' }} align='center'>
                    {checkedArr.includes(checkedArr.find((elem) => elem.ID === res.ID)) && <MySelect res={res} />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {checkedArr.length > 0 && (
        <>
          <Box align='right' mt='10px'>
            <UnmountExecutionBtn handler={handler} />
          </Box>
          {saving && <LinearProgress sx={{ marginTop: '10px' }} />}
        </>
      )}
    </>
  );
}

export default UnmountSearchResult;
