import { beeHandBookAPI } from './beeHandBookAPI';

export const MoveExecutionAPI = beeHandBookAPI.enhanceEndpoints({ addTagTypes: ['MoveRegister'] }).injectEndpoints({
  endpoints: (builder) => ({
    MoveSearch: builder.query({
      query: (MoveDesc) => `UnmountSearch?SearchString=${MoveDesc}`,
    }),
    MovePositionSearch: builder.query({
      query: (positionDesc) => `PositionSearch?SearchString=${positionDesc}`,
    }),

    GetMoveRegister: builder.query({
      query: () => `GetMoveRegister`,
      providesTags: ['MoveRegister'],
    }),

    SaveMoveRegisterEntry: builder.mutation({
      query: (checkedArr) => ({
        url: `SaveMoveRegisterEntry`,
        method: 'POST',
        body: checkedArr,
      }),
      invalidatesTags: ['MoveRegister'],
    }),

    MoveRegisterUserAction: builder.mutation({
      query: ({ action, state, user, data }) => ({
        url: `MoveRegisterUserAction`,
        method: 'POST',
        body: { action, state, user, data },
        responseHandler: async (response) => {
          if (action === 'download_act') {
            var hiddenElement = document.createElement('a');
            var url = window.URL || window.webkitURL;
            var MoveActZipFile = url.createObjectURL(await response.blob());
            hiddenElement.href = MoveActZipFile;
            hiddenElement.target = '_blank';
            hiddenElement.download = `MoveAct.zip`;
            hiddenElement.click();
            return 'Ready';
          } else {
            return JSON.parse(await response.text());
          }
        },
      }),

      invalidatesTags: ['MoveRegister'],
    }),
  }),
});

export const { useMoveSearchQuery, useMovePositionSearchQuery, useGetMoveRegisterQuery, useSaveMoveRegisterEntryMutation, useMoveRegisterUserActionMutation } = MoveExecutionAPI;
