import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

function TableHeaderMonthsAndDays() {
  const { holidays, planningYear } = useSelector((state) => state.VacationPlanning);
  const monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

  function months() {
    let months = [];
    for (let monthNo = 1; monthNo <= 12; monthNo++) {
      let DInM = daysInMonth(monthNo, planningYear);
      months.push(
        <TableCell key={monthNo} colSpan={DInM} sx={{ padding: 0, margin: 0, paddingLeft: '10px', height: '30px', border: '1px solid black' }} align='center'>
          {monthNames[monthNo - 1]}
        </TableCell>
      );
    }
    return months;
  }

  function days() {
    let days = [];
    for (let month = 1; month <= 12; month++) {
      let DInM = daysInMonth(month, planningYear);
      for (let dayInMonth = 1; dayInMonth <= DInM; dayInMonth++) {
        days.push(
          <TableCell
            key={`${planningYear}_${month}_${dayInMonth}`}
            sx={{
              fontSize: '12px',
              padding: 0,
              margin: 0,
              border: '1px solid black',
              minWidth: '20px',
              width: '20px',
              height: '30px',
              backgroundColor: holidays.some((elem) => elem[0] === month && elem[1] === dayInMonth) ? '#FDEDEC' : 'white',
            }}
            align='center'
          >
            {dayInMonth}
          </TableCell>
        );
      }
    }
    return days;
  }
  return (
    <>
      <TableRow>{months()}</TableRow>
      <TableRow>{days()}</TableRow>
    </>
  );
}

export default TableHeaderMonthsAndDays;
