import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

function AlertDialog(props) {
  const [open, setOpen] = useState(false);

  function showAlertDialog() {
    setOpen(true);
  }

  function hideAlertDialog() {
    setOpen(false);
  }

  function confirmRequest() {
    props.response();
    hideAlertDialog();
  }

  return (
    <>
      {props.children(showAlertDialog)}
      {open && (
        <Dialog open={open} onClose={hideAlertDialog} maxWidth='xs'>
          <DialogTitle>{props.title}</DialogTitle>
          <DialogActions>
            <Button variant='contained' size='small' onClick={hideAlertDialog}>
              Нет
            </Button>
            <Button variant='contained' size='small' onClick={confirmRequest} autoFocus>
              Да
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default AlertDialog;
