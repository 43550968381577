import { beeHandBookAPI } from './beeHandBookAPI';

export const PaymentExecutionAPI = beeHandBookAPI.enhanceEndpoints({ addTagTypes: ['Estimates', 'Logs'] }).injectEndpoints({
  endpoints: (builder) => ({
    EstimateFileUpload: builder.mutation({
      query: (data) => ({
        url: `EstimateFileUpload`,
        method: 'POST',
        body: data,
        cache: 'no-cache',
      }),
      invalidatesTags: ['Estimates', 'Logs'],
    }),

    CreateOrderPDF: builder.mutation({
      query: ({ organization, estimateDataArr, orderDate }) => ({
        url: `CreateOrderPDF`,
        method: 'POST',
        body: { organization, estimateDataArr, orderDate },
        cache: 'no-cache',
        responseHandler: async (response) => {
          var hiddenElement = document.createElement('a');
          var url = window.URL || window.webkitURL;
          var OrderPDFFile = url.createObjectURL(await response.blob());
          hiddenElement.href = OrderPDFFile;
          hiddenElement.target = '_blank';
          hiddenElement.download = `Заказ_${estimateDataArr[0].orderNum}.pdf`;
          hiddenElement.click();
          return 'Ready';
        },
      }),
      invalidatesTags: ['Estimates', 'Logs'],
    }),
  }),
});

export const { useEstimateFileUploadMutation, useCreateOrderPDFMutation } = PaymentExecutionAPI;
