import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { beeHandBookAPI } from './API/beeHandBookAPI';
import AuthSliceReducer from './Slices/AuthSlice';
import SparePartsFullnessSliceReducer from './Slices/SparePartsFullnessSlice';
import UnmountExecutionSliceReducer from './Slices/UnmountExecutionSlice';
import MoveExecutionSliceReducer from './Slices/MoveExecutionSlice';
import AccessExecutionSliceReducer from './Slices/AccessExecutionSlice';
import ZIPExecutionSliceReducer from './Slices/ZIPExecutionSlice';
import PaymentExecutionSliceReducer from './Slices/PaymentExecutionSlice';
import PositionsExecutionSliceReducer from './Slices/PositionsExecutionSlice';
import VacationPlanningExecutionSliceReducer from './Slices/VacationPlanningExecutionSlice';

export const store = configureStore({
  reducer: {
    [beeHandBookAPI.reducerPath]: beeHandBookAPI.reducer,
    Auth: AuthSliceReducer,
    SparePartFullnessExecution: SparePartsFullnessSliceReducer,
    UnmountExecution: UnmountExecutionSliceReducer,
    MoveExecution: MoveExecutionSliceReducer,
    AccessExecution: AccessExecutionSliceReducer,
    ZIPExecution: ZIPExecutionSliceReducer,
    PaymentExecution: PaymentExecutionSliceReducer,
    PositionsExecution: PositionsExecutionSliceReducer,
    VacationPlanning: VacationPlanningExecutionSliceReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(beeHandBookAPI.middleware),
});

setupListeners(store.dispatch);
