import { createSlice, current } from '@reduxjs/toolkit';

const currentDate = () => {
  let d = new Date();
  let date = [d.getFullYear(), ('0' + (d.getMonth() + 1)).slice(-2), ('0' + d.getDate()).slice(-2)].join('-');
  return date;
};

const initialState = {
  selectedRows: [],
  ZIPNewSelectedCartDialogOpen: false,
  ZIPMountPosition: '',
  ZIPMountDate: currentDate(),
  ZIPMountParams: {},
};

const ZIPExecutionSlice = createSlice({
  name: 'ZIPExecutionState',
  initialState,

  reducers: {
    setSelectedRows: (state, action) => {
      if (action.payload === 'clear') {
        state.selectedRows = [];
        return;
      }
      if (!state.selectedRows.some((elem) => elem.id === action.payload.id)) {
        state.selectedRows.push({ ...action.payload, ZIPMountQty: '1' });
      } else {
        state.selectedRows = state.selectedRows.filter((elem) => elem.id !== action.payload.id);
      }
    },

    setZIPNewSelectedCartDialogOpen: (state, action) => {
      state.ZIPNewSelectedCartDialogOpen = action.payload;
    },

    deleteSelectedZipFromCart: (state, action) => {
      state.selectedRows = state.selectedRows.filter((elem) => elem.id !== action.payload);
      if (state.selectedRows.length === 0) {
        state.ZIPNewSelectedCartDialogOpen = false;
        state.ZIPMountParams = {};
        state.ZIPMountPosition = '';
      }
    },

    changeZIPMountQty: (state, action) => {
      const index = state.selectedRows.findIndex((elem) => elem.id === action.payload.id);

      if (index !== -1) {
        if (action.payload.value >= 1 && action.payload.value <= state.selectedRows[index].Qty_res) state.selectedRows[index].ZIPMountQty = action.payload.value;
      }
    },

    setZIPMountPosition: (state, action) => {
      state.ZIPMountPosition = action.payload;
      if (state.ZIPMountPosition !== '' && state.ZIPMountPosition !== null) {
        state.ZIPMountParams = {
          mount_date: state.ZIPMountDate,
          position_num: state.ZIPMountPosition.position_num,
          position_name: state.ZIPMountPosition.position_name,
          position_ERP: state.ZIPMountPosition.position_ERP,
          project: '14000246',
          position_GFK: state.ZIPMountPosition.position_MRB,
          TT: '',
        };
      }
    },

    setZIPMountposGFKValue: (state, action) => {
      state.ZIPMountParams.position_GFK = action.payload;
      state.ZIPMountParams.position_GFK === state.ZIPMountPosition.position_MRB ? (state.ZIPMountParams.project = '14000246') : (state.ZIPMountParams.project = '14002214');
    },

    setZIPMountTT: (state, action) => {
      state.ZIPMountParams.TT = action.payload;
    },

    setZIPMountDate: (state, action) => {
      state.ZIPMountParams.mount_date = action.payload;
    },

    clearZIPMountParams: (state, action) => {
      state.ZIPMountParams = {};
      state.ZIPMountPosition = '';
    },
  },
});

export const {
  setSelectedRows,
  setZIPNewSelectedCartDialogOpen,
  deleteSelectedZipFromCart,
  changeZIPMountQty,
  setZIPMountPosition,
  setZIPMountposGFKValue,
  setZIPMountTT,
  setZIPMountDate,
  clearZIPMountParams,
} = ZIPExecutionSlice.actions;

export default ZIPExecutionSlice.reducer;
