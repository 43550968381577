import React from 'react';
import { TextField } from '@mui/material';

function ProjectInput({ project, posValue }) {
  return (
    <TextField
      label='Номер проекта'
      InputLabelProps={{
        shrink: true,
      }}
      value={project}
      size='small'
      sx={{ minWidth: '110px', width: '110px' }}
      inputProps={{ style: { fontSize: '12px' } }}
      disabled={posValue ? false : true}
    />
  );
}

export default ProjectInput;
