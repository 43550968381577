import React, { useState, useEffect } from 'react';
import { Box, Button, Select, MenuItem, ButtonGroup, Popover, Divider } from '@mui/material';

import { useUnmountRegisterUserActionMutation } from '../../../../Redux/API/UnmountExecutionAPI';

import AlertDialog from '../../../Access/UI/AlertDialog';

function StateEditPopover({ open, anchorEl, handleClose, stateRowValueArr, user, callback }) {
  const [stateEditValue, setStateEditValue] = useState('Исправно');
  const [UnmountRegisterUserAction] = useUnmountRegisterUserActionMutation();

  useEffect(() => {
    setStateEditValue(stateRowValueArr[0]?.unmounted_state ?? 'Исправно');
  }, [open]);

  async function handlePopoverClose() {
    let workArr = [];
    stateRowValueArr.forEach((elem) => {
      workArr.push({ ...elem, unmounted_state: stateEditValue });
    });

    const res = await UnmountRegisterUserAction({
      action: 'save_state',
      user,
      checkedArr: workArr,
    }).unwrap();
    handleClose();
    callback(res);
  }

  return (
    <AlertDialog
      title='Вы уверены ?'
      response={() => {
        handlePopoverClose();
      }}
    >
      {(showAlertDialog) => (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            stateEditValue !== stateRowValueArr[0]?.unmounted_state ? showAlertDialog() : handleClose();
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Select
            size='small'
            sx={{ margin: '5px', width: '270px' }}
            value={stateEditValue}
            onChange={(event) => {
              setStateEditValue(event.target.value);
            }}
          >
            <MenuItem value={'Неисправно, на утилизацию'}>Неисправно, на утилизацию</MenuItem>
            <MenuItem value={'Неисправно, в ремонт'}>Неисправно, в ремонт</MenuItem>
            <MenuItem value={'Исправно'}>Исправно</MenuItem>
          </Select>
          <Divider />
          <Box align='right'>
            <ButtonGroup size='small' sx={{ margin: '5px 5px 5px 0px' }}>
              <Button
                sx={{ fontSize: '7px' }}
                onClick={() => {
                  handleClose();
                }}
              >
                Отмена
              </Button>
              <Button
                disabled={stateEditValue === stateRowValueArr[0]?.unmounted_state}
                sx={{ fontSize: '7px' }}
                onClick={() => {
                  showAlertDialog();
                }}
              >
                Сохранить
              </Button>
            </ButtonGroup>
          </Box>
        </Popover>
      )}
    </AlertDialog>
  );
}

export default StateEditPopover;
