import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Grid, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from 'react-redux';
import { loggedInCheck, loginUser } from '../Redux/Slices/AuthSlice';

function Authorization() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [alarm, setAlarm] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    axios
      .post(
        'https://beehandbook.ru:4001/login',

        {
          email: e.target.elements.email.value,
          password: e.target.elements.password.value,
        },
        { withCredentials: true }
      )

      .then((data) => {
        setLoading(false);
        dispatch(loginUser(data.headers.authorization));
        dispatch(loggedInCheck());
      })
      .catch((error) => {
        setLoading(false);
        setAlarm(error?.response?.data);
      });
  }

  return (
    <Grid
      container
      sx={{
        minWidth: '100%',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
      }}
      alignItems='center'
      direction='column'
    >
      <Grid item mb={4}>
        <Typography variant='h4'>beeHandBook</Typography>
      </Grid>
      <Grid item>
        <form onSubmit={handleSubmit}>
          <Stack spacing={1} noValidate autoComplete='off' sx={{ width: '300px' }}>
            <TextField
              id='email'
              label='Email'
              variant='outlined'
              placeholder='Введите email'
              size='small'
              onFocus={() => {
                setAlarm('');
              }}
              required
            />
            <TextField
              id='password'
              type='password'
              label='Пароль'
              variant='outlined'
              placeholder='Введите пароль'
              size='small'
              onFocus={() => {
                setAlarm('');
              }}
              required
            />
            <Typography variant='overline' display={alarm === '' ? 'none' : 'block'} align='center' color='red'>
              {alarm}
            </Typography>
            <LoadingButton type='submit' variant='contained' loading={loading}>
              Войти
            </LoadingButton>
          </Stack>
        </form>
      </Grid>
    </Grid>
  );
}

export default Authorization;
