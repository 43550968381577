import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Typography, Box, Paper, LinearProgress, Button, Tooltip, Stack, Chip, Switch, useMediaQuery, useTheme } from '@mui/material';

import { DataGrid, gridClasses, GridActionsCellItem, GridToolbar, ruRU } from '@mui/x-data-grid';

import DeleteIcon from '@mui/icons-material/Delete';

import { useGetZIPFullnessDataQuery } from '../../../Redux/API/SparePartsFullnessAPI';
import { setAddZIPFullnessAddEntryDialogOpen } from '../../../Redux/Slices/SparePartsFullnessSlice';
import { useZIPFullnessActionMutation } from '../../../Redux/API/SparePartsFullnessAPI';
import AlertDialog from '../../Access/UI/AlertDialog';
import ZIPFullnessAddEntryDialog from './UI/ZIPFullnessAddEntryDialog';
import MySnackbar from '../../Access/UI/MySnackbar';

function ZIPFulness() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const { data: ZIPFullnessData, isLoading: isZIPFullnessDataLoading } = useGetZIPFullnessDataQuery({}, { refetchOnMountOrArgChange: true });
  const [ZIPFullnessAction] = useZIPFullnessActionMutation();
  const { user } = useSelector((state) => state.Auth);
  const [IdForDelete, setIdForDelete] = useState();
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [wrap, setWrap] = useState(false);

  async function deleteZIPFullnessDataEntryHandler() {
    const res = await ZIPFullnessAction({ action: 'delete', id: IdForDelete }).unwrap();
    setSnackBarMessage(res.res);
  }

  const columns = [
    { field: 'ZIP_Group', headerName: 'Группа модулей ЗИП', width: 100, editable: user.role === 'Admin' ? true : false },
    { field: 'ZIP_name', headerName: 'Наименование модуля', width: 100, editable: user.role === 'Admin' ? true : false },
    { field: 'ZIP_NFSs', headerName: 'Возможные коды НФС', flex: 1, minWidth: 400, editable: user.role === 'Admin' ? true : false },
    { field: 'ZIP_normal_qty', headerName: 'Необходимое кол-во в ЗИП-ЛО', width: 150, editable: user.role === 'Admin' ? true : false },
    { field: 'ZIP_In_Stock_Irkutsk', headerName: 'Актуальное кол-во в ЗИП-ЛО Иркутска', width: 150 },
    { field: 'ZIP_In_Stock_Bratsk', headerName: 'Актуальное кол-во в ЗИП-ЛО Братска', width: 150 },
    user.role === 'Admin' && {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      width: 80,
      getActions: (params) => [
        <AlertDialog title='Вы уверены ?' response={deleteZIPFullnessDataEntryHandler}>
          {(showAlertDialog) => (
            <Tooltip title={`Удалить ${params.row.ZIP_name} из паспорта ЗИП-ЛО`}>
              <GridActionsCellItem
                icon={<DeleteIcon sx={{ color: 'red', fontSize: '15px' }} />}
                label='Удалить'
                onClick={() => {
                  setIdForDelete(params.id);
                  showAlertDialog();
                }}
              />
            </Tooltip>
          )}
        </AlertDialog>,
      ],
    },
  ];

  function wrapSwitchHandler(value) {
    value.target.checked ? setWrap(true) : setWrap(false);
  }
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Stack direction='row' justifyContent={user.role === 'Admin' ? 'space-between' : 'start'} alignItems='center'>
            <Chip label={mobileView ? 'Анализ ЗИП-ЛО' : 'Анализ достаточности ЗИП-ЛО'} sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} />
            <Stack direction='row' alignItems='center' justifyContent='end' mb='8px'>
              {!mobileView && (
                <>
                  <Typography variant='caption'>Включить перенос строк в таблице</Typography>
                  <Switch size='small' checked={wrap} onChange={wrapSwitchHandler} disabled />{' '}
                </>
              )}

              {user.role === 'Admin' && (
                <Button
                  variant='contained'
                  size='small'
                  sx={{ fontSize: '9px', marginLeft: '5px', minWidth: '210px' }}
                  onClick={() => {
                    dispatch(setAddZIPFullnessAddEntryDialogOpen(true));
                  }}
                >
                  Добавить запись в паспорт ЗИП-ЛО
                </Button>
              )}
            </Stack>
          </Stack>

          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', minHeight: '80vh', padding: '10px' }}>
              {isZIPFullnessDataLoading ? (
                <LinearProgress />
              ) : ZIPFullnessData?.length === 0 ? (
                <Box align='center'>Записи в паспорте ЗИП-ЛО для анализа отсутствуют</Box>
              ) : (
                <>
                  <DataGrid
                    disableColumnMenu
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    hideFooter
                    rowHeight={27}
                    columnHeaderHeight={35}
                    getRowHeight={() => (wrap ? 'auto' : null)}
                    getEstimatedRowHeight={() => (wrap ? 100 : null)}
                    editMode='row'
                    processRowUpdate={async (updatedRow, originalRow) => {
                      if (JSON.stringify(updatedRow) !== JSON.stringify(originalRow)) {
                        const res = await ZIPFullnessAction({
                          action: 'edit',
                          id: updatedRow.id,
                          data: { ZIP_Group: updatedRow.ZIP_Group, ZIP_name: updatedRow.ZIP_name, ZIP_NFSs: updatedRow.ZIP_NFSs, ZIP_normal_qty: updatedRow.ZIP_normal_qty },
                        }).unwrap();
                        setSnackBarMessage(res.res);
                      }
                      return updatedRow;
                    }}
                    onProcessRowUpdateError={() => {}}
                    rows={ZIPFullnessData}
                    columns={columns}
                    sx={{
                      height: '80vh',
                      fontSize: '10px',

                      '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                      },
                      '& .MuiDataGrid-columnHeader': {
                        // Forced to use important since overriding inline styles
                        height: 'unset !important',
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        // Forced to use important since overriding inline styles
                        maxHeight: '150px !important',
                      },
                      [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                        outline: 'none',
                      },
                      [`& .${gridClasses.cell}`]: wrap && {
                        py: 1,
                      },

                      '.bad': {
                        backgroundColor: '#F5B7B1',
                        transition: '0.1s',
                        '&:hover': {
                          bgcolor: '#F1948A',
                        },
                      },
                      '.warning': {
                        backgroundColor: '#FCF3CF',
                        transition: '0.1s',
                        '&:hover': {
                          bgcolor: '#F9E79F',
                        },
                      },
                    }}
                    getRowClassName={(params) => {
                      if (Number(params.row.ZIP_In_Stock_Irkutsk) + Number(params.row.ZIP_In_Stock_Bratsk) === 0) return 'bad';
                      if (Number(params.row.ZIP_In_Stock_Irkutsk) + Number(params.row.ZIP_In_Stock_Bratsk) === 1) return 'warning';
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: {
                          delimiter: ';',
                          utf8WithBom: true,
                          disableToolbarButton: mobileView,
                        },
                      },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                  />
                </>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>
      <ZIPFullnessAddEntryDialog />
      <MySnackbar message={snackBarMessage} setMessage={setSnackBarMessage} />
    </>
  );
}

export default ZIPFulness;
