import React, { useState } from 'react';
import { Box, Chip, Paper, Button, ButtonGroup, Menu, MenuItem, TextField } from '@mui/material';

import { DataGrid, gridClasses, ruRU, GridToolbarContainer } from '@mui/x-data-grid';

import { useDispatch, useSelector } from 'react-redux';
import MySnackbar from '../Access/UI/MySnackbar';
import { useEstimateFileUploadMutation, useCreateOrderPDFMutation } from '../../Redux/API/PaymentExecutionAPI';
import { setParsedXLSxRows, changeRow, setOrderDate, setSnackBarMessage, handleChangeOrderDate } from '../../Redux/Slices/PaymentExecutionSlice';
import AwaitingAlert from '../UI/AwaitingAlert';
import MyDropzone from './UI/MyDropzone';

function OrderPreparation() {
  const { user } = useSelector((state) => state.Auth);
  const { parsedXLSxRows, orderDate, snackBarMessage } = useSelector((state) => state.PaymentExecution);
  const dispatch = useDispatch();

  const [EstimateFileUpload] = useEstimateFileUploadMutation();
  const [CreateOrderPDF] = useCreateOrderPDFMutation();
  const [organizations, setOrganizations] = useState([]);
  const [OrderPreparationAwaitingAlertOpen, setOrderPreparationAwaitingAlertOpen] = useState(false);
  const [OrderPreparationAwaitingAlertText, setOrderPreparationAwaitingAlertText] = useState('');

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const stateMenuOpen = Boolean(menuAnchorEl);
  const handleMenuButton = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  function handleOrderPreparationAwaitingAlertClose(event, reason) {
    if (reason && reason === 'backdropClick') return;
    setOrderPreparationAwaitingAlertOpen(false);
  }

  async function handleEstimateFileUpload(AcceptedFile) {
    setOrderPreparationAwaitingAlertText('Загрузка и распознавание сметы');
    setOrderPreparationAwaitingAlertOpen(true);

    let formData = new FormData();
    //formData.append('file', e.target.files[0]);
    formData.append('file', AcceptedFile[0]);
    await EstimateFileUpload(formData)
      .unwrap()

      .then((res) => {
        dispatch(setSnackBarMessage(res.res));
        dispatch(setOrderDate(res.data[0].WorkDate.split('.')[2] + '-' + res.data[0].WorkDate.split('.')[1] + '-' + res.data[0].WorkDate.split('.')[0]));
        dispatch(setParsedXLSxRows(res.data));
        setOrganizations(res.organizations);
        handleOrderPreparationAwaitingAlertClose();
      })
      .catch((error) => {
        handleOrderPreparationAwaitingAlertClose();
        dispatch(setSnackBarMessage('!' + error.data.res));
        return;
      });
  }

  async function handleCreateOrderEvent(organization) {
    setOrderPreparationAwaitingAlertText('Подготовка файла заказа');
    setOrderPreparationAwaitingAlertOpen(true);
    const res = await CreateOrderPDF({ organization, estimateDataArr: parsedXLSxRows, orderDate }).unwrap();
    handleMenuClose();
    res === 'Ready' && handleOrderPreparationAwaitingAlertClose();
  }

  const columns = [
    { field: 'estimateRowId', headerName: '№', width: 100, editable: true },
    { field: 'workCode', headerName: 'Код работ', width: 100, editable: true },
    { field: 'workName', headerName: 'Наименование работ/материалы/оборудование', flex: 1, minWidth: 400, editable: true },
    { field: 'QtyType', headerName: 'Ед.изм', width: 150, editable: true },
    { field: 'Qty', headerName: 'Кол-во', width: 150, editable: true },
    { field: 'WorkDate', headerName: 'Дата выполнения', width: 150, editable: true },
    { field: 'ERP', headerName: 'ERP', width: 150, editable: true },
    { field: 'positionName', headerName: 'Название объекта', width: 150, editable: true },
    { field: 'position_num', headerName: 'Номер позиции', width: 150, editable: true },
    { field: 'position_name', headerName: 'Наименование позиции', width: 150, editable: true },
    { field: 'position_addr', headerName: 'Адрес позиции', width: 150, editable: true },
    { field: 'orderNum', headerName: 'Номер заявки по системам ВК', width: 150, editable: true },
    { field: 'cost', headerName: 'Стоимость за ед., без НДС, руб.', width: 150, editable: true },
    { field: 'costNDS', headerName: 'Стоимость за ед., НДС, руб.', width: 150, editable: true },
    { field: 'sum', headerName: 'Сумма, без НДС, руб.', width: 150, editable: true },
    { field: 'sumNDS', headerName: 'Сумма, НДС, руб.', width: 150, editable: true },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {parsedXLSxRows.length !== 0 && (
          <TextField
            id='date'
            label='Дата заказа'
            type='date'
            size='small'
            value={orderDate}
            onChange={(e) => {
              dispatch(handleChangeOrderDate(e));
            }}
            sx={{ minWidth: 130, mt: '5px', ml: 'auto', mr: '5px' }}
            inputProps={{ style: { fontSize: '12px' } }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Chip label='Формирование заказа из сметы' sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} />
          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', minHeight: '80vh', padding: '10px' }}>
              <Box sx={{ height: '75vh' }}>
                {parsedXLSxRows.length === 0 ? (
                  <MyDropzone handleEstimateFileUpload={handleEstimateFileUpload} />
                ) : (
                  <>
                    <DataGrid
                      disableColumnMenu
                      disableRowSelectionOnClick
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      hideFooter
                      rowHeight={27}
                      editMode='row'
                      processRowUpdate={async (updatedRow, originalRow) => {
                        if (JSON.stringify(updatedRow) !== JSON.stringify(originalRow)) {
                          dispatch(changeRow(updatedRow));
                        }
                        return updatedRow;
                      }}
                      onProcessRowUpdateError={(err) => {
                        console.log(err);
                      }}
                      rows={parsedXLSxRows}
                      columns={columns}
                      sx={{
                        //height: '65vh',
                        fontSize: '10px',

                        '& .MuiDataGrid-columnHeaderTitle': {
                          whiteSpace: 'normal',
                          lineHeight: 'normal',
                        },
                        '& .MuiDataGrid-columnHeader': {
                          // Forced to use important since overriding inline styles
                          height: 'unset !important',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                          // Forced to use important since overriding inline styles
                          maxHeight: '150px !important',
                        },
                        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                          outline: 'none',
                        },
                      }}
                      slots={{ toolbar: CustomToolbar }}
                      localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    />
                  </>
                )}
              </Box>
              <Box align='right' mt='10px'>
                {parsedXLSxRows.length !== 0 && (
                  <ButtonGroup size='small'>
                    <Button
                      sx={{ fontSize: '9px' }}
                      onClick={() => {
                        dispatch(setParsedXLSxRows([]));
                      }}
                    >
                      Отмена
                    </Button>

                    <Button sx={{ fontSize: '9px' }} onClick={handleMenuButton}>
                      Сформировать заказ
                    </Button>
                  </ButtonGroup>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Menu anchorEl={menuAnchorEl} open={stateMenuOpen} onClose={handleMenuClose}>
        {organizations.map((elem, i) => (
          <MenuItem
            key={i}
            onClick={(e) => {
              handleCreateOrderEvent(e.target.innerText);
            }}
          >
            {elem.Organization_name}
          </MenuItem>
        ))}
      </Menu>
      <MySnackbar
        message={snackBarMessage}
        setMessage={(e) => {
          dispatch(setSnackBarMessage(e));
        }}
      />
      <AwaitingAlert
        awaitingAlertOpen={OrderPreparationAwaitingAlertOpen}
        handleAwaitingAlertClose={handleOrderPreparationAwaitingAlertClose}
        title={OrderPreparationAwaitingAlertText}
        // title={'Подготовка файла заказа'}
      />
    </>
  );
}

export default OrderPreparation;
