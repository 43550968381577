import React, { useState, useEffect } from 'react';

import { Typography, Box, Paper, LinearProgress, TextField, Button, Badge, Checkbox, Switch, Stack, Chip, useMediaQuery, useTheme } from '@mui/material';

import { DataGrid, gridClasses, GridToolbar, ruRU } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';

import { useZIPSearchQuery } from '../../Redux/API/ZIPExecutionAPI';
import { setSelectedRows, setZIPNewSelectedCartDialogOpen } from '../../Redux/Slices/ZIPExecutionSlice';
import ZIPNewSelectedCartDialog from './UI/ZIPNewSelectedCartDialog';

function ZIPNew() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { selectedRows } = useSelector((state) => state.ZIPExecution);

  const dispatch = useDispatch();

  const [ZIPDesc, setZIPDesc] = useState('');
  const [skip, setSkip] = useState(true);
  const [wrap, setWrap] = useState(false);

  const { data: ZIPRegisterData, isLoading: isZIPRegisterLoading, isFetching: isZIPRegisterFetching } = useZIPSearchQuery(ZIPDesc, { skip });

  const columns = [
    {
      field: 'completed',
      headerName: '',
      editable: false,
      width: 45,
      sortable: false,
      hideSortIcons: true,
      headerClassName: 'checkbox-column',
      renderCell: (params) => (
        <Checkbox
          checked={selectedRows.some((item) => item.id === params.id) || false}
          // disableRipple
          // disableFocusRipple
          onClick={() => {
            dispatch(setSelectedRows(params.row));
          }}
        />
      ),
    },
    { field: 'NFS', headerName: 'Код НФС', width: 100 },
    { field: 'Description', headerName: 'Описание', flex: 1, minWidth: 400 },
    { field: 'SN', headerName: 'Серийный номер/партия', width: 150 },
    { field: 'Qty_res', headerName: 'Доступное кол-во', width: 80 },
    { field: 'MOL', headerName: 'МОЛ', width: 200 },
    { field: 'Invoice', headerName: 'Инвойс', width: 200 },
  ];

  useEffect(() => {
    let delay;
    setSkip(true);

    if (ZIPDesc.trim() !== '' && ZIPDesc.length > 2) {
      delay = setTimeout(() => {
        setSkip(false);
      }, 700);
    } else {
      setSkip(true);
    }
    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [ZIPDesc]);

  useEffect(() => {
    dispatch(setSelectedRows('clear'));
    // eslint-disable-next-line
  }, []);

  function wrapSwitchHandler(value) {
    value.target.checked ? setWrap(true) : setWrap(false);
  }
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Chip label='Поиск и оформление ЗИП' sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} />
            <Stack direction='row' alignItems='center' justifyContent='end' mb='8px'>
              <Typography variant='caption'>{mobileView ? 'Перенос строк' : 'Включить перенос строк в таблице'}</Typography>
              <Switch size='small' checked={wrap} onChange={wrapSwitchHandler} />
              <Button variant='contained' size='small' sx={{ fontSize: '9px', marginLeft: '5px' }} onClick={() => {}}>
                Лог
              </Button>
            </Stack>
          </Stack>

          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', minHeight: '80vh', padding: '10px' }}>
              <TextField
                fullWidth
                label='Поиск ЗИП'
                variant='outlined'
                helperText={mobileView ? 'Введите наименование ЗИП, код НФC, серийный номер' : 'Введите наименование ЗИП или код НФС или серийный номер'}
                size='small'
                value={ZIPDesc}
                onChange={(e) => {
                  setZIPDesc(e.target.value);
                }}
              />
              {isZIPRegisterLoading ? (
                <LinearProgress />
              ) : (
                ZIPRegisterData &&
                ZIPRegisterData?.res?.length !== 0 && (
                  <>
                    <Box sx={{ height: 'calc(65vh + 20px)' }}>
                      <DataGrid
                        disableColumnMenu
                        disableRowSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        hideFooter
                        rowHeight={27}
                        getRowHeight={() => (wrap ? 'auto' : null)}
                        getEstimatedRowHeight={() => (wrap ? 100 : null)}
                        columnHeaderHeight={30}
                        rows={ZIPRegisterData.res}
                        columns={columns}
                        sx={{
                          height: '65vh',
                          fontSize: '10px',

                          '& .MuiDataGrid-columnHeaderTitle': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                          },
                          '& .MuiDataGrid-columnHeader': {
                            // Forced to use important since overriding inline styles
                            height: 'unset !important',
                          },
                          '& .MuiDataGrid-columnHeaders': {
                            // Forced to use important since overriding inline styles
                            maxHeight: '150px !important',
                          },
                          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                            outline: 'none',
                          },
                          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                            outline: 'none',
                          },
                          [`& .${gridClasses.cell}`]: wrap && {
                            py: 1,
                          },
                        }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: false,
                            printOptions: { disableToolbarButton: true },
                            csvOptions: {
                              delimiter: ';',
                              utf8WithBom: true,
                              disableToolbarButton: true,
                            },
                          },
                        }}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                      />
                      {ZIPRegisterData && isZIPRegisterFetching && <LinearProgress sx={{ marginTop: '5px' }} />}
                    </Box>
                  </>
                )
              )}
              {ZIPRegisterData && (
                <Box align='right' mr='10px'>
                  <Badge badgeContent={selectedRows.length} color='primary'>
                    <Button
                      size='small'
                      disabled={selectedRows.length === 0}
                      variant='outlined'
                      sx={{ fontSize: '9px' }}
                      onClick={() => {
                        dispatch(setZIPNewSelectedCartDialogOpen(true));
                      }}
                    >
                      Оформить монтаж на выделенных строках
                    </Button>
                  </Badge>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>
      <ZIPNewSelectedCartDialog />
    </>
  );
}

export default ZIPNew;
