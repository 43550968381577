import React from 'react';
import { TextField, useMediaQuery, useTheme } from '@mui/material';

function ERPInput({ posERP = '', posValue }) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <TextField
      label='Код ЕРП'
      InputLabelProps={{
        shrink: true,
      }}
      value={posERP}
      size='small'
      sx={{ minWidth: mobileView ? '165px' : '120px', width: '165px' }}
      inputProps={{ style: { fontSize: '12px' } }}
      disabled={posValue ? false : true}
    />
  );
}

export default ERPInput;
