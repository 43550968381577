import React, { useState, useCallback } from 'react';
import { Box, Dialog, DialogTitle, DialogActions, Button, Tooltip, LinearProgress, Paper, Chip, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar, GridActionsCellItem, ruRU } from '@mui/x-data-grid';

import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DeleteIcon from '@mui/icons-material/Delete';

import { useSelector } from 'react-redux';
import { useGetAccessInfoQuery, useAccessInfoEntryActionsMutation } from '../../Redux/API/AccessExecutionAPI';
import MySnackbar from './UI/MySnackbar';
import AlertDialog from './UI/AlertDialog';

function AccessInfo() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useSelector((state) => state.Auth);
  const { data: AccessInfoData, isLoading: isAccessInfoDataLoading } = useGetAccessInfoQuery({}, { refetchOnMountOrArgChange: true });
  const [AccessInfoEntryActions] = useAccessInfoEntryActionsMutation();
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const [promiseArguments, setPromiseArguments] = useState(null);

  const processRowUpdate = useCallback(
    (updatedRow, originalRow) =>
      new Promise((resolve, reject) => {
        if (JSON.stringify(updatedRow) !== JSON.stringify(originalRow)) {
          setPromiseArguments({ resolve, reject, updatedRow, originalRow });
        } else {
          resolve(originalRow);
        }
      }),
    []
  );

  const handleNo = () => {
    const { originalRow, resolve } = promiseArguments;
    resolve(originalRow);
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { updatedRow, originalRow, reject, resolve } = promiseArguments;
    try {
      let res = await AccessInfoEntryActions({ action: 'save', originalRowID: originalRow.id, updatedRow }).unwrap();
      resolve(updatedRow);
      setPromiseArguments(null);
      setSnackBarMessage(res.res);
    } catch (error) {
      reject(originalRow);
      setPromiseArguments(null);
      setSnackBarMessage('!Изменения не сохранены');
    }
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }
    return (
      <Dialog open={!!promiseArguments} maxWidth='xs'>
        <DialogTitle>Сохранить изменения ?</DialogTitle>
        <DialogActions>
          <Button variant='contained' size='small' onClick={handleNo}>
            Нет
          </Button>
          <Button variant='contained' size='small' onClick={handleYes}>
            Да
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  async function handleDeleteEntry(params) {
    let res = await AccessInfoEntryActions({ action: 'delete', originalRowID: params.id }).unwrap();
    setSnackBarMessage(res.res);
  }

  async function handleReplaceEntry(params) {
    let res = await AccessInfoEntryActions({ action: 'replace', originalRowID: params.id }).unwrap();
    setSnackBarMessage(res.res);
  }

  const columns = [
    { field: 'filial', headerName: 'Филиал', width: 100 },
    { field: 'ERP', headerName: 'ЕРП', width: 150 },
    { field: 'partner_name', headerName: 'Контрагент', width: 250, editable: true },
    { field: 'partner_position', headerName: 'Должность представителя контрагента	', width: 250, editable: true },
    { field: 'partner_telnum', headerName: 'Телефонный номер контрагента', width: 100, editable: true },
    { field: 'partner_email', headerName: 'Адрес эл.почты контрагента', width: 150, editable: true },
    { field: 'comments', headerName: 'Комментарии', width: 300, editable: true },
    {
      field: 'actions',
      headerName: 'Действия',
      width: 90,
      renderCell: (params) => (
        <>
          {!isNaN(params.row.ERP.split('_')[2]) && (
            <AlertDialog
              title='Вы уверены ?'
              response={() => {
                handleReplaceEntry(params);
              }}
            >
              {(showAlertDialog) => (
                <Tooltip title={`Сделать для ЕРП ${params.row.ERP.split('_')[0]} основным контактом ${params.row.ERP} ?`}>
                  <GridActionsCellItem
                    icon={<PublishedWithChangesIcon sx={{ fontSize: '15px' }} />}
                    label='Удалить'
                    onClick={() => {
                      //  setIdForDelete(params.id);
                      showAlertDialog();
                    }}
                  />
                </Tooltip>
              )}
            </AlertDialog>
          )}

          {user.role === 'Admin' && (
            <AlertDialog
              title='Вы уверены ?'
              response={() => {
                handleDeleteEntry(params);
              }}
            >
              {(showAlertDialog) => (
                <Tooltip title={`Удалить ${params.row.ERP} ?`}>
                  <GridActionsCellItem
                    icon={<DeleteIcon sx={{ color: 'red', fontSize: '15px' }} />}
                    label='Удалить'
                    onClick={() => {
                      //  setIdForDelete(params.id);
                      showAlertDialog();
                    }}
                  />
                </Tooltip>
              )}
            </AlertDialog>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Chip label='Информация по допуску на позиции' sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} />
          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', minHeight: '80vh', padding: '10px' }}>
              {isAccessInfoDataLoading && <LinearProgress />}
              {AccessInfoData && AccessInfoData?.length !== 0 && (
                <>
                  {renderConfirmDialog()}
                  <DataGrid
                    //  density='compact'
                    disableColumnMenu
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    //checkboxSelection
                    rowHeight={27}
                    // autoHeight
                    rows={AccessInfoData}
                    columns={columns}
                    sx={{
                      height: '75vh',
                      fontSize: '10px',
                      '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                      },
                      '& .MuiDataGrid-columnHeader': {
                        // Forced to use important since overriding inline styles
                        height: 'unset !important',
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        // Forced to use important since overriding inline styles
                        maxHeight: '168px !important',
                      },
                      [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                        outline: 'none',
                      },
                      '.Versions': {
                        fontWeight: 100,
                        color: 'lightgrey',
                      },
                    }}
                    editMode='row'
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={() => {}}
                    isCellEditable={(params) => isNaN(params.row.ERP.split('_')[2])}
                    getRowClassName={(params) => {
                      if (!isNaN(params.row.ERP.split('_')[2])) return 'Versions';
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                      },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                  />
                </>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>
      <MySnackbar message={snackBarMessage} setMessage={setSnackBarMessage} />
    </>
  );
}

export default AccessInfo;
