import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Paper,
  LinearProgress,
  Stack,
  Button,
  Tooltip,
  Chip,
  ButtonGroup,
  Menu,
  MenuItem,
  IconButton,
  Popover,
  Link,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ClearIcon from '@mui/icons-material/Clear';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { DataGrid, gridClasses, GridToolbar, ruRU } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';

import { setMailPreparationDialogOpen } from '../../Redux/Slices/AccessExecutionSlice';
import {
  useGetAccessMailJobsRegisterQuery,
  useAccessListActionMutation,
  useAccessMailsResponseUploadMutation,
  useAccessMailsResponseFileDeleteMutation,
  useAccessMailsResponseFileDownloadMutation,
  useAccessMailsDownloadMutation,
  useAccessMailJobsActionStatusQuery,
} from '../../Redux/API/AccessExecutionAPI';
import MailPreparationDialog from './UI/MailPreparationDialog';
import AccessMailsAwaitingAlert from './UI/AccessMailsAwaitingAlert';
import AlertDialog from './UI/AlertDialog';
import MySnackbar from './UI/MySnackbar';
import AccessLogDialog from './UI/AccessLogDialog';
import AccessInfoAndMemoDialog from './UI/AccessInfoAndMemoDialog';

import Beeline from './Images/Beeline.png';
import Service_Telecom from './Images/Service_Telecom.png';

function AccessMails() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useSelector((state) => state.Auth);
  const { data: AccessMailJobsRegisterData, isLoading: isMailJobsRegisterLoading } = useGetAccessMailJobsRegisterQuery({}, { refetchOnMountOrArgChange: true });
  const [AccessListAction] = useAccessListActionMutation();
  const [AccessMailsResponseUpload, { isLoading: isMailsResponseUploading }] = useAccessMailsResponseUploadMutation();
  const [AccessMailsResponseFileDelete] = useAccessMailsResponseFileDeleteMutation();
  const [AccessMailsResponseFileDownload] = useAccessMailsResponseFileDownloadMutation();
  const dispatch = useDispatch();

  const [skipPoll, setSkipPoll] = useState(true);
  const [AccessMailsDownload] = useAccessMailsDownloadMutation();
  const [accessMailJobsPreparationProcessId, setAccessMailJobsPreparationProcessId] = useState();
  const [AccessMailJobsStatusDataState, setAccessMailJobsStatusDataState] = useState({ progress: 0, status: '' });
  const { data: AccessMailJobsStatusData } = useAccessMailJobsActionStatusQuery(accessMailJobsPreparationProcessId, {
    pollingInterval: 500,
    skip: skipPoll,
    refetchOnMountOrArgChange: true,
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [popoverContent, setPopoverContent] = useState('');

  const [stateSelectMenuAnchorEl, setStateSelectMenuAnchorEl] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const popoverOpen = Boolean(popoverAnchorEl);
  const handleUploadedFilesButton = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const handleUploadedFilesPopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const stateSelectMenuOpen = Boolean(stateSelectMenuAnchorEl);
  const handleStateMenuButton = (event) => {
    setStateSelectMenuAnchorEl(event.currentTarget);
  };
  const handleStateMenuClose = () => {
    setStateSelectMenuAnchorEl(null);
  };

  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [AccessMailsAwaitingAlertOpen, setAccessMailsAwaitingAlertOpen] = useState(false);
  const [AccessMailsAwaitingAlertTitle, setAccessMailsAwaitingAlertTitle] = useState('');
  const [uploadProgressID, setUploadProgressID] = useState();
  const [accessLogDialogOpen, setAccessLogDialogOpen] = useState(false);
  const [accessInfoAndMemoDialogOpen, setAccessInfoAndMemoDialogOpen] = useState(false);
  const [accessInfoAndMemoDialogData, setAccessInfoAndMemoDialogData] = useState({});

  async function handleAccessReplyFileChange({ e, params }) {
    let selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 0) {
      setUploadProgressID(params.id);
      let formData = new FormData();
      formData.append('id', params.id);
      formData.append('user', JSON.stringify(user));
      formData.append('filesArray', params.row.AccessMail_reply);

      selectedFiles.forEach((file) => {
        formData.append('files', file);
      });
      const res = await AccessMailsResponseUpload(formData).unwrap();
      setSnackBarMessage(res.res);
    }
  }

  useEffect(() => {
    //загружаем картинки во время монтирования компонента, чтобы они не "моргали" при переходе с компонента на компонент
    const imageList = [Beeline, Service_Telecom];
    imageList.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  useEffect(() => {
    async function AccessMailStausCheck() {
      setAccessMailJobsStatusDataState({ progress: AccessMailJobsStatusData?.res.progress, status: AccessMailJobsStatusData?.res.status });
      if (AccessMailJobsStatusData?.res.progress === 100) {
        let fileName = AccessMailJobsStatusData.res.filePath.split('/')[2];
        setSkipPoll(true);
        const res = await AccessMailsDownload({ processID: accessMailJobsPreparationProcessId, fileName }).unwrap();
        if (res === 'Ready') handleAccessMailsAwaitingAlertClose();
      }
    }
    AccessMailStausCheck();
  }, [AccessMailJobsStatusData]);

  const columns = [
    {
      field: 'Position_owner',
      headerName: 'Собственник',
      width: 90,
      renderCell: (params) => <img src={params.value === 'Service_Telecom' ? Service_Telecom : Beeline} width={55} alt={params.value} />,
    },
    { field: 'Position', headerName: 'Позиция', width: 300 },
    {
      field: 'Access_Info',
      headerName: 'Информация о допуске',
      width: 90,
      renderCell: (params) => (
        <Tooltip title={params.value !== '' && <div style={{ whiteSpace: 'pre-line' }}>{params.value}</div>}>
          <IconButton
            color={params.value !== '' ? 'primary' : ''}
            onClick={() => {
              setAccessInfoAndMemoDialogData({ id: params.id, title: 'Информация по допуску на позицию', type: 'PositionAccessInfo', data: params.value });
              setAccessInfoAndMemoDialogOpen(true);
            }}
          >
            <InfoOutlinedIcon sx={{ fontSize: '15px' }} />
          </IconButton>
        </Tooltip>
      ),
    },

    {
      field: 'Memo',
      headerName: 'Комментарии',
      width: 90,
      renderCell: (params) => (
        <Tooltip title={params.value === '' ? 'Добавить комментарии' : <div style={{ whiteSpace: 'pre-line' }}>{params.value}</div>}>
          <IconButton
            color={params.value !== '' ? 'primary' : ''}
            onClick={() => {
              setAccessInfoAndMemoDialogData({ id: params.id, title: 'Комментарии по допуску на позицию', type: 'PositionMemo', data: params.value });
              setAccessInfoAndMemoDialogOpen(true);
            }}
          >
            <CommentOutlinedIcon sx={{ fontSize: '15px' }} />
          </IconButton>
        </Tooltip>
      ),
    },

    {
      field: 'AccessMail_reply',
      headerName: 'Ответ арендодателя',
      width: 100,
      renderCell: (params) => (
        <>
          {isMailsResponseUploading && params.id === uploadProgressID ? (
            <CircularProgress size='15px' sx={{ marginLeft: '10px', marginRight: '6px' }} />
          ) : (
            <Tooltip title='Загрузить файлы'>
              <IconButton component='label'>
                <input
                  type='file'
                  multiple
                  onChange={(e) => {
                    handleAccessReplyFileChange({ e, params });
                  }}
                  style={{ display: 'none' }}
                  id='multiple-file-input'
                />
                <UploadFileIcon sx={{ fontSize: '15px' }} />
              </IconButton>
            </Tooltip>
          )}
          {params.value !== '' && (
            <Tooltip title='Присоединенные файлы'>
              <IconButton
                color='primary'
                onClick={(e) => {
                  setPopoverContent({ id: params.id, files: params.value });
                  handleUploadedFilesButton(e);
                }}
              >
                <ListAltIcon sx={{ fontSize: '15px' }} />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      field: 'Organizations',
      headerName: 'Организации',
      width: 250,
      renderCell: (params) => params.value.split(',').map((res, i) => <Chip key={i} size='small' sx={{ fontSize: '8px', fontWeight: 'bold', margin: '2px' }} label={res} />),
    },

    {
      field: 'startAccessDate',
      headerName: 'Дата начала допуска',
      width: 120,
      align: 'center',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value.split('.')[2] + '-' + value.split('.')[1] + '-' + value.split('.')[0]),
    },
    {
      field: 'endAccessDate',
      headerName: 'Дата конца допуска',
      width: 120,
      align: 'center',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value.split('.')[2] + '-' + value.split('.')[1] + '-' + value.split('.')[0]),
    },
    {
      field: 'Job_Date_Create',
      headerName: 'Дата оформления письма на допуск',
      width: 120,
      align: 'center',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value.split('.')[2] + '-' + value.split('.')[1] + '-' + value.split('.')[0]),
    },
    { field: 'State', headerName: 'Статус допуска', width: 150 },
    { field: 'Job_Author', headerName: 'Ответственный', width: 250 },
  ];

  function actionHandler(res) {
    setRowSelectionModel([]);
    setSelectedRows([]);
    if (res !== '') {
      setSnackBarMessage(res.res);
    }
  }

  function handleAccessMailsAwaitingAlertClose(event, reason) {
    if (reason && reason === 'backdropClick') return;
    setAccessMailsAwaitingAlertOpen(false);
  }

  async function handleDeleteFilesButtonClick(fileForDelete) {
    let oldFileNamesArr = popoverContent.files?.split(',');
    let newFileNamesArr = [];
    const res = await AccessMailsResponseFileDelete({ id: popoverContent.id, fileNameForDelete: fileForDelete, user }).unwrap();
    newFileNamesArr = oldFileNamesArr.filter((el) => el !== fileForDelete);
    setPopoverContent({ id: popoverContent.id, files: newFileNamesArr.toString() });
    setSnackBarMessage(res.res);
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '95%', marginTop: '10px' }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Chip label={mobileView ? 'Письма на допуск' : 'Оформление писем на допуск'} sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} />
            <Box mb='8px'>
              <Button
                variant='contained'
                size='small'
                sx={{ fontSize: '9px', marginRight: '5px' }}
                onClick={() => {
                  setAccessLogDialogOpen(true);
                }}
              >
                Лог
              </Button>
              <Button
                variant='contained'
                size='small'
                sx={{ fontSize: '9px' }}
                onClick={() => {
                  dispatch(setMailPreparationDialogOpen(true));
                }}
              >
                {mobileView ? 'Создать письмо' : 'Создать письмо на допуск'}
              </Button>
            </Box>
          </Stack>
          <Paper elevation={24} sx={{ borderTopLeftRadius: '0px' }}>
            <Box sx={{ width: 'auto', minHeight: '80vh', padding: '10px' }}>
              {isMailJobsRegisterLoading ? (
                <LinearProgress />
              ) : AccessMailJobsRegisterData?.MailJobs?.length === 0 ? (
                <Box align='center'>Нет заданий на оформление допусков</Box>
              ) : (
                <>
                  <DataGrid
                    //  density='compact'
                    disableColumnMenu
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    checkboxSelection
                    rowHeight={27}
                    // autoHeight
                    rows={AccessMailJobsRegisterData.MailJobs}
                    columns={columns}
                    sx={{
                      height: '75vh',
                      fontSize: '10px',
                      '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                      },
                      '& .MuiDataGrid-columnHeader': {
                        // Forced to use important since overriding inline styles
                        height: 'unset !important',
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        // Forced to use important since overriding inline styles
                        maxHeight: '168px !important',
                      },
                      [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                        outline: 'none',
                      },
                      '.mail_sended': {
                        backgroundColor: '#FCF3CF',
                        transition: '0.1s',
                        '&:hover': {
                          bgcolor: '#F9E79F',
                        },
                      },
                      '.access_granted': {
                        backgroundColor: '#D5F5E3',
                        transition: '0.1s',
                        '&:hover': {
                          bgcolor: '#ABEBC6',
                        },
                      },
                      '.access_rejected': {
                        backgroundColor: '#F5B7B1',
                        transition: '0.1s',
                        '&:hover': {
                          bgcolor: '#F1948A',
                        },
                      },
                    }}
                    onRowSelectionModelChange={(ids) => {
                      setSelectedRows(ids.map((id) => AccessMailJobsRegisterData.MailJobs.find((row) => row.id === id)));
                      setRowSelectionModel(ids);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    getRowClassName={(params) => {
                      switch (params.row.State) {
                        case 'Письмо отправлено контрагенту': {
                          return 'mail_sended';
                        }
                        case 'Доступ согласован': {
                          return 'access_granted';
                        }
                        case 'В доступе отказано': {
                          return 'access_rejected';
                        }
                        default: {
                          break;
                        }
                      }
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                      },
                    }}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'Job_Date_Create', sort: 'desc' }],
                      },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                  />
                </>
              )}
              {AccessMailJobsRegisterData?.MailJobs?.length !== 0 && (
                <Box align='right' mt='10px'>
                  <ButtonGroup size='small' disabled={selectedRows.length === 0}>
                    {user.role === 'Admin' && (
                      <AlertDialog
                        title='Вы уверены ?'
                        response={async () => {
                          const res = await AccessListAction({ action: 'delete', user, data: selectedRows }).unwrap();
                          actionHandler(res);
                        }}
                      >
                        {(showAlertDialog) => (
                          <Button sx={{ fontSize: mobileView ? '7px' : '9px' }} color='error' onClick={showAlertDialog}>
                            {mobileView ? 'Удалить' : 'Удалить выбранные задачи'}
                          </Button>
                        )}
                      </AlertDialog>
                    )}
                    <Button
                      sx={{ fontSize: mobileView ? '7px' : '9px' }}
                      onClick={async () => {
                        //работаем только с задачами, на которые согласован допуск
                        let workdata = [];
                        for (let position of selectedRows) {
                          if (position.State === 'Доступ согласован') workdata.push(position);
                        }
                        if (workdata.length !== 0) {
                          setAccessMailJobsStatusDataState({ progress: 0, status: '' });
                          setAccessMailsAwaitingAlertTitle('Подготовка письма для ГПО');
                          setAccessMailsAwaitingAlertOpen(true);

                          AccessListAction({ action: 'download_accessed_mails_for_subcontractors', user, data: workdata })
                            .unwrap()
                            .then((res) => {
                              actionHandler('');
                              setAccessMailJobsPreparationProcessId(res.processID);
                              setSkipPoll(false);
                            });
                        }
                      }}
                    >
                      {mobileView ? 'Cкачать письмо для ГПО' : 'Cформировать информационное письмо для ГПО'}
                    </Button>
                    <Button
                      sx={{ fontSize: mobileView ? '7px' : '9px' }}
                      onClick={async () => {
                        setAccessMailsAwaitingAlertOpen(true);
                        setAccessMailsAwaitingAlertTitle('Подготовка писем на допуск');
                        const res = await AccessListAction({ action: 'download_mails', user, data: selectedRows }).unwrap();
                        actionHandler('');
                        res === 'Ready' && handleAccessMailsAwaitingAlertClose();
                      }}
                    >
                      {mobileView ? 'Скачать письма' : 'Скачать подготовленные письма'}
                    </Button>

                    <Button sx={{ fontSize: mobileView ? '7px' : '9px' }} onClick={handleStateMenuButton}>
                      {mobileView ? 'Изменить состояние' : 'Изменить состояние выбранных задач'}
                    </Button>
                  </ButtonGroup>
                  <Menu anchorEl={stateSelectMenuAnchorEl} open={stateSelectMenuOpen} onClose={handleStateMenuClose}>
                    <MenuItem
                      onClick={async () => {
                        const res = await AccessListAction({ action: 'change_status', user, status: 'Письмо отправлено контрагенту', data: selectedRows }).unwrap();
                        handleStateMenuClose();
                        actionHandler(res);
                      }}
                    >
                      Письмо отправлено контрагенту
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        const res = await AccessListAction({ action: 'change_status', user, status: 'Доступ согласован', data: selectedRows }).unwrap();
                        handleStateMenuClose();
                        actionHandler(res);
                      }}
                    >
                      Доступ согласован
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        const res = await AccessListAction({ action: 'change_status', user, status: 'В доступе отказано', data: selectedRows }).unwrap();
                        handleStateMenuClose();
                        actionHandler(res);
                      }}
                    >
                      В доступе отказано
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>
      {AccessMailJobsRegisterData !== undefined && <MailPreparationDialog data={AccessMailJobsRegisterData} />}

      <MySnackbar message={snackBarMessage} setMessage={setSnackBarMessage} />
      <AccessMailsAwaitingAlert
        title={AccessMailsAwaitingAlertTitle}
        progress={AccessMailJobsStatusDataState.progress}
        status={AccessMailJobsStatusDataState.status}
        accessMailsAwaitingAlertOpen={AccessMailsAwaitingAlertOpen}
        handleAccessMailsAwaitingAlertClose={handleAccessMailsAwaitingAlertClose}
      />
      {popoverContent.files !== '' && (
        <Popover
          open={popoverOpen}
          anchorEl={popoverAnchorEl}
          onClose={handleUploadedFilesPopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box sx={{ p: 2 }}>
            {popoverContent.files?.split(',').map((el, i) => (
              <Box key={i}>
                <Tooltip title='Скачать файл'>
                  <Link
                    component='button'
                    variant='body2'
                    onClick={() => {
                      AccessMailsResponseFileDownload({ el, id: popoverContent.id, user });
                      handleUploadedFilesPopoverClose();
                    }}
                  >
                    {el}
                  </Link>
                </Tooltip>
                <AlertDialog
                  title='Вы уверены ?'
                  response={() => {
                    handleDeleteFilesButtonClick(el);
                  }}
                >
                  {(showAlertDialog) => (
                    <Tooltip title='Удалить файл'>
                      <IconButton size='small' sx={{ ml: '5px' }} onClick={showAlertDialog}>
                        <ClearIcon sx={{ color: 'red' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </AlertDialog>
                <br />
              </Box>
            ))}
          </Box>
        </Popover>
      )}
      <AccessLogDialog accessLogDialogOpen={accessLogDialogOpen} setAccessLogDialogOpen={setAccessLogDialogOpen} />
      <AccessInfoAndMemoDialog
        accessInfoAndMemoDialogOpen={accessInfoAndMemoDialogOpen}
        setAccessInfoAndMemoDialogOpen={setAccessInfoAndMemoDialogOpen}
        data={accessInfoAndMemoDialogData}
      />
    </>
  );
}

export default AccessMails;
