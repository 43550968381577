import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { Box, Button, LinearProgress, Typography, Divider, TextField, Stack, Alert, Snackbar, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

function UploadOSfile() {
  const { accessToken } = useSelector((state) => state.Auth);
  const [file, setFile] = useState('');
  const [load, setLoad] = useState(false);
  const [loadState, setLoadState] = useState('');
  //const [snackBarOpts, setSnackBarOpts] = useState({ state: false, severity: 'success', message: '' });
  const [OSFileColumsSettings, setOSFileColumsSettings] = useState({
    ActiveCol: 3,
    QtyCol: 4,
    SNCol: 5,
    PartCol: 6,
    InventoryNumCol: 7,
    NFSCol: 9,
    DescriptionCol: 11,
    GFKCol: 15,
    ERPCol: 17,
    AddressCol: 19,
    DataRow: 6,
  });
  const inputRef = useRef();

  function handleChange(e) {
    setLoadState('');
    const fileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

    if (e.target.files[0] && fileTypes.indexOf(e.target.files[0].type) === -1) {
      alert('Выбран не верный тип файла!');
      inputRef.current.value = '';
      return;
    } else if (e.target.files[0] === undefined) {
      return;
    } else {
      setFile(e.target.files[0]);
    }
  }

  async function uploadFile() {
    var uploadres = '';

    setLoad(true);
    setLoadState('Файл загружается на сервер...');

    const formData = new FormData();
    formData.append('file', file); // подключаем файл в элемент formData

    // загружаем файл на сервер

    uploadres = await axios.post('https://beehandbook.ru:4001/uploadOSfile', formData, {
      headers: {
        Authorization: accessToken,
      },
      withCredentials: true,
    });
    if (uploadres.data === 'Upload complete, processing...') {
      setLoadState('Файл загружен на сервер, идет обработка');
      //если все ок, запускаем процесс заполнения базы данных

      uploadres = await axios.post('https://beehandbook.ru:4001/processOSfile', OSFileColumsSettings, {
        headers: {
          Authorization: accessToken,
        },
        withCredentials: true,
      });
    } else {
      setLoadState('Ошибка при загрузке файла на сервер. Попробуйте еще раз');
      setLoad(false);
    }

    if (uploadres.data === 'Processing completed!') {
      setLoadState('Обработка завершена успешно');
      setLoad(false);
    } else {
      setLoadState('Ошибка при обработке. Проверьте файл на корректность данных');
      setLoad(false);
    }
  }

  return (
    <>
      <Stack direction='row' spacing='10px' alignItems='center' mt='10px'>
        <Typography variant='subtitle1'>Загрузка файла основных средств</Typography>

        <Button variant='contained' component='label' size='small'>
          Выбрать файл
          <input type='file' ref={inputRef} onChange={handleChange} hidden />
        </Button>

        {file && (
          <Typography variant='body2' sx={{ marginLeft: '10px' }}>
            Выбран файл: {file.name}
          </Typography>
        )}
        <IconButton
          sx={{ display: file ? undefined : 'none' }}
          onClick={() => {
            inputRef.current.value = '';
            setFile('');
            setLoadState('');
          }}
        >
          <ClearIcon sx={{ color: 'red' }} />
        </IconButton>
      </Stack>

      {file && (
        <Box sx={{ marginTop: '30px' }} align='left'>
          <Typography variant='body2'>Укажите номера столбцов с типами данных в файле основных средств:</Typography>
          <Stack sx={{ marginTop: '20px' }} direction='row' spacing='10px'>
            <TextField
              label='Актив'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={OSFileColumsSettings.ActiveCol}
              onChange={(e) => {
                setOSFileColumsSettings({ ...OSFileColumsSettings, ActiveCol: e.target.value });
              }}
              size='small'
              sx={{ width: '60px' }}
            />
            <TextField
              label='Кол-во'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={OSFileColumsSettings.QtyCol}
              onChange={(e) => {
                setOSFileColumsSettings({ ...OSFileColumsSettings, QtyCol: e.target.value });
              }}
              size='small'
              sx={{ width: '60px' }}
            />
            <TextField
              label='Серийный номер'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={OSFileColumsSettings.SNCol}
              onChange={(e) => {
                setOSFileColumsSettings({ ...OSFileColumsSettings, SNCol: e.target.value });
              }}
              size='small'
              sx={{ width: '120px' }}
            />
            <TextField
              label='номер партии'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={OSFileColumsSettings.PartCol}
              onChange={(e) => {
                setOSFileColumsSettings({ ...OSFileColumsSettings, PartCol: e.target.value });
              }}
              size='small'
              sx={{ width: '100px' }}
            />
            <TextField
              label='Инвентарный номер'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={OSFileColumsSettings.InventoryNumCol}
              onChange={(e) => {
                setOSFileColumsSettings({ ...OSFileColumsSettings, InventoryNumCol: e.target.value });
              }}
              size='small'
              sx={{ width: '140px' }}
            />
            <TextField
              label='Код НФС'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={OSFileColumsSettings.NFSCol}
              onChange={(e) => {
                setOSFileColumsSettings({ ...OSFileColumsSettings, NFSCol: e.target.value });
              }}
              size='small'
              sx={{ width: '80px' }}
            />
            <TextField
              label='Описание'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={OSFileColumsSettings.DescriptionCol}
              onChange={(e) => {
                setOSFileColumsSettings({ ...OSFileColumsSettings, DescriptionCol: e.target.value });
              }}
              size='small'
              sx={{ width: '80px' }}
            />
            <TextField
              label='код ГФК'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={OSFileColumsSettings.GFKCol}
              onChange={(e) => {
                setOSFileColumsSettings({ ...OSFileColumsSettings, GFKCol: e.target.value });
              }}
              size='small'
              sx={{ width: '75px' }}
            />
            <TextField
              label='код ЕРП'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={OSFileColumsSettings.ERPCol}
              onChange={(e) => {
                setOSFileColumsSettings({ ...OSFileColumsSettings, ERPCol: e.target.value });
              }}
              size='small'
              sx={{ width: '75px' }}
            />
            <TextField
              label='Адрес'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              value={OSFileColumsSettings.AddressCol}
              onChange={(e) => {
                setOSFileColumsSettings({ ...OSFileColumsSettings, AddressCol: e.target.value });
              }}
              size='small'
              sx={{ width: '65px' }}
            />
          </Stack>

          <Typography variant='body2' sx={{ marginTop: '40px' }}>
            Укажите номер строки с которой начинаются данные (без заголовков) в файле основных средств:
          </Typography>
          <TextField
            label='Первая строка с данными'
            type='number'
            InputLabelProps={{
              shrink: true,
            }}
            value={OSFileColumsSettings.DataRow}
            onChange={(e) => {
              setOSFileColumsSettings({ ...OSFileColumsSettings, DataRow: e.target.value });
            }}
            size='small'
            sx={{ width: '180px', marginTop: '10px' }}
          />
        </Box>
      )}
      <Box align='right'>
        <Button variant='contained' size='small' color='error' sx={{ display: file ? undefined : 'none' }} onClick={uploadFile}>
          Загрузить на сервер
          <input type='file' ref={inputRef} onChange={handleChange} hidden />
        </Button>
      </Box>
      {load && <LinearProgress sx={{ marginTop: '10px' }} />}
      <Box align='right' mb='10px' mt='10px'>
        <Typography variant='body2' sx={{ display: file ? undefined : 'none' }}>
          {loadState}
        </Typography>
      </Box>
      <Divider />
    </>
  );
}

export default UploadOSfile;
